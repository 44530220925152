"use strict";

window.isIE9 = window.navigator.userAgent.indexOf('MSIE 9.0') !== -1;

var searchFormModule = angular.module('SearchFormServiceUI', []);

angular.module('SearchFormServiceUI').controller('SearchFormCtrl', ['$scope', '$window', '$timeout', 'queryParamCollection', 'sfsDataModelProvider', 'userPreferences',
function ($scope, $window, $timeout, queryParamCollection, sfsDataModelProvider, userPreferences) {

    $scope.sfsData = {};
    $scope.model = null;
/* istanbul ignore next */
    $scope.resetSearch = function () {
        /*$scope.model =  $.extend(true, {}, $scope.originalModel);
        $window.setTimeout(function(){
            $scope.$apply();
        })*/
    }
/* istanbul ignore next */
    if ($window.ancestry && $window.ancestry.search) {
        $window.ancestry.search.resetSearch = $scope.resetSearch;
    }
    $scope.init = function (domPrefix) {
        $scope.sfsData = $window[domPrefix + 'sfsData'];
        $scope.domPrefix = domPrefix;
        $scope.model =  sfsDataModelProvider.getDataModel(domPrefix);
        $scope.originalModel =  $.extend(true, {}, sfsDataModelProvider.getDataModel(domPrefix));
        $scope.metadata = sfsDataModelProvider.getMetadata(domPrefix);

        userPreferences.updateMetadataDefaults(domPrefix);

        var savedFormData = getSavedFormData($scope);
        var TYPE_BACK_FORWARD = 2;
        var userNavigatedBack = savedFormData || ($window.performance && $window.performance.navigation.type === TYPE_BACK_FORWARD);
        if (!userNavigatedBack) {
            var formData = queryParamCollection.getFormData($scope.domPrefix);
            sfsDataModelProvider.populateFromData(domPrefix, formData, 'onload', function () {
                userPreferences.applyUserPreferences(domPrefix);
                loadingComplete();
            });
        }
    };

    $scope.loadFromPreviousState = function () {
        var savedData = getSavedFormData($scope);
        if (savedData) {
            // when reloading after user navigated back, all text inputs will be empty since autocomplete=off is set on all of them to 
            // prevent the browser from trying to add suggestions that interferes with place picker and autocomplete.  This behavior
            // of the inputs being empty while the datamodel has values can cause weird behavior (like in Safai) so we first clear all
            // fields on the form and then immediately repopulate.
            sfsDataModelProvider.resetData($scope.domPrefix);
            // Note that category buckets need to be set to false since true is the default and the true state doesn't always load
            // (Safari) without this state being "reset"
            $scope.model.types.photos = false;
            $scope.model.types.records = false;
            $scope.model.types.stories = false;
            $scope.model.types.trees = false;
            $timeout(function () {
                $.extend(true, $scope.model, savedData.dataModel);
                loadingComplete();
            }, 1);
        }
    };

    $scope.saveFormState = function() {
        // the saveFormState method is called when the form is submitted
        // which means that it remembers the checkbox value at the moment of time
        // before submit. which is not what we want here, as we should remember the 
        // filter value before submit. 
        var dataModel = $.extend(true, {}, $scope.model);
        if (dataModel.showUnviewedRecordsOnly) {
            delete dataModel.showUnviewedRecordsOnly;
        }

        var formDataStr = JSON.stringify({
            dataModel: dataModel,
            viewModel: {}
        });
        var formDataId = $scope.metadata.form.dataFieldId;

        // Note that sfsDataStorage is an input that the caller of SFS puts on the page somewhere and that is shared
        // between all SFS forms.
        if ($('#sfsDataStorage').length === 1) {
            var sfsDataStorage = getSfsSavedFormData() || {};
            sfsDataStorage[formDataId] = formDataStr;
            $('#sfsDataStorage').val(JSON.stringify(sfsDataStorage));
        } else {
            $('#' + formDataId).val(formDataStr);
        }
    };

    function getSavedFormData($scope) {
        var formDataId = $scope.metadata.form.dataFieldId;

        // Note that sfsDataStorage is an input that the caller of SFS puts on the page somewhere and that is shared
        // between all SFS forms.
        if ($('#sfsDataStorage').length === 1) {
            var sfsDataStorage = getSfsSavedFormData();
            return sfsDataStorage && sfsDataStorage[formDataId]
                ? JSON.parse(sfsDataStorage[formDataId])
                : null;
        }
        var formDataStorageStr = $('#' + formDataId).val();
        return formDataStorageStr ? JSON.parse(formDataStorageStr) : null;
    }

    function getSfsSavedFormData() {
        var sfsDataStorageStr = $('#sfsDataStorage').val();
        if (sfsDataStorageStr) {
            return JSON.parse(sfsDataStorageStr);
        }
        return null;
    }

    function loadingComplete() {
        // This broadcast call is only necessary because we still have some controllers that are not on the Shared Model
        if ($scope.model.isExactAllEnabled === true) {
            $scope.$root.$broadcast('toggleMatchAllTermsExactly', $scope.model.isExactAllEnabled, $scope.domPrefix);
        }
        $scope.$root.$broadcast('modelLoaded', $scope.model, $scope.domPrefix);
    };

    $scope.$on('clearFormEvent', function (event, domPrefix) {
        if ($scope.domPrefix !== domPrefix) return;
        sfsDataModelProvider.resetData(domPrefix);
    });

    $scope.$on('personPickerUpdated', function ($event, domPrefix) {
        if ($scope.domPrefix !== domPrefix) return;
        var personPickerData = queryParamCollection.getPersonPickerParams();
        // this has been added for testing //
        sfsDataModelProvider.populateFromData(domPrefix, personPickerData, 'personPicker');
    });

    $scope.$on('toggleMatchAllTermsExactly', function ($event, isMatchAll, domPrefix) {
        if ($scope.domPrefix !== domPrefix) return;
        sfsDataModelProvider.matchAllTermsExactly(domPrefix, isMatchAll);
    });
    $scope.$on('toggleShowUnviewedRecordsOnly', function ($event, currentValue, domPrefix) {
       $scope.model.showUnviewedRecordsOnly = currentValue;
    });

    $scope.$on('resetMatchAllTermsExactly', function (event, domPrefix) {
        if (domPrefix !== $scope.domPrefix) return;
        $scope.model.isExactAllEnabled = false;
    });
}]);

angular.module('SearchFormServiceUI').directive('sfsHistoryBack', ['$window', '$parse', function ($window, $parse) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var evtHandler = $parse(attr.sfsHistoryBack);
            // It seems most browsers are OK with using the pageshow event, however IE doesn't fire this event when wired up from here.  It seems nearly
            // all browsers will execute this link code here any time the page loads (even during the pageshow event) with the exception of Safari.  So...
            // the overall winning solution seems to be to assume you can immediately trigger the event handler for pageshow and fall back on the pageshow
            // event if this didn't work (like with Safari).
            var triggered = false;
            if ($window.performance && $window.performance.navigation.type === 2) {
                evtHandler(scope);
                triggered = true;
            }
             /* istanbul ignore next */
            $($window).on('pageshow', function (e) {
                var backNavigationDetected = e.originalEvent.persisted || ($window.performance && $window.performance.navigation.type === 2);
                var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                if (isSafari && backNavigationDetected) {
                    $window.setTimeout(function () {
                        scope.$apply(function () {
                            evtHandler(scope);
                        });
                        triggered = true;
                    }, 10);
                } else if (!triggered && backNavigationDetected) {
                    scope.$apply(function() {
                        evtHandler(scope);
                    });
                    triggered = true;
                }
            });
            function onPageLeave() {
                triggered = false;
            }
            $($window).on('beforeunload', onPageLeave);
            $($window).on('pagehide', onPageLeave);
        }
    };
}]);

angular.module('SearchFormServiceUI').directive('sfsBeforeUnload', ['$window', '$parse', function ($window, $parse) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var evtHandler = $parse(attr.sfsBeforeUnload);
            var triggered = false;
            function fireEvtHandler(){
                if (triggered) return;
                scope.$apply(function() {
                    evtHandler(scope);
                });
                triggered = true;
            };
            $($window).on('beforeunload', fireEvtHandler);
            $($window).on('pagehide', fireEvtHandler);
            $($window).on('pageshow', function(){
                triggered = false;
            });
        }
    };
}]);

angular.module('SearchFormServiceUI').controller('AdvancedPanelCtrl', ['$scope', function ($scope) {
    $scope.switchView = function () {
        $scope.model.isShowMoreOptionsExpanded = !$scope.model.isShowMoreOptionsExpanded;
    };
}]);

angular.module('SearchFormServiceUI').controller('EventController', ['$scope', 'sfsDataModelProvider', function ($scope, sfsDataModelProvider) {
    $scope.event = null;
    $scope.init = function (eventGroupName) {
        $scope.event = sfsDataModelProvider.getEvent($scope.domPrefix, eventGroupName);
    };
}]);

angular.module('SearchFormServiceUI').controller('MSAVController', ['$scope', function ($scope) {
    $scope.getMSAV = function () {
        if ($scope.model.isExactAllEnabled && $scope.model.isShowMoreOptionsExpanded) {
            return 2;
        } else if (!$scope.model.isExactAllEnabled && $scope.model.isShowMoreOptionsExpanded) {
            return 1;
        } else if ($scope.model.isExactAllEnabled && !$scope.model.isShowMoreOptionsExpanded) {
            return -1;
        } else {
            return 0;
        }
    };
}]);

angular.module('SearchFormServiceUI').controller('MatchAllTermsExactlyModuleController', ['$scope', function ($scope) {
    $scope.checkBoxChanged = function () {
        $scope.$root.$broadcast('toggleMatchAllTermsExactly', !$scope.model.isExactAllEnabled, $scope.domPrefix);
    };
}]);

angular.module('SearchFormServiceUI').service('urlCanonicalizerProvider', [function() {
    return {
        getUrlCanonicalizer: function () { return urlCanonicalizer; }
    };
}]);

angular.module('SearchFormServiceUI').service('nodeObjectHashProvider', [function() {
    return {
        getHasher: function(configuration) { return nodeObjectHash(configuration).hash; }
    };
}]);
angular.module('SearchFormServiceUI').controller('CategoryBucketCtrl', ['$scope', 'sfsDataModelProvider', function ($scope, sfsDataModelProvider) {
    var alertMessage = $scope.sfsData.categoryBucket.alertMessage;

    $scope.getSubmitValue = function () {
        return sfsDataModelProvider.getCategoryBucketString($scope.domPrefix);
    };

    $scope.categoryBucketChanged = function (nameOfChangedCategoryBucketProperty) {
        // if all category buckets are unchecked, show the user an alert message that they must select at least one category
        // then check the checkbox they last unchecked to get into this state.
        var submitValue = $scope.getSubmitValue();
        if (submitValue === '') {
            $scope.model.types[nameOfChangedCategoryBucketProperty] = true;
            alert(alertMessage);
        }
    };
}]);
angular.module('SearchFormServiceUI').controller('CollectionFocusModuleController', ['$rootScope', '$scope', '$window', 'queryParamCollection', function ($rootScope, $scope, $window, queryParamCollection) {
    var defaultSearchBlock = '0';
    $scope.collectionFocusList = [{ group: null, name: 'All Collections', value: '0', groupName: null, gpids: null, location: null }];
    $scope.searchBlock = defaultSearchBlock;
    var metadata = $window[$scope.domPrefix + 'ScopeMetadata'];
    $scope.showsplitnew = true;
    var collectionList = metadata.collectionFocus.items; 

    var focussplitexp = window.splitExperiments != null ? window.splitExperiments["international-optimization"] : "off";

    /* istanbul ignore next */ 
    $scope.collectionFocusList = $.map(collectionList, function (item) {
    if (item.id === "1") {
        if (focussplitexp === "on") {
            return {
                value: item.id,
                name: item.localizedName,
                group: item.group,
                groupName: item.weightGroupName,
                location: item.location,
                gpids: item.locationGpids
            };
        }
    }
    else {
        return {
            value: item.id,
            name: item.localizedName,
            group: item.group,
            groupName: item.weightGroupName,
            location: item.location,
            gpids: item.locationGpids
        };
      }
    });

    $scope.updateCollectionFocus = function () {
        var newCollectionFocus = $.grep($scope.collectionFocusList, function (elem) {
            return elem.value === $scope.searchBlock;
        })[0];
        $scope.model.location = [];
        $scope.model.location = newCollectionFocus.gpids;
        $scope.model.priority = newCollectionFocus.groupName || '';
        $rootScope.$broadcast('collectionFocusChanged', $scope.model);
    };

    $scope.$watch('model.priority', function () {
        /* istanbul ignore next */
        var priority = $scope.model.priority
        if (typeof $scope.model.priority === "object") {
            priority = Object.values(priority).join("");
        }
        /* istanbul ignore next */
        var newCollectionFocus = $.grep($scope.collectionFocusList, function (elem) {
            return priority === elem.groupName;
        })[0];
        if (newCollectionFocus) {
            $scope.searchBlock = newCollectionFocus.value;
        } else {
            $scope.searchBlock = defaultSearchBlock;
            $scope.updateCollectionFocus();
        }
    });
}]);
angular.module('SearchFormServiceUI').controller('ContentBasedFormCtrl', ['$scope', '$window', 'sfsCookies', 'userPreferences', function ($scope, $window, sfsCookies, userPreferences) {
    $scope.showLicensePage = false;

    $scope.initLicensePage = function (dbid) {
        $scope.showLicensePage = true;
        try {
            if((window.ancestry && window.ancestry.search) && ((window.ancestry.search.newSliderEditForm && window.ancestry.search.newSliderEditForm == 'on') ||
            (window.ancestry.search.facetData && window.ancestry.search.facetData.isCollectionResults 
                && window.ancestry.search.facetData.isCollectionResults === true))){
                $scope.showLicensePage = false;
            } else {
                $scope.showLicensePage = $.inArray(dbid, $window.Ancestry.SFS.Settings.AcceptedDbLicenses) == -1;
            }
        } catch (e) { }
    };

    function updateCookie() {
        var cookieStr = sfsCookies.get('VARSESSION') || '';

        if (cookieStr.indexOf('NovaS=1') !== -1) {
            return;
        }

        if (cookieStr.length > 0) {
            cookieStr = cookieStr + '&';
        }

        cookieStr = 'VARSESSION=' + cookieStr + 'NovaS=1';

        var domain = getCookieDomain();
        if (domain) {
            cookieStr += ';domain=' + domain;
        }

        sfsCookies.set('VARSESSION', cookieStr, 365 * 20);
    }

    function getCookieDomain() {
        var location = $window.location.hostname;
        var firstDot = location.indexOf('.');

        return firstDot > 0 ? location.substring(firstDot, location.length) : '';
    }

    $scope.hideLicensePage = function () {
        $scope.showLicensePage = false;

        updateCookie();
    };

    $scope.$on('personPickerUpdated', function ($event, domPrefix) {
        $scope.showUnviewedRecordsOnly = $window &&
        $window.ancestry &&
        $window.ancestry.search &&
        $window.ancestry.search.globalSearchformMetadata &&
        $window.ancestry.search.globalSearchformMetadata.authorizedFeatures &&
        $window.ancestry.search.globalSearchformMetadata.authorizedFeatures.unviewedRecordsFilter === 'allow' 
    });


    // to show the checkbox
        $scope.showUnviewedRecordsOnly = $window &&
                            $window.ancestry &&
                            $window.ancestry.search &&
                            $window.ancestry.search.globalSearchformMetadata &&
                            $window.ancestry.search.globalSearchformMetadata.authorizedFeatures &&
                            $window.ancestry.search.globalSearchformMetadata.authorizedFeatures.unviewedRecordsFilter === 'allow' && 
                            $scope.model.treePerson && 
                            $scope.model.treePerson.treeId &&
                            $scope.model.treePerson.personId;
        
    // checkbox value
        $scope.toggleShowUnviewedRecordsOnly = function () {
            $scope.$root.$broadcast('toggleShowUnviewedRecordsOnly', !$scope.model.showUnviewedRecordsOnly, $scope.domPrefix);
        };

}]);
angular.module('SearchFormServiceUI').controller('DayModuleCtrl', ['$scope', 'sfsDataModelProvider', function ($scope, sfsDataModelProvider) {
    $scope.searchKey = '';
    $scope.day = 'unselected';
    $scope.event = null;

    $scope.init = function (moduleGroupId, moduleId, searchKey) {
        $scope.searchKey = searchKey;
        $scope.event = sfsDataModelProvider.getEvent($scope.domPrefix, moduleGroupId);
    };

    $scope.updateDay = function () {
        var day = $scope.day === 'unselected' ? null : parseInt($scope.day);
        day = day != null && day >= 1 && day <= 31 ? day : null;
        $scope.event.date.day = day;
    }

    $scope.$watch('event.date.day', function () {
        $scope.day = $scope.event.date.day != null ? $scope.event.date.day.toString() : "unselected";
    });
}]);
angular.module('SearchFormServiceUI').controller('EstBirthYearExactCtrl', ['$scope', 'sfsDataModelProvider', '$rootScope', function ($scope, sfsDataModelProvider, $rootScope) {
    var birthGroupName = "SelfBirth";
    $scope.birthEventGroup = sfsDataModelProvider.getEventGroup($scope.domPrefix, birthGroupName);
    $scope.birthEvent = null;
    $scope.$watchCollection('birthEventGroup.instances', function () {
        $scope.birthEvent = $scope.birthEventGroup.instances.length >= 1 ? $scope.birthEventGroup.instances[0] : null;
        $scope.year = $scope.birthEvent != null ? $scope.birthEvent.date.year : $scope.year;
    });
    $scope.$watch('birthEvent.date.year', function () {
        $scope.year = $scope.birthEvent == null ? null : $scope.birthEvent.date.year;
    });
    var maxAgeValue = 120;
    var minYearValue = 1000;
    $scope.showerror = false;

    var deletedDateObj = null;
    $scope.inputYearChanged = function () {
        if ($scope.year == null) {
            $scope.birthEvent.date.year = null;
            var birthEventHasOnlyYearData = $scope.birthEvent.location.length === 0;
            if (birthEventHasOnlyYearData) {
                deletedDateObj = $.extend(true, {}, $scope.birthEvent);
                sfsDataModelProvider.removeEvent($scope.domPrefix, birthGroupName, 0);
                $scope.birthEvent = null;
            }
        } else {
            if ($scope.birthEventGroup.instances.length === 0) {
                $scope.birthEvent = sfsDataModelProvider.addEvent($scope.domPrefix, birthGroupName, 0);
                if (deletedDateObj) {
                    $scope.birthEvent.dateProximity.year = deletedDateObj.dateProximity.year;
                }
            } else {
                $scope.birthEvent = $scope.birthEventGroup.instances[0];
            }
            $scope.birthEvent.date.year = $scope.year;
        }
    };

    $scope.$on('clearFormEvent', function (event, domPrefix) {
        if ($scope.domPrefix != domPrefix) return;
        deletedDateObj = null;
    });

    $scope.$on('personPickerUpdated', function ($event, domPrefix) {
        if ($scope.domPrefix != domPrefix) return;
        deletedDateObj = null;
    });

    $scope.$on('toggleMatchAllTermsExactly', function ($event, isMatchAll, domPrefix) {
        if ($scope.domPrefix != domPrefix) return;
        deletedDateObj = !isMatchAll ? null : deletedDateObj;
    });

    $scope.ageChange = function () {
        if ($scope.yeage && !isYearValid()) {
            $scope.showerror = true;
            //$rootScope && $rootScope.$emit && $rootScope.$emit('reopen')
        }
        $scope.errorFix();
    };

    $scope.yearChange = function () {
        $scope.errorFix();
    };

    function isAgeValid() {
        return $scope.yeage != null && $scope.yeage > 0 && $scope.yeage <= maxAgeValue;
    };

    function isYearValid() {
        return $scope.yeyear != null && $scope.yeyear > minYearValue;
    };

    $scope.errorFix = function () {
        if ($scope.yeage == null && $scope.yeyear == null) {
            $scope.showerror = false;
        } else if ($scope.yeage != null && isAgeValid()) {
            $scope.showerror = false;
        } else if ($scope.yeyear != null && isYearValid()) {
            $scope.showerror = false;
        }
    };

    $scope.calloutClose = function () {
        if ($scope.yeage == null && $scope.yeyear == null) return true;
        if (isAgeValid() && isYearValid()) {
            $scope.year = $scope.yeyear - $scope.yeage;
            $scope.inputYearChanged();
            return true;
        }
        $scope.showerror = true;
        //$rootScope && $rootScope.$emit && $rootScope.$emit('reopen')
        return false;
    };
}]);
(function () {
    var nextEventId = 0;

    angular.module('SearchFormServiceUI').controller('EventSelectorCtrl', ['$scope', '$window', 'sfsDataModelProvider', 'sfsObjectCache',
        function ($scope, $window, sfsDataModelProvider, cache) {

            $scope.exactSearchEnabled = false;
            $scope.eventTypesInHeaderRow = ['SelfBirth', 'SelfMarriage', 'SelfDeath', 'SelfResidence', 'Self'];
            $scope.eventTypes = ['SelfBirth', 'SelfMarriage', 'SelfDeath', 'SelfResidence', 'SelfArrival', 'SelfDeparture', 'SelfMilitary', 'Self'];
            var eventTypeToCharMap = { 'SelfBirth': 'b', 'SelfMarriage': 'g', 'SelfDeath': 'd', 'SelfResidence': 'r', 'SelfArrival': 'a', 'SelfDeparture': 'e', 'SelfMilitary': 'i', 'Self': 'y' };
            $scope.lastExactOption = null;
            $scope.lastExactFlag = null;
            var subTypes = { 'EXACTNESS': '_x' };
            $scope.display = $scope.sfsData.eventDisplay;  // sfsData object is defined on a parent scope in SearchFormCtrl controller
            $scope.eventToFocus = null;
            $scope.arrivalButtonId = $scope.domPrefix + "arrivalButton";
            $scope.departureButtonId = $scope.domPrefix + "departureButton";
            $scope.militaryButtonId = $scope.domPrefix + "militaryButton";

            (function populateEventGroups() {
                $scope.eventGroups = [];
                $.each($scope.eventTypes, function (eventTypeIndex, eventType) {
                    $scope.eventGroups.push(sfsDataModelProvider.getEventGroup($scope.domPrefix, eventType));
                });
            })();

            $scope.allEvents = [];
            function refreshAllEvents() {
                $scope.allEvents = [];
                $.each($scope.eventGroups, function (index, eventGroup) {
                    var groupName = eventGroup.relation + eventGroup.eventName;
                    $.each(eventGroup.instances, function (eventInstanceIndex, eventInstance) {
                        var eventData = cache.get("EventSelectorCtrl", eventInstance, groupName);
                        if (!eventData) {
                            eventData = {
                                groupName: groupName,
                                data: eventInstance,
                                id: 'event' + nextEventId++
                            }
                            cache.set(eventData, "EventSelectorCtrl", eventInstance, eventData.groupName);
                        }
                        eventData.index = eventInstanceIndex;
                        $scope.allEvents.push(eventData);
                    });
                });
            }
            $.each($scope.eventTypes, function (eventTypeIndex, eventType) {
                $scope.$watchCollection('model.events.' + eventType + '.instances', refreshAllEvents);
            });

            $scope.getSelectorId = function (type) { return $scope.domPrefix + "addEventLink_" + type; };
            $scope.firstEventSelector = '#' + $scope.getSelectorId('SelfBirth');

            $scope.shouldFocus = function (event) {
                if ($scope.eventToFocus != null && $scope.eventToFocus === event) {
                    $scope.eventToFocus = null;
                    return true;
                }
                return false;
            };

            $scope.addEvent = function (type) {
                var event = sfsDataModelProvider.addEvent($scope.domPrefix, type);
                if (event != null) {
                    $scope.eventToFocus = event;
                }
                $scope.closeCallout();
            };

            $scope.hitLimit = function (type) {
                var eventGroup = sfsDataModelProvider.getEventGroup($scope.domPrefix, type);
                var maxCount = sfsDataModelProvider.getEventGroupMaxCount($scope.domPrefix, type);
                return eventGroup.instances.length >= maxCount;
            };

            $scope.removeEvent = function (event, index) {
                if ($scope.focusToNextRow) {
                    $scope.focusToNextRow(index);
                }
                sfsDataModelProvider.removeEvent($scope.domPrefix, event.groupName, event.index);
            };

            $scope.getDateParam = function (event, paramType, subType) {
                var eventTypeChar = eventTypeToCharMap[event.groupName];
                var indexStr = event.index == 0 ? '' : event.index;
                return 'ms' + eventTypeChar + 'd' + paramType + indexStr + (subType ? subTypes[subType] : '');
            };

            $scope.getPlaceParam = function (event) {
                var eventTypeChar = eventTypeToCharMap[event.groupName];
                var indexStr = event.index > 0 ? event.index : '';
                return 'ms' + eventTypeChar + 'pn' + indexStr;
            };
        }]);
})();
(function () {
    var nextFamilyId = 0;

    angular.module('SearchFormServiceUI').controller('FamilySelectorCtrl', ['$scope', 'queryParamCollection', 'sfsDataModelProvider', 'sfsObjectCache', function ($scope, queryParamCollection, sfsDataModelProvider, cache) {
        $scope.strings = $scope.sfsData.familyDisplay;
        $scope.memberTypes = ['father', 'mother', 'sibling', 'spouse', 'child'];
        $scope.hasMemberWithGivenName = determineHasMemberWithGivenName();
        var newMember = null;

        var getParam = function (nameType, index, config, isExactness) {
            var memberIndex = index === 0 ? '' : index.toString();
            var paramName = nameType[0] === 'g' ? config.givenNameParam : config.surnameParam;
            if (nameType.length === 2) {
                paramName += nameType[1];
            }
            return paramName + memberIndex + (isExactness ? '_x' : '');
        };

        $scope.allMembers = [];
        function refreshAllMembers() {
            $scope.allMembers = [];
            $scope.memberTypes.forEach(function (memberType) {
                var familyGroup = $scope.model.family[memberType];
                var familyGroupConfig = $scope.metadata.family[memberType];
                familyGroup.instances.forEach(function (member, index) {
                    if (!familyGroupConfig.hasGivenName && !familyGroupConfig.hasSurname) {
                        return;
                    }
                    var data = cache.get("FamilySelectorCtrl", member, memberType);
                    if (!data) {
                        data = {
                            relationship: memberType,
                            member: member,
                            config: familyGroupConfig,
                            id: nextFamilyId++,
                            focusOnce: newMember === member
                        };
                        cache.set(data, "FamilySelectorCtrl", member, memberType);
                    }
                    data.index = index;
                    data.givenNameParam             = getParam('g', index, familyGroupConfig);
                    data.givenNameExactnessParam    = getParam('g', index, familyGroupConfig, true);
                    data.surnameParam               = getParam('s', index, familyGroupConfig);
                    data.surnameExactnessParam      = getParam('s', index, familyGroupConfig, true);
                    if ($scope.metadata.name.usesParentSurnameFields) {
                        data.firstSurnameParam = getParam('sf', index, familyGroupConfig);
                        data.secondSurnameParam = getParam('ss', index, familyGroupConfig);
                    }
                    $scope.allMembers.push(data);
                });
            });
            newMember = null;
        };
        Object.keys($scope.model.family).forEach(function(relationship) {
            $scope.$watchCollection('model.family.' + relationship + '.instances', refreshAllMembers);
        });
        refreshAllMembers();

        function determineHasMemberWithGivenName() {
            var hasGivenName = false;
            $.each($scope.metadata.family, function (memberType) {
                hasGivenName = hasGivenName || $scope.metadata.family[memberType].hasGivenName;
            });
            return hasGivenName;
        };

        $scope.getSelectorId = function (familyGroup) { return $scope.domPrefix + "addMemberLink_" + familyGroup; };

        function getFirstFamilyGroupSelector() {
            var firstFamilyGroup = null;
            $scope.memberTypes.forEach(function (familyGroup) {
                if (firstFamilyGroup == null && $scope.metadata.family[familyGroup].maxCount > 0) {
                    firstFamilyGroup = familyGroup;
                }
            });
            return "#" + $scope.getSelectorId(firstFamilyGroup);
        };

        $scope.firstFamilyGroupSelector = getFirstFamilyGroupSelector();

        $scope.addMember = function (familyMemberType) {
            newMember = sfsDataModelProvider.addFamilyMember($scope.domPrefix, familyMemberType);
        };

        $scope.hitLimit = function (familyMemberType) {
            return $scope.model.family[familyMemberType].instances.length >= $scope.metadata.family[familyMemberType].maxCount;
        };

        $scope.removeMember = function (relationship, indexInGroup, index) {
            if ($scope.focusToNextRow) {
                $scope.focusToNextRow(index);
            }
            sfsDataModelProvider.removeFamilyMember($scope.domPrefix, relationship, indexInGroup);
        };
    }]);
})();
var sfsFdidDropDownCtrlInstance = 0;
angular.module('SearchFormServiceUI').controller('FdidDropdownCtrl', ['$scope', function ($scope) {
    sfsFdidDropDownCtrlInstance++;
    $scope.exactCheckboxId = $scope.domPrefix + 'sfsFdidDropDownCtrl-' + sfsFdidDropDownCtrlInstance;

    $scope.init = function (fieldName, key, list) {
        $scope.field = $scope.model.fields[fieldName];
        $scope.exactKey = key + '_x';
        $scope.options = list;
    };
}]);
'use strict';
(function () {
    angular.module('SearchFormServiceUI').controller('FdidHierarchicalFilterFieldCtrl', ['$scope', 'sfsFilterHierarchyService', function ($scope, sfsFilterHierarchyService) {
        $scope.filters = [];
        $scope.field = null;
        var _fieldName;

        $scope.init = function (fieldName) {
            _fieldName = fieldName;
            $scope.field = $scope.model.fields[fieldName];
            loadFilter([], function () {
                $scope.$watch('field.value', function (newVal, oldVal) {
                    if (newVal == null || newVal === '' || $scope.filters.length === 0) {
                        return;
                    }

                    var lastFilter = $scope.filters[$scope.filters.length - 1];
                    if (isNullOrWhitespace($scope.field.value)) {
                        if (lastFilter.isLeaf) {
                            $scope.field.value = '';
                            lastFilter.selectedValue = '';
                        }
                    } else if (!lastFilter.isLeaf || lastFilter.selectedValue !== newVal) {
                        sfsFilterHierarchyService.getValuePathFromFieldValue($scope.domPrefix, _fieldName, $scope.field.value, function (err, valuePath) {
                            if (err) {
                                $scope.filters = [];
                                $scope.error = true;
                            } else {
                                loadFilters(valuePath);
                            }
                        });
                    }
                });
            });
        };

        $scope.$on('clearFormEvent', function (event, domPrefix) {
            if ($scope.domPrefix !== domPrefix || $scope.filters.length === 0) return;
            $scope.filters[0].selectedValue = '';
            $scope.filterChanged($scope.filters[0], 0);
        });

        $scope.filterChanged = function (filter, index) {
            var option = getSelectedOption(filter);

            // Sync field value
            if (!option || !option.isLeaf) {
                // Set to empty whenever there are more filter values to fill out
                $scope.field.value = "";
                filter.isLeaf = false;
            } else {
                $scope.field.value = filter.selectedValue;
                filter.isLeaf = true;
            }

            // When a drop down is set to the empty value...
            if (!option) {
                // Remove filters after the current filter
                $scope.filters.splice(index + 1, $scope.filters.length - 1 - index);
                return;
            }

            if (filter.selectedValue.length > 0 && option && !option.isLeaf) {
                var path = [];
                for (var i = 0; i <= index; ++i) {
                    path.push($scope.filters[i].selectedValue);
                }
                loadFilter(path);
            }
        };

        function isNullOrWhitespace(str) {
            return str == null || /^s*$/.test(str);
        };

        function getSelectedOption(filter) {
            for (var i = 0; i < filter.options.length; ++i) {
                if (filter.options[i].value === filter.selectedValue) {
                    return filter.options[i];
                }
            }
        };

        function loadFilters(valuePath, doneCount) {
            doneCount = doneCount || 0;
            loadFilter(valuePath.slice(0, doneCount), function (err, filter) {
                if (!err) {
                    filter.selectedValue = valuePath[doneCount];
                    filter.isLeaf = valuePath.length - 1 === doneCount;
                    if (filter.isLeaf) {
                        $scope.field.value = filter.selectedValue;
                    } else {
                        loadFilters(valuePath, doneCount + 1);
                    }
                }
            });
        };

        function loadFilter(path, callback) {
            $scope.filters.splice(path.length, $scope.filters.length - path.length);
            sfsFilterHierarchyService.getFieldFilter($scope.domPrefix, _fieldName, path, function (err, filter) {
                if (err) {
                    $scope.filters = [];
                    $scope.error = true;
                    if (callback) callback(err);
                } else {
                    filter.id = "sfsHierarchyFilter_" + _fieldName + "_" + (path.length);
                    filter.selectedValue = '';
                    $scope.filters.push(filter);
                    if (callback) callback(null, filter);
                }
            });
        };
    }]);
})();'use strict';
(function () {
    angular.module('SearchFormServiceUI').controller('FdidHierarchicalFilterPlaceCtrl', ['$scope', 'sfsFilterHierarchyService', 'sfsDataModelProvider', function ($scope, sfsFilterHierarchyService, sfsDataModelProvider) {
        $scope.filters = [];
        $scope.event = null;
        var _eventGroupName;

        $scope.init = function (eventGroupName) {
            _eventGroupName = eventGroupName;
            $scope.event = sfsDataModelProvider.getEvent($scope.domPrefix, eventGroupName, 0);
            loadFilter([], function () {
                $scope.$watch('event', function (newVal, oldVal) {
                    if ($scope.filters.length === 0) {
                        return;
                    }

                    var lastFilter = $scope.filters[$scope.filters.length - 1];

                    if (isNullOrWhitespace($scope.event.location) || isNullOrWhitespace($scope.event.gpid)) {
                        if (lastFilter.isLeaf) {
                            $scope.event.location = '';
                            $scope.event.gpid = '';
                            lastFilter.selectedValue = '';
                            lastFilter.selectedGpid = '';
                        }
                    } else if (!lastFilter.isLeaf || (lastFilter.selectedValue !== $scope.event.location || lastFilter.selectedGpid !== $scope.event.gpid)) {
                        sfsFilterHierarchyService.getValuePathFromPlaceValue($scope.domPrefix, _eventGroupName, $scope.event.location, function (err, valuePath) {
                            if (err) {
                                $scope.filters = [];
                                $scope.error = true;
                            } else {
                                loadFilters(valuePath);
                            }
                        });
                    }
                }, true);
            });
        };

        $scope.$on('clearFormEvent', function (event, domPrefix) {
            if ($scope.domPrefix !== domPrefix || $scope.filters.length === 0) return;
            $scope.filters[0].selectedValue = '';
            $scope.filterChanged($scope.filters[0], 0);
        });

        $scope.filterChanged = function (filter, index) {
            var option = getSelectedOption(filter);

            // Sync field value
            if (!option || !option.isLeaf) {
                // Set to empty whenever there are more filter values to fill out
                $scope.event.location = "";
                $scope.event.gpid = "";
                filter.selectedGpid = "";
                filter.isLeaf = false;
            } else {
                $scope.event.location = option.value;
                $scope.event.gpid = option.gpid;
                filter.selectedGpid = $scope.event.gpid;
                filter.isLeaf = true;
            }

            // When a drop down is set to the empty value...
            if (!option) {
                // Remove filters after the current filter
                $scope.filters.splice(index + 1, $scope.filters.length - 1 - index);
                return;
            }

            if (filter.selectedValue.length > 0 && option && !option.isLeaf) {
                var path = [];
                for (var i = 0; i <= index; ++i) {
                    path.push($scope.filters[i].selectedValue);
                }
                loadFilter(path);
            }
        };

        function isNullOrWhitespace(str) {
            return str == null || /^s*$/.test(str);
        };

        function getSelectedOption(filter) {
            for (var i = 0; i < filter.options.length; ++i) {
                if (filter.options[i].value === filter.selectedValue) {
                    return filter.options[i];
                }
            }
        };

        function loadFilters(valuePath, doneCount) {
            doneCount = doneCount || 0;
            loadFilter(valuePath.slice(0, doneCount), function (err, filter) {
                if (!err) {
                    filter.selectedValue = valuePath[doneCount];
                    filter.isLeaf = valuePath.length - 1 === doneCount;
                    if (filter.isLeaf) {
                        var option = getSelectedOption(filter);
                        filter.selectedGpid = option.gpid;
                        $scope.event.location = filter.selectedValue;
                        $scope.event.gpid = option.gpid;
                    } else {
                        loadFilters(valuePath, doneCount + 1);
                    }
                }
            });
        };

        function loadFilter(path, callback) {
            $scope.filters.splice(path.length, $scope.filters.length - path.length);
            sfsFilterHierarchyService.getPlaceFilter($scope.domPrefix, _eventGroupName, path, function (err, filter) {
                if (err) {
                    $scope.filters = [];
                    $scope.error = true;
                    if (callback) callback(err);
                } else {
                    filter.id = "sfsHierarchyFilter_" + _eventGroupName + "_" + (path.length);
                    filter.selectedValue = '';
                    $scope.filters.push(filter);
                    if (callback) callback(null, filter);
                }
            });
        };
    }]);
})();var sfsFdidTextBoxCtrlInstance = 0;
angular.module('SearchFormServiceUI').controller('FdidTextBoxCtrl', ['$scope', function ($scope) {
    ++sfsFdidTextBoxCtrlInstance;
    $scope.exactnessId = $scope.domPrefix + 'sfsFdidTextBoxCtrl-' + sfsFdidTextBoxCtrlInstance;

    $scope.init = function (fieldName, key) {
        $scope.field = $scope.model.fields[fieldName];
        $scope.exactKey = key + '_x';
    };
}]);
angular.module('SearchFormServiceUI').controller('FirstAnyEventCtrl', ['$scope', 'sfsDataModelProvider', function ($scope, sfsDataModelProvider) {
    var tempEvent = { location: "", date: { year: null }, locationExactness: {} };
    $scope.event = tempEvent;
    $scope.$watch('model.events.Self.instances.length', function() {
        $scope.event = $scope.model.events.Self.instances.length === 0 ? tempEvent : $scope.model.events.Self.instances[0];
        if ($scope.model.events.Self.instances.length === 0) {
            $scope.event = tempEvent;
            tempEvent.location = "";
        } else {
            $scope.event = $scope.model.events.Self.instances[0];
        }
    });
    $scope.onLocationChange = function() {
        if ($scope.event.location != null && $scope.event.location.length > 0) {
            if ($scope.model.events.Self.instances.length === 0) {
                sfsDataModelProvider.addEvent($scope.domPrefix, 'Self', 0);
            }
            $scope.model.events.Self.instances[0].location = $scope.event.location;
            $scope.event = $scope.model.events.Self.instances[0];
        } else if ($scope.event.location.length === 0 && $scope.event.date.year == null) {
            sfsDataModelProvider.removeEvent($scope.domPrefix, 'Self', 0);
            // There may be multiple "Self" events (Any events) on the form or the "Self" event instance might be a singleton in which case
            // it cannot be removed.  Either way, if there is still an instance of the "Self" event, then bind to that.
            if ($scope.model.events.Self.instances.length === 0) {
                $scope.event = tempEvent;
                tempEvent.location = "";
            } else {
                $scope.event = $scope.model.events.Self.instances[0];
            }
        }
    };
}]);
angular.module('SearchFormServiceUI').controller('GenderCtrl', ['$scope', function ($scope) {
    var gender = $scope.model.fields.SelfGender;
    $scope.genderSubmitValue = '';

    function updateGenderSubmitValueFromModel() {
        if (gender.value === "male") {
            $scope.genderSubmitValue = 'f';
        } else if (gender.value === "female") {
            $scope.genderSubmitValue = 'm';
        } else {
            $scope.genderSubmitValue = '';
        }
    }

    $scope.genderSubmitValueChanged = function () {
        if ($scope.genderSubmitValue === "f") {
            gender.value = "male";
        } else if ($scope.genderSubmitValue === "m") {
            gender.value = "female";
        } else {
            gender.value = null;
            $scope.genderSubmitValue = "";
        }
    };

    $scope.$watch('model.fields.SelfGender', updateGenderSubmitValueFromModel, true);

    updateGenderSubmitValueFromModel();
}]);
angular.module('SearchFormServiceUI').controller('MonthModuleCtrl', ['$scope', 'sfsDataModelProvider', function ($scope, sfsDataModelProvider) {
    $scope.searchKey = '';
    $scope.month = 'unselected';
    $scope.event = null;

    $scope.init = function (moduleGroupId, moduleId, searchKey) {
        $scope.searchKey = searchKey;
        $scope.event = sfsDataModelProvider.getEvent($scope.domPrefix, moduleGroupId);
    };

    $scope.updateMonth = function () {
        var month = $scope.month === 'unselected' ? null : parseInt($scope.month);
        month = month != null && month >= 1 && month <= 12 ? month : null;
        $scope.event.date.month = month;
    }

    $scope.$watch('event.date.month', function () {
        $scope.month = $scope.event.date.month != null ? $scope.event.date.month.toString() : "unselected";
    });
}]);
angular.module('SearchFormServiceUI').controller('MSVController', ['$scope', function ($scope) {
    var isOldSearchSimulator = $scope.metadata.form.type === 'OldSearchSimulator';
    if (isOldSearchSimulator) {
        var defaultViewMode = $scope.metadata.form.isRefiningSearch ? $scope.model.viewMode : 'record';
        $scope.$watch('model.isExactAllEnabled', function() {
            $scope.model.viewMode = $scope.model.isExactAllEnabled ? 'category' : defaultViewMode;
        });
    }
}]);
angular.module('SearchFormServiceUI').controller('YearModuleCtrl', ['$scope', 'sfsDataModelProvider', function ($scope, sfsDataModelProvider) {
    $scope.searchKey = '';
    $scope.event = null;

    $scope.init = function (moduleGroupId, moduleId, searchKey) {
        $scope.searchKey = searchKey;
        $scope.event = sfsDataModelProvider.getEvent($scope.domPrefix, moduleGroupId);
    };
}]);
angular.module('SearchFormServiceUI').controller('YearRangeCtrl', ['$scope', '$rootScope', function ($scope, $rootScope) {
    $scope.parentForm = null;
    $scope.showError = false;
    $scope.preventSubmit = false;

    $scope.init = function (formName) {
        $scope.parentForm = $scope[formName];
    };

    $scope.rangeChanged = function (event) {
        if ($scope.model && $scope.model.range) {
            var isValidRange = ($scope.model.range.yearStart == null || $scope.model.range.yearEnd == null)
                || ($scope.model.range.yearStart <= $scope.model.range.yearEnd);
            if (isValidRange) {
                $scope.showError = false;
                $scope.preventSubmit = false;
                $scope.parentForm.ossyear.$setValidity('yearRange', true);
            } else {
                $scope.preventSubmit = true;
            }
        }
        if (event && event.keyCode == 13) {
            $scope.checkRange();
        }
    };

    $scope.$watch('model.range', $scope.rangeChanged, true);

    $scope.checkRange = function () {
        if ($scope.model.range.yearStart != null && $scope.model.range.yearEnd != null) {
            $scope.showError = $scope.model.range.yearStart > $scope.model.range.yearEnd;
            if ($scope.showError) {
                $rootScope.$broadcast('yearRangeHasError', $scope.model);
            }
        } else {
            $scope.showError = false;
        }
        $scope.parentForm.ossyear.$setValidity('yearRange', !$scope.showError);
    };

    $scope.$on('yearRangeHasError', function (event, model) {
        if ($scope.model !== model) return;
        $scope.showError = true;
        $scope.parentForm.ossyear.$setValidity('yearRange', false);
    });

    $scope.$on('modelLoaded', function (event, domPrefix) {
        if (domPrefix !== $scope.domPrefix) return;
        $scope.checkRange();
    });
}]);
angular.module('SearchFormServiceUI').directive('sfsAllowDigitsOnly', ['$window', '$timeout', function ($window, $timeout) {
    function isDescendant(parent, child) {
        var node = child.parentNode;
        while (node != null) {
            if (node === parent) return true;
            node = node.parentNode;
        }
        return false;
    }
    function hasSelectedText(element) {
        try {
            var selection = $window.getSelection();
            if (selection != null && selection.type === "Range" && isDescendant(selection.anchorNode, $(element)[0])) {
                return true;
            }
            var domElement = element[0];
            var hasSelection = domElement.selectionStart != null && domElement.selectionEnd != null && domElement.selectionStart != domElement.selectionEnd;
            return hasSelection;
        } catch (ex) {
            return false;
        }
    }

    var keycodes = {
        backspace: 8,
        deleteKey: 46,
        tab: 9,
        escape: 27,
        enter: 13,
        pageUp: 33,
        pageDown: 34,
        endKey: 35,
        homeKey: 36,
        leftArrow: 37,
        upArrow: 38,
        rightArrow: 39,
        downArrow: 40,
        a: 65,
        v: 86
    };
    var allowedKeyCodes = [
        keycodes.backspace,
        keycodes.deleteKey,
        keycodes.tab,
        keycodes.escape,
        keycodes.enter,
        keycodes.pageUp,
        keycodes.pageDown,
        keycodes.endKey,
        keycodes.homeKey,
        keycodes.leftArrow,
        keycodes.upArrow,
        keycodes.rightArrow,
        keycodes.downArrow
    ];

    return {
        restrict: 'A',
        link: function (scope, element) {
            var maxLength = parseInt($(element).attr('maxlength')) || null;
            var maxNum = parseInt(new Array(maxLength + 1).join('9'));
            element.bind('paste', function(e) {
                $timeout(function() {
                    var sanitizedStr = element.val().replace(/[^0-9]/g, '');
                    if (maxLength != null) {
                        sanitizedStr = sanitizedStr.substr(0, Math.min(sanitizedStr.length, maxLength));
                    }
                    element.val(sanitizedStr);
                }, 0);
            });
            element.bind('keydown', function (e) {
                if ($.inArray(e.keyCode, allowedKeyCodes) !== -1 ||
                    // Allow: Ctrl+V
                    (e.ctrlKey === true && e.keyCode === keycodes.v) ||
                    // Allow: Ctrl+A, Command+A
                    ((e.ctrlKey === true || e.metaKey === true) && e.keyCode === keycodes.a)) {
                    // If down or up arrow is used, ensure they don't allow number to go negative or exceed max length
                    var isNotArrowKey = e.keyCode !== keycodes.downArrow && e.keyCode !== keycodes.upArrow;
                    var isDownArrowAboveMin = e.keyCode === keycodes.downArrow && $(element).val() > 0;
                    var isUpArrowBelowMax = e.keyCode === keycodes.upArrow;
                    if (isUpArrowBelowMax && maxLength != null) {
                        isUpArrowBelowMax = $(element).val() < maxNum;
                    }
                    if (isNotArrowKey || isDownArrowAboveMin || isUpArrowBelowMax) {
                        return;
                    } else {
                        e.preventDefault();
                    }
                }
                // Ensure that it is a number.  If not, stop the keypress
                if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }

                var isMaxLengthExceeded = maxLength != null && $(element).val().toString().length >= maxLength;
                if (!hasSelectedText(element) && isMaxLengthExceeded) {
                    e.preventDefault();
                }
            });
        }
    };
}]);
/*  element to which this directive is attached is a trigger
    sfn-callout - id on element where callout content is
    sfn-callout-visible - is the content of callout is visible,
    sfn-callout-focus - id of focused element */
angular.module('SearchFormServiceUI').directive('sfsCallout', ['$timeout', '$window', '$rootScope', function ($timeout, $window, $rootScope) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var isAUI2 = typeof ui === 'object' && ui.callout;
            var isExist;
            var calloutInstance = null;
            element.on('$destroy', function cleanup() {
                if (scope.destroyCallout) scope.destroyCallout();
            });
            $timeout(function () {                
                scope.destroyCallout = function () {
                    // An opened callout should be closed before it is destroyed.
                    scope.closeCallout();

                    if (isExist) {
                        /**
                         * TODO: [TA63726][SUI-SFS] Replace AngularJS (aka Angular 1.x) search form - remove Ancestry UI 1.x jQuery logic
                         */
                        if (isAUI2) {
                            if (calloutInstance.destroy) {
                                calloutInstance.destroy();
                            }
                        } else {
                            element.callout('destroy');
                        }
                        isExist = false;
                    }
                };

                scope.closeCallout = function () {
                    if (isExist) {
                        /**
                         * TODO: [TA63726][SUI-SFS] Replace AngularJS (aka Angular 1.x) search form - remove Ancestry UI 1.x jQuery logic
                         */
                        if (isAUI2) {
                            ui.callout.close();
                        } else {
                            element.callout('close');
                        }
                    }
                };

                scope.openCallout = function() {
                    if (isExist) {
                        /**
                         * TODO: [TA63726][SUI-SFS] Replace AngularJS (aka Angular 1.x) search form - remove Ancestry UI 1.x jQuery logic
                         */
                        if (isAUI2) {
                            calloutInstance.open(); 
                        } else {
                            element.callout('open');
                        }
                    }
                }

                scope.createCallout = function () {
                    /**
                     * TODO: [TA63726][SUI-SFS] Replace AngularJS (aka Angular 1.x) search form - remove Ancestry UI 1.x jQuery logic
                     */
                    var closeButtonId = attr.closeButtonId;
                    var sfsOnCloseButtonEvent = attr.sfsOnCloseButtonEvent;
                    var sfsCalloutContainerId = attr.sfsCallout;
                    var sfsCalloutFocusId = attr.sfsCalloutFocus;
                    var sfsCalloutFocusList = attr.sfsCalloutFocusList;

                    if (isAUI2) {
                        var trigger = element[0];
                        var triggersToContentWithNonSubmittingButtons = [
                            'sfs_Calc_Trigger'
                        ];
                        var hasNonSubmitButton = triggersToContentWithNonSubmittingButtons.indexOf(trigger.id) > -1;

                        calloutInstance = ui.callout(trigger, {
                            onAfterOpen: function () {
                                // When a callout is opened, focus the specified descendant element found via sfs-callout-focus attribute.
                                if (sfsCalloutFocusId) {
                                    var sfsCalloutFocusElement = document.getElementById(sfsCalloutFocusId);
                                    if (sfsCalloutFocusElement) {
                                        window.setTimeout(function() {
                                            sfsCalloutFocusElement.focus();
                                        }, 250);
                                    }
                                } else if (sfsCalloutFocusList) {
                                    var listOfElementsToFocus = scope.$eval(sfsCalloutFocusList);
                                    listOfElementsToFocus.forEach(function(elementIdToFocus) {
                                        var $elementIdToFocus = document.querySelector('#' + elementIdToFocus + ':enabled');
                                        if ($elementIdToFocus) {
                                            $elementIdToFocus.focus();
                                        }
                                    });
                                }

                                var callbackMethodName, callbackArgumentsArray = [];
                                if (typeof sfsOnCloseButtonEvent === 'string') {
                                    var callbackArray = sfsOnCloseButtonEvent.split('(');
                                    callbackMethodName = callbackArray[0].trim();
                                    var callbackArgumentsString = callbackArray[1];
                                    if (callbackArgumentsString) {
                                        callbackArgumentsArray = callbackArgumentsString.slice(0, -1)
                                          .split(',')
                                          .map(function(argString) {
                                              return argString.trim();
                                          });
                                    }
                                }

                                var closeButton = document.getElementById(closeButtonId);
                                if (closeButton) {
                                    closeButton.addEventListener('click', function() {
                                        if (hasNonSubmitButton && sfsOnCloseButtonEvent) {
                                            var isValid = scope[callbackMethodName].apply(this, callbackArgumentsArray);
                                             /* istanbul ignore next */
                                            if (isValid) {
                                                scope.closeCallout();
                                            } else {
                                                /* istanbul ignore next */
                                                scope.closeCallout();
                                                /* istanbul ignore next */
                                                scope.openCallout();
                                            }
                                            scope.$apply();
                                        }
                                        else {
                                            scope.closeCallout();
                                        }
                                    });
                                }

                                var sfsCalculatorContainer = document.getElementById(sfsCalloutContainerId);
                                if (sfsCalculatorContainer) {
                                    sfsCalculatorContainer.addEventListener('keyup', function(ev) {
                                        if (ev.key === 'Enter') {
                                            ev.stopPropagation();
                                            if (sfsOnCloseButtonEvent) {
                                                var isValid = scope[callbackMethodName].apply(this, callbackArgumentsArray);
                                                 /* istanbul ignore next */
                                                if (isValid) {
                                                    scope.closeCallout();
                                                } else {
                                                    /* istanbul ignore next */
                                                    scope.closeCallout();
                                                    /* istanbul ignore next */
                                                    scope.openCallout();
                                                }
                                                scope.$apply();
                                            } else {
                                                scope.closeCallout();
                                                element.closest('form').submit();
                                            }
                                        }
                                    });
                                }
                            },
                            calloutStyle: 'light',
                            type: 'click',
                            content: document.getElementById(sfsCalloutContainerId) || '',
                            classes: 'SUI_SFS_form',
                            position: 'bottom'
                        });
                    } else {
                        element.callout({
                            onAfterOpen: function () {
                                // When a callout is opened, focus the specified descendant element found via sfs-callout-focus attribute.
                                if (sfsCalloutFocusId) {
                                    $('#' + sfsCalloutFocusId).focus();
                                } else if (sfsCalloutFocusList) {
                                    var listOfElementsToFocus = scope.$eval(sfsCalloutFocusList);
                                    for (var i = 0; i < listOfElementsToFocus.length; ++i) {
                                        var elementIdToFocus = listOfElementsToFocus[i];
                                        var elements = $('#' + elementIdToFocus + ':enabled');
                                        if (elements.length === 1) {
                                            elements.focus();
                                            break;
                                        }
                                    }
                                }

                                if (closeButtonId) {
                                    angular.element('#' + closeButtonId).bind('click', function () {
                                        scope.$apply(function () {
                                            if (sfsOnCloseButtonEvent) {
                                                var isValid = scope.$eval(sfsOnCloseButtonEvent);
                                                if (isValid) {
                                                    scope.closeCallout();
                                                }
                                            } else {
                                                scope.closeCallout();
                                            }
                                        });
                                        returnFocus();
                                    });
                                }

                                if (sfsCalloutContainerId) {
                                    angular.element('#' + sfsCalloutContainerId).keyup(function (e) {
                                        // We don't run the code below for IE 9, because it exposes buggy behavior.
                                        if (e.keyCode == 13 && !$window.isIE9) {
                                            e.stopPropagation();
                                            if (sfsOnCloseButtonEvent) {
                                                var isValid = scope.$eval(sfsOnCloseButtonEvent);
                                                if (isValid) {
                                                    scope.closeCallout();
                                                    returnFocus();
                                                }
                                                scope.$apply();
                                            } else {
                                                scope.closeCallout();
                                                element.closest('form').submit();
                                            }
                                        }
                                    });
                                }
                            },
                            style: 'light',
                            type: 'click',
                            content: $('#' + sfsCalloutContainerId),
                            classes: 'SUI_SFS_form',
                            position: 'bottom',
                            align: 'center',
                            keepContentInPlace: true
                        });

                        function returnFocus() {
                            var sfsSetFocusAfterClose = attr.sfsSetFocusAfterClose;
                            if (sfsSetFocusAfterClose) {
                                var setFocustTo = angular.element('#' + sfsSetFocusAfterClose);
                                if (setFocustTo) {
                                    setFocustTo.focus();
                                }
                            }
                        }
                    }
                    isExist = true;
                };

                scope.createCallout();
            });
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsClearForm', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            element.on("click", function () {
                // angular.element('form[name=' + attr.sfsClearForm + ']')[0].reset();
                scope.$apply(function () {
                    scope.$root.$broadcast('clearFormEvent', scope.domPrefix);
                    scope[attr.sfsClearForm].$setPristine();
                });
            });
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsPreventSubmit', [function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if (event.which === 13) {
                if (attrs.sfsPreventSubmit.toLowerCase() === 'true') {
                    event.preventDefault();
                }
            }
        });
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsFocusOnce', [function () {
    return function (scope, element, attrs) {
        var setFocus = attrs.sfsFocusOnce.toLowerCase() === 'true';
        if (setFocus) {
            element[0].focus();
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsLocation', ['$window', '$timeout', 'sfsLocationExactnessProvider', function ($window, $timeout, sfsLocationExactnessProvider) {
    var sfsPlacePickerTemplate =
        '<div>' +
            '<span data-ng-if="gpidHintEnabled" data-ng-show="showGpidHint" style="color: #4272A7;" class="errorMessage icon iconInfo">{{strings.gpidHint}}</span>' +
            '<input autocomplete="off" class="placepicker" type="text" id="{{inputDomId}}" name="{{gpidName}}__ftp" ' +
                'data-ng-class="{\'error error-blue\': showNoGpidHint}" ' +
                'data-ng-change="textChanged()" ' +
                'placeholder="{{strings.placeholder}}" ' +
                'data-ng-model="event.location" ' +
                'data-autoname="PlacePickerTextBox" />' +
            '<input type="hidden" name="{{gpidName}}" value="{{event.gpid}}" data-ng-if="event.gpid.length > 0" /> ' +
            '<input type="hidden" name="{{gpidName}}_PInfo" value="{{pinfo}}" data-ng-if="event.gpid.length > 0" />' +
        '</div>';
    return {
        restrict: 'E',
        replace: true,
        scope: {
            'event': '=',
            'gpidName': '@',
            'inputDomId': '@',
            'autoname': '@',
            'maxResults': '@',
            'domPrefix': '@',
            'notifyTextChanged': '&onTextChange',
            'gpidHintEnabledAttr': '@gpidHintEnabled'
        },
        template: sfsPlacePickerTemplate,
        link: function (scope, element, attrs) {
            var minLengthForAutocompleteToShow = 3;

            var sfsData = $window[scope.domPrefix + 'sfsData'];
            var metadata = $window[scope.domPrefix + 'ScopeMetadata'];
            var eventPlaceStrs = sfsData['eventPlace'];
            scope.pinfo = null;
            scope.gpidHintEnabled = scope.gpidHintEnabledAttr && metadata.form.type === 'OldSearchSimulator';
            scope.strings = {
                gpidHint: eventPlaceStrs.gpidHint,
                placeholder: eventPlaceStrs.placeholder
            };

            scope.$watch('event.gpid', function () {
                scope.pinfo = null;
                if (scope.event.gpid != null && scope.event.gpid.length > 0 && scope.event.location != null && scope.event.location.length > 0) {
                    sfsLocationExactnessProvider.getPlaceInfoByPrefixMatchingGpid(scope.domPrefix, scope.event.location, scope.event.gpid, function (err, placeInfo) {
                        if (err) return;
                        scope.pinfo = placeInfo.level + '-' + '|' + placeInfo.idHierarchy.join('|') + '|';
                    });
                }
            });

            scope.textChanged = function () {
                scope.event.gpid = null;
                scope.event.locationExactness.level = scope.event.locationExactness.isExact ? 0 : null;
                scope.event.locationExactness.isAdjacent = false;
                scope.notifyTextChanged();
            };

            function updatePlaceWithAutcompleteValue(autoCompleteValue) {
                sfsLocationExactnessProvider.cachePlaceByPrefixResponse(autoCompleteValue);
                scope.$apply(function () {
                    scope.event.gpid = autoCompleteValue.Id.toString();
                    scope.event.location = autoCompleteValue.HName;
                    scope.showGpidHint = false;
                });
            };

            function selectAutocompleteValueIfTextMatches(autocompleteValues) {
                if (!scope.event.gpid && autocompleteValues && autocompleteValues.length > 0) {
                    for (var i = 0; i < autocompleteValues.length; i++) {
                        if (trimSpacesAndLowerCase(scope.event.location) === trimSpacesAndLowerCase(autocompleteValues[i].HName)) {
                            var matchedPlace = autocompleteValues[i];
                            updatePlaceWithAutcompleteValue(matchedPlace);
                            break;
                        }
                    }
                }
            };

            function trimSpacesAndLowerCase(placeStr) {
                var parts = placeStr.split(",");
                for (var i = 0; i < parts.length; i++) {
                    parts[i] = [parts[i].toLowerCase().trim()];
                }
                return parts.join();
            }

            function updatePlacePickerHintVisibility() {
                if (!scope.gpidHintEnabled) {
                    return;
                }

                //we only show the hint message when no GPID is available and the field contains a string
                //of a minimal length
                scope.$apply(function () {
                    scope.showGpidHint = (!scope.event.gpid && scope.event.location.length >= minLengthForAutocompleteToShow);
                });
            };

            var placePickerSourceUrl = (eventPlaceStrs.placePickerUrl || '//placepfx.ancestry.com/s/') +
                '?maxCount=' + (scope.maxResults || 8) +
                '&cultureId=' + (sfsData.cultureId || 'en-US');
            var placePickerResults;
            $timeout(function () {
                /**
                 * TODO: [TA63726][SUI-SFS] Replace AngularJS (aka Angular 1.x) search form - remove Ancestry UI 1.x jQuery logic
                 */
                if (typeof ui === 'object' && ui.autocomplete) {
                    ui.autocomplete('#' + scope.inputDomId, {
                        'customDisplay': function (config) {
                            var data = config.data;
                            return {
                                display: data.display,
                                value: data.value
                            }
                        },
                        dataType: 'jsonp',
                        jsonConversion: function (config) {
                            var data = config.data;
                            placePickerResults = data;
                            return data;
                        },
                        searchKey: 'HName',
                        minLength: minLengthForAutocompleteToShow,
                        onClose: function () {
                            selectAutocompleteValueIfTextMatches(placePickerResults);
                            updatePlacePickerHintVisibility();
                        },
                        onItemSelect: function (config) {
                            var input = config.element;
                            var data = input.getAttribute('data-extra');
                            var savedResponse = data ? JSON.parse(data) : config.item;
                            updatePlaceWithAutcompleteValue(savedResponse);
                        },
                        queryParameter: 'prefix',
                        source: placePickerSourceUrl
                    });
                } else {
                    $('#' + scope.inputDomId).autocomplete({
                        'customDisplay': function (data) {
                            return {
                                'display': data.value,
                                'value': data.value
                            }
                        },
                        'dataType': 'jsonp',
                        'jsonConversion': function (data) {
                            placePickerResults = data;
                            return data;
                        },
                        'key': 'HName',
                        'minLength': minLengthForAutocompleteToShow,
                        'onClose': function (event, $resultContainer, formattedData) {
                            if ($.autocomplete.version === 1) {
                                selectAutocompleteValueIfTextMatches(formattedData);
                            } else {
                                selectAutocompleteValueIfTextMatches(placePickerResults);
                            }
                            updatePlacePickerHintVisibility();
                        },
                        'onResultSelect': function (input) {
                            var data = input.attr('data-extra');
                            var savedResponse = data ? $.parseJSON(data) : input.data('raw');
                            updatePlaceWithAutcompleteValue(savedResponse);
                        },
                        'queryParameter': 'prefix',
                        'source': placePickerSourceUrl,
        
                        //V1 autocomplete specific options - Remove after all clients update to V2 Autocomplete
                        'sourceUrl': placePickerSourceUrl,
                        'queryString': 'prefix',
                        'saveResponse': true,
                        'preFiltered': true,
                        'itemHandler': function (value, itemData, searchTerm) {
                            return { 'inputText': value, 'displayText': value };
                        }
                    });
                }
            });
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsLocationExactness', ['$window', 'sfsLocationExactnessProvider', function ($window, sfsLocationExactnessProvider) {
    var uniqueId = 0;
    return {
        restrict: 'E',
        replace: true,
        scope: {
            'event': '=',
            'gpidKey': '@',
            'domPrefix': '@',
            'autonamePrefix': '@'
        },
        templateUrl: '/sfs-location-exactness.html',
        link: function (scope, element, attrs) {
            ++uniqueId;
            scope.exactnessAcronym = null;
            scope.uniquePrefix = scope.domPrefix + "-sfsLocationExactness-" + uniqueId;
            scope.calloutId = scope.uniquePrefix + '-place-callout';
            scope.exactInputId = scope.uniquePrefix + '-exact';
            var sfsData = $window[scope.domPrefix + 'sfsData'];
            scope.searchDomain = sfsData.searchDomain;
            var exactnessStrs = sfsData.eventPlaceExactnessDisplay;
            var eventPlaceStrs = sfsData['eventPlace'];
            scope.strings = {
                exactTo: eventPlaceStrs.exactTo,
                aboutTheseSettings: eventPlaceStrs.aboutTheseSettings
            };
            scope.relativeExactnesses = [];
            var exactToThisPlaceExactness = {
                relativeLevel: 0,
                absoluteLevel: -1,
                isAdjacent: false,
                acronym: '1',
                optionString: sfsData.eventPlaceExactnessSelectDisplay['1'],
                exactnessString: exactnessStrs.exactToThisPlace,
                exactnessStringWhenOneExactnessChoice: exactnessStrs.exactToThisPlace
            };

            function getCurrentPlaceExactnessByAcronym() {
                var placeExactness = null;
                scope.relativeExactnesses.forEach(function (loopPlaceExactness) {
                    if (loopPlaceExactness.acronym === scope.exactnessAcronym) {
                        placeExactness = loopPlaceExactness;
                    }
                });
                return placeExactness;
            }

            // This method exists to keep scope.relativeExactnesses and scope.exactnessAcronym in sync based on what the current gpid is.  
            // Both of these scope variables only apply when there is a gpid, otherwise they should be empty / null.
            function onGpidChanged() {
                if (scope.event.gpid == null || scope.event.gpid.length === 0) {
                    scope.relativeExactnesses.length = 0;
                    scope.exactnessAcronym = null;
                    return;
                }
                sfsLocationExactnessProvider.getPlaceInfoByPrefixMatchingGpid(scope.domPrefix, scope.event.location, scope.event.gpid, function (err, placeInfo) {
                    var exactnesses = sfsLocationExactnessProvider.getExactnesses(scope.domPrefix, scope.event.gpid);
                    scope.relativeExactnesses.length = 0;
                    scope.relativeExactnesses.push.apply(scope.relativeExactnesses, exactnesses);  // add all exactnesses items to scope.relativeExactnesses
                    // Remove first exactness and replace with the "This place" exactness
                    if (scope.relativeExactnesses.length > 0) {
                        var firstExactness = scope.relativeExactnesses.splice(0, 1, exactToThisPlaceExactness)[0];
                        exactToThisPlaceExactness.exactnessStringWhenOneExactnessChoice = firstExactness.exactnessString;
                    }
                    scope.exactnessAcronym = null;
                    if (scope.event.locationExactness.isExact) {
                        scope.exactnessAcronym = '1';
                        for (var i = 0; i < scope.relativeExactnesses.length; ++i) {
                            var relativeExactness = scope.relativeExactnesses[i];
                            if (relativeExactness.relativeLevel === scope.event.locationExactness.level &&
                                relativeExactness.isAdjacent === scope.event.locationExactness.isAdjacent) {
                                scope.exactnessAcronym = relativeExactness.acronym;
                                break;
                            }
                        }
                        if (scope.exactnessAcronym === '1') {
                            scope.event.locationExactness.level = 0;
                            scope.event.locationExactness.isAdjacent = false;
                        }
                    }
                });
            };
            function syncPlaceExactness() {
                scope.exactnessAcronym = null;
                scope.relativeExactnesses.forEach(function (placeExactness) {
                    // Assign scope place exactness if it's a match to the loop place exactness
                    if (scope.event.locationExactness.level === placeExactness.relativeLevel &&
                        scope.event.locationExactness.isAdjacent === placeExactness.isAdjacent) {
                        scope.exactnessAcronym = placeExactness.acronym;
                    }
                });
            };
            scope.$watch('event.gpid', onGpidChanged);
            scope.$watch('event.locationExactness', syncPlaceExactness, true);
            scope.getExactnessButtonText = function () {
                // Handle no gpid / no relative exactnesses scenario (just a exact not exact scenario)
                if (scope.relativeExactnesses.length === 0) return eventPlaceStrs.exact;
                // Return the only relative exactness text when there is only one
                if (scope.relativeExactnesses.length === 1) return scope.relativeExactnesses[0].exactnessStringWhenOneExactnessChoice;
                // When not exact and there are multiple exactness choices, so exactness text with ellipsis
                if (!scope.event.locationExactness.isExact) return eventPlaceStrs.exactTo;
                if (scope.event.locationExactness.level === 0 && !scope.event.locationExactness.isAdjacent) return exactnessStrs['exactToThisPlace'];
                var placeExactness = getCurrentPlaceExactnessByAcronym();
                if (scope.event.locationExactness.level == null || placeExactness == null) return eventPlaceStrs.exact;
                return placeExactness.exactnessString;
            };
            scope.updateIsExact = function (isExact) {
                scope.event.locationExactness.isExact = isExact;
                if (!scope.event.locationExactness.isExact) {
                    scope.exactnessAcronym = null;
                    scope.event.locationExactness.level = null;
                    scope.event.locationExactness.isAdjacent = false;
                } else {
                    scope.exactnessAcronym = scope.exactnessAcronym || '1';
                    scope.event.locationExactness.level = scope.event.locationExactness.level || 0;
                }
            };
            scope.placeExactnessChanged = function () {
                var placeExactness = getCurrentPlaceExactnessByAcronym();
                scope.event.locationExactness.isExact = true;
                scope.event.locationExactness.level = placeExactness.relativeLevel;
                scope.event.locationExactness.isAdjacent = placeExactness.isAdjacent;
            };
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsNameExactness', ['$window', function ($window) {
    var sfsFirstNameExactnessTemplate =
        '<div class="exact-filter" data-ng-show="name[nameProp].length > 0">' +
            '<input type="hidden" name="{{searchKey}}_x" data-ng-if="isRequired" value="1" />' +
            '<input type="hidden" name="{{searchKey}}_x" data-ng-if="!isRequired && name[nameProp].length > 0" value="{{exactnessString}}" data-submit-default="" />' +
            '<div data-ng-if="isRequired">' +
                '<button type="button" class="link icon iconCheck locationButton">{{exactnessLabel}}</button>' +
            '</div>' +
            '<div data-ng-if="!isRequired">' +
                '<button type="button" data-ng-class="{iconCheck: exactness.isExact, inputBox: !exactness.isExact}" class="link icon locationButton" ' +
                        'data-ng-click="enableNameExactness()" ' +
                        'data-sfs-callout="{{calloutId}}" ' +
                        'data-sfs-callout-focus="{{calloutExactInputId}}" ' +
                        'data-autoname="{{autoname}}">{{exactnessLabel}}' +
                '</button>' +
                '<div id="{{calloutId}}" class="{{moduleClass}} form SUI_SFS_form calloutDomContent">' +
                    '<div>' +
                        '<input class="checkbox" id="{{calloutExactInputId}}" type="checkbox" ' +
                                'data-autoname="ExactAnd" data-ng-change="updateNameExactness()" data-ng-model="exactness.isExact">' +
                        '<label for="{{calloutExactInputId}}">{{strings.exactAndEllipsisText}}</label>' +
                    '</div>' +
                    '<div class="indent" data-ng-repeat="chk in exactnessCheckboxes" data-ng-init="checkboxId = $parent.calloutExactCheckboxIdPrefix + chk.prop">' +
                        '<input class="checkbox" id="{{checkboxId}}" type="checkbox" data-autoname="{{chk.autoname}}" ' +
                                'data-ng-model="exactness.flags[chk.prop]" data-ng-change="exactness.isExact = true">' +
                        '<label for="{{checkboxId}}">{{chk.label}}</label>' +
                    '</div>' +
                    '<div class="about">' +
                        '<button class="link" type="button" data-autoname="Settings" ' +
                                'data-sfs-show-help-for="{{showHelpForName}}" data-search-domain="{{searchDomain}}">{{strings.aboutTheseSettingsText}}</button>' +
                    '</div>' +
                '</div>' +
            '</div>' +
        '</div>';
    var uniqueId = 0;
    return {
        restrict: 'E',
        replace: true,
        scope: {
            'name': '=',
            'domPrefix': '@',
            'isGivenName': '='
        },
        template: sfsFirstNameExactnessTemplate,
        link: function (scope, element, attrs) {
            ++uniqueId;
            var uniqueNamePrefix = scope.domPrefix + 'sfsNameExactness-' + uniqueId;
            var metadata = $window[scope.domPrefix + 'ScopeMetadata'];
            var sfsData = $window[scope.domPrefix + 'sfsData'];
            var sfsDataCheckboxes = scope.isGivenName ? sfsData.name.firstNameCheckboxes : sfsData.name.lastNameCheckboxes;

            scope.strings = sfsData.name;
            scope.nameProp = scope.isGivenName ? 'givenName' : 'surname';
            scope.exactness = scope.isGivenName ? scope.name.givenNameExactness : scope.name.surnameExactness;
            scope.searchKey = scope.isGivenName ? 'gsfn' : 'gsln';
            scope.exactnessString = '0';
            scope.exactnessLabel = scope.strings.exactText;
            scope.showHelpForName = scope.isGivenName ? "fname" : "lname";
            scope.moduleClass = scope.isGivenName ? "SUI_SFS_FirstNameExact_Container" : "SUI_SFS_LastNameExact_Container";
            scope.autoname = scope.isGivenName ? "FirstNameExact" : "LastNameExact";
            scope.calloutId = uniqueNamePrefix + '-callout';
            scope.calloutExactInputId = uniqueNamePrefix + '-callout-input';
            scope.calloutExactCheckboxIdPrefix = uniqueNamePrefix + '-callout-checkbox-';
            scope.searchDomain = sfsData.searchDomain;
            scope.exactnessCheckboxes = $.extend(true, [], sfsDataCheckboxes);
            scope.isRequired = attrs.isRequired === 'true';

            function getExactnessText() {
                var exactText = scope.strings.exactText;
                if (!scope.exactness.isExact) return exactText + '...';
                var labels = [exactText];
                $.each(scope.exactnessCheckboxes, function (checkboxIndex, checkboxObj) {
                    if (scope.exactness.flags[checkboxObj.prop]) {
                        labels.push(checkboxObj.label.toLowerCase());
                    }
                });
                if (labels.length === 1) return labels[0];
                var lastLabel = labels.pop();
                return labels.join(', ') + ' & ' + lastLabel;
            };

            function updateExactnessString() {
                if (scope.exactness.isExact) {
                    var exactnessStrArr = [];
                    var flagToExactnessStrMap = metadata.name.flagToExactnessStrMap;
                    $.each(scope.exactness.flags, function (flagName, flagBoolValue) {
                        if (flagBoolValue === true) {
                            scope.exactness.isExact = true;
                            exactnessStrArr.push(flagToExactnessStrMap[flagName]);
                        }
                    });
                    scope.exactnessString = exactnessStrArr.length === 0 ? '1' : exactnessStrArr.join('_');
                } else {
                    scope.exactnessString = '0';
                }
                scope.exactnessLabel = getExactnessText();
            };

            scope.enableNameExactness = function () {
                if (!scope.exactness.isExact) {
                    scope.exactness.isExact = true;
                    updateExactnessString();
                }
            };

            scope.updateNameExactness = function () {
                if (!scope.exactness.isExact) {
                    $.each(scope.exactness.flags, function (key) {
                        scope.exactness.flags[key] = false;
                    });
                }
                updateExactnessString();
            };

            scope.$watch('name.' + scope.nameProp + 'Exactness', updateExactnessString, true);
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsNosubmit', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            if (attrs.sfsNosubmit !== undefined && attrs.sfsNosubmit.toLowerCase() === 'true') {
                element.closest('form').bind('submit', function () {
                    element.find('input').attr('disabled', 'disabled');
                });
            }
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsPersonPicker', ['$timeout', 'sfsCookies', 'sfsPersonPickerService', 'sfsQuickTilesUtils', '$rootScope','$window', function ($timeout, sfsCookies, sfsPersonPickerService, sfsQuickTilesUtils, $rootScope,$window) {
    var constants = {
        ENUS: "en-US",
        GLOBAL: "Global",
        GSLN: "gsln",
        GSFN: "gsfn",
        SURNAME: "Surname",
        GIVENNAME: "GivenName",
        isSurnameVisible: 'isSurnameVisible',
        isGivenNameVisible: 'isGivenNameVisible',
        BUSINESS_EVENT_FN: 'quickfill-givenname',
        BUSINESS_EVENT_LN: 'quickfill-surname'
    }
return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            
            // Skip person picker functionality for instituional accounts or when explicitly disabled (like for AlaCarte)
            if (scope.sfsData.user.isInstitutional || !scope.sfsData.personPicker.enabled) return;
            // Get the quick fill text from resource
            var quickFillSuggestions= $window[scope.domPrefix+'sfsData'].personPicker.quickFillSuggestions;
            var nameType = attrs.name == constants.GSLN ? constants.SURNAME : constants.GIVENNAME;
            var formDetails = sfsQuickTilesUtils.getVersionSettings();
            var formType = sfsQuickTilesUtils.getFormType();
            var cultureId = sfsQuickTilesUtils.getCultureId();
            if (cultureId == constants.ENUS && formType == constants.GLOBAL) {
                var visibleType = attrs.name == constants.GSLN ? constants.isSurnameVisible : constants.isGivenNameVisible;
                if (!formDetails[visibleType]) {
                    return;
                }
            } else if (nameType === constants.SURNAME) {
                return;
            }
            var treeSelectionLabel = scope.sfsData.personPicker.treeSelectionLabel;
            var treeLabel = scope.sfsData.personPicker.treeLabel;
            var autoCompleteInstance;

            var personPickerUrl = (attrs.sfsPersonPicker || '//search.ancestry.com/personpicker/'),
                personPickerTreeList = null,
                selectedTreeId = null,
                allowTreeRequestForTabKey = true;

            element
                .bind('mousedown', function () {
                    if (!personPickerTreeList) {
                        loadTreeData();
                    }
                    else {
                        var treeData = sfsPersonPickerService.getTreeData();
                        if(!treeData) {
                            sfsPersonPickerService.setTreeData(personPickerTreeList);
                        }
                    }
                })
                .bind('keyup', function (event) {
                    // treeDate request will not be made if element gets tab focus and then losts focus in less than half a second
                    if (event.which === 9 && !personPickerTreeList) {
                        $timeout(function () {
                            if (allowTreeRequestForTabKey) {
                                loadTreeData();
                            } else {
                                allowTreeRequestForTabKey = true;
                            }
                        }, 500);
                    }
                }).bind('blur', function () {
                allowTreeRequestForTabKey = false;
            });

            function loadTreeData() {
                $.getJSON(personPickerUrl + "trees/?callback=?", null, function (treeData) {
                    personPickerTreeList = treeData;
                    // If user does not have a tree, then do not show the person picker
                    if (treeData == null || treeData.AllTrees == null || treeData.AllTrees.length === 0) {
                        return;
                    }

                    // Ensure treeData.SelectedTree is populated.  The response may come back with multiple trees but not a selected tree.
                    // if this is the case then first try to choose a tree using the personPickerSelectedTreeId cookie value, otherwise
                    // just select the first tree from the collection.
                    if (!treeData.SelectedTree) {
                        var cookieTreeId = sfsCookies.get('personPickerSelectedTreeId');
                        if (typeof cookieTreeId === 'string') {
                            var selectedTrees = treeData.AllTrees.filter(function(tree) {
                                return tree.TreeId === cookieTreeId;
                            });
                            treeData.SelectedTree = selectedTrees.length > 0 ? selectedTrees[0] : null;
                        }
                    }
                    treeData.SelectedTree = treeData.SelectedTree || (treeData.AllTrees.length > 0 ? treeData.AllTrees[0] : null);
                    if (!treeData.SelectedTree) {
                        return;
                    }
                    selectedTreeId = treeData.SelectedTree.TreeId;

                    // Save the selected tree id to a cookie (unless the cookie already had that value)
                    if (treeData.SelectedTree.TreeId !== cookieTreeId) {
                        sfsCookies.set('personPickerSelectedTreeId', treeData.SelectedTree.TreeId, 365);
                    }

                    createAutocomplete(treeData, selectedTreeId);
                    // Autocomplete needs this method to trigger the focus event to complete initialization.  We only do this if the
                    // element has focus currently (which is usually the case at this point).
                    if ($(element).is(':focus')) {
                        $(element).trigger('focus');
                    }
                });
            }

            

            function getTreeSelect(treeData) {
                var treeSelectPElement, treeSelectElement;
                var treeSelectContainerElement = document.createElement('div');
                // If tree data is available, put something in the container; otherwise it will be an empty DIV
                if (typeof treeData === 'object' && treeData.AllTrees) {
                    treeSelectContainerElement.classList.add('sfsTreeSelectionContainer');
                    treeSelectContainerElement.classList.add('form');
                    // If user has multiple trees, then create a select element
                    if (treeData.AllTrees.length > 1) {
                        var treeSelectLabelledbyId = 'treeSelectLabel';
                        treeSelectPElement = document.createElement('p');
                        treeSelectPElement.setAttribute('id', treeSelectLabelledbyId);
                        treeSelectPElement.textContent = treeSelectionLabel;
                        treeSelectContainerElement.appendChild(treeSelectPElement);

                        treeSelectElement = document.createElement('select');
                        treeSelectElement.setAttribute('aria-labelledby', treeSelectLabelledbyId);
                        // Add all trees to the select
                        treeData.AllTrees.forEach(function(tree) {
                            var option = document.createElement('option');
                            option.textContent = tree.Name;
                            option.setAttribute('value', tree.TreeId);
                            var selectedTreeIdfromService = sfsPersonPickerService.getTreeId();
                            if(selectedTreeIdfromService) {
                                selectedTreeId = selectedTreeIdfromService;
                            }
                            if (tree.TreeId === selectedTreeId) {
                                option.setAttribute('selected', true);
                            }
                            treeSelectElement.appendChild(option);
                        });

                        var onChangeTreeSelectElement = function(selectedTreeId, isTreeSelectElement) {
                            $.getJSON(personPickerUrl + "setselectedtree/" + selectedTreeId + "/?callback=?", null, function (data, textStatus) { });
                            sfsCookies.set('personPickerSelectedTreeId', selectedTreeId, 365);
                            sfsPersonPickerService.setTreeId(selectedTreeId);
                            sfsPersonPickerService.setTreeData(treeData);
                            if(isTreeSelectElement) { 
                                element.blur();
                                createAutocomplete(treeData, selectedTreeId);
                                element.focus();
                            }
                            $rootScope.$emit('treechanged', selectedTreeId);
                        };
                        $rootScope.$on('onSelectedTreeChange', function (e, currentTree) {
                            $("option", treeSelectElement).each(function() {
                                var currentElement = $(this);
                                if (currentElement.attr("value") == currentTree) {
                                    currentElement.attr("selected", true);
                                } else {
                                    currentElement.removeAttr("selected", false)
                                }
                            }); 
                            if (autoCompleteInstance && autoCompleteInstance.config && autoCompleteInstance.config.source )  {
                                autoCompleteInstance.config.source = autoCompleteInstance.config.source.replace(selectedTreeId, currentTree);
                            }
                            selectedTreeId = currentTree;
                            if (autoCompleteInstance && typeof autoCompleteInstance.flush === 'function') {
                                autoCompleteInstance.flush();
                            }
                            onChangeTreeSelectElement(selectedTreeId);
                        });
                        treeSelectElement.addEventListener('change', function () {
                            var optionSelected = $(this).find('option:selected');
                            selectedTreeId = optionSelected.val();
                            sfsPersonPickerService.setTreeId(selectedTreeId);
                            onChangeTreeSelectElement(selectedTreeId, true);
                        });
                        treeSelectContainerElement.appendChild(treeSelectElement);
                    } else {
                        // User only has one tree, so just display the name of the tree (no need for a select)
                        treeSelectPElement = document.createElement('p');
                        treeSelectPElement.classname = 'sfsTreeSelectionContainer';
                        treeSelectPElement.textContent = treeLabel + ' ' + treeData.SelectedTree.Name;
                        treeSelectContainerElement.appendChild(treeSelectPElement);
                    }
                }
                return treeSelectContainerElement;
            } // End getTreeSelect function
            

            function createAutocomplete(treeData, tid) {
                var tidStr = typeof (tid) != "undefined" ? tid + "/" : "";
                /**
                 * TODO: [TA63726][SUI-SFS] Replace AngularJS (aka Angular 1.x) search form - remove Ancestry UI 1.x jQuery logic
                 */
                if (typeof ui === 'object' && ui.autocomplete) {
                    
                    

                    autoCompleteInstance = ui.autocomplete(element[0], {
                        source: personPickerUrl + 'suggest/' + tidStr,
                        onSearch: function() {
                            var quickFillVersion = sfsQuickTilesUtils.getQuickFillVersion();
                            if (cultureId == constants.ENUS && formType == constants.GLOBAL && ( quickFillVersion === 'v1' || quickFillVersion === 'v2')) {
                                var treeId = sfsPersonPickerService.getTreeId() || selectedTreeId;
                                var tidStr = typeof (treeId) != "undefined" ? treeId + "/" : "";
                                autoCompleteInstance.flush();  
                                if(element[0].dataset.autoname==='FirstNameTextBox'){
                                    autoCompleteInstance.config.queryParameter='partialFirstName';
                                    autoCompleteInstance.config.source = personPickerUrl + 'suggest/' + tidStr+"?partialLastName="+$("input[name='"+ constants.GSLN +"']").val();
                                }else {
                                    autoCompleteInstance.config.source = personPickerUrl + 'suggest/' + tidStr+"?partialFirstName="+ $("input[name='"+ constants.GSFN +"']").val();
                                    autoCompleteInstance.config.queryParameter='partialLastName';
                                }
                            }               
                        },
                        onOpen: function() {
                            var treeData = sfsPersonPickerService.getTreeData();
                            if(treeData) {
                                var elemtns = getTreeSelect(treeData);
                                $('.sfsTreeSelectionContainer').remove();
                                $('.autocompleteAfter').append(elemtns);
                                autoCompleteInstance.flush();
                            }
                        },
                        customDisplay: function (config) {
                            var data = config.data;
                            var itemData = data.raw;
                            // Format autocomplete items so that a date range appears on the right
                            var dateRange = '';
                            if (itemData.BirthYear !== null) {
                                dateRange = itemData.BirthYear + "-";
                            }
                            if (itemData.DeathYear !== null) {
                                dateRange += itemData.DeathYear;
                            }
                            var displayHtml;
                            if (itemData.FormattedFullName) {
                                displayHtml = $('<div/>')
                                    .html(itemData.FormattedFullName).text()                       // html encode the name
                                    .replace(/(\$];)(\s+)(\$\[;)/g, "$1<span> </span>$3")
                                    .replace(/\$\[;/g, '<span class="autocompleteHighlighted">')   // add open highlight tag
                                    .replace(/\$];/g, '</span>');                                  // add close highlight tag
                            } else {
                                // Combine the given and surname and then html encode them
                                displayHtml = $('<div/>').html(itemData.FirstName + ' ' + itemData.LastName).text();
                                itemData.GivenName = itemData.FirstName;
                                itemData.Surname = itemData.LastName;
                            }
                            // Add date range
                            displayHtml = '<span class="sfsPersonPickerExtraData">' + dateRange + '</span>' + displayHtml;
                            return {
                                'value': itemData[nameType],
                                'display': displayHtml
                            };
                        },
                        //Prepend the Quick fill Suggestions options to the FN and LN dropdown
                        onResponse:function(){
                            if(formDetails.isGivenNameVisible && formDetails.isQuickTilesVisible && formDetails.isSurnameVisible){
                                $('.quick-tile-label').remove();
                                $('.autocompleteResults').prepend('<li class="autocompleteResult quick-tile-label" role="option" id="default" tabindex="0"><div class="textWrap autocompleteHighlighted icon iconDownload">'+quickFillSuggestions+'</div></li>');
                            }
                        },
                        dataType: 'jsonp',
                        searchKey: 'GivenName',
                        minLength: 3,
                        after: getTreeSelect(treeData),
                        onItemSelect: function (config) {
                            var input = config.element;
                            var data = input.getAttribute('data-extra');    
                            $rootScope.$emit('onCloseQuickTileSuggestion');
                            var person = data ? JSON.parse(data) : config.item;
                            person.TreeId = person.TreeId ? person.TreeId : selectedTreeId;
                            $.getJSON(personPickerUrl + 'person/' + person.TreeId + '/' + person.PersonId + '/?callback=?', null, function (personPickerResponse, textStatus) {
                                if (textStatus !== 'success') return;
                                // Update data model with 'searchType' business event as 'TreePerson_DropDown_FN' OR 'TreePerson_DropDown_LN'
                                // Pre populate form based on data that came back for selected person
                                var businessEvent = '';
                                if (input.name === constants.GSLN) businessEvent = constants.BUSINESS_EVENT_LN;
                                else if (input.name === constants.GSFN) businessEvent = constants.BUSINESS_EVENT_FN;
                                sfsPersonPickerService.updateModelFromPersonPickerResponse(scope.domPrefix, personPickerResponse, businessEvent);
                                input.blur();
                            });
                        },
                        queryParameter: 'partialFirstName'
                    });       
                } else {
                    function initAutoCompleteTreeSelection(autocompleteResultContainer, treeData) {
                        // If tree data is available and the trees selector hasn't already been added to the autocomplete, then add it
                        var treeSelectionContainer = autocompleteResultContainer.find(".sfsTreeSelectionContainer");
                        if (treeData != null && !treeSelectionContainer.length) {
                            // If user has multiple trees, then show a drop down
                            if (treeData.AllTrees.length > 1) {
                                var treeContainer = autocompleteResultContainer.append('<div class="sfsTreeSelectionContainer form"><hr />' + treeSelectionLabel + ' <select /></div>'),
                                    treeSelect = treeContainer.find('.sfsTreeSelectionContainer select');
                                // Add all trees to the drop down
                                for (var i = 0; i < treeData.AllTrees.length; ++i) {
                                    var tree = treeData.AllTrees[i];
                                    treeSelect.append($('<option>', {
                                        value: tree.TreeId,
                                        text: tree.Name,
                                        selected: tree.TreeId === selectedTreeId
                                    }));
                                }

                                treeSelect.change(function () {
                                    var optionSelected = $(this).find("option:selected");
                                    selectedTreeId = optionSelected.val();
                                    $.getJSON(personPickerUrl + "setselectedtree/" + selectedTreeId + "/?callback=?", null, function (data, textStatus) { });
                                    sfsCookies.set('personPickerSelectedTreeId', selectedTreeId, 365);
                                    element.blur();
                                    createAutocomplete(treeData, selectedTreeId);
                                    element.focus();
                                });
                            }
                            // User only has one tree, so just display the name of the tree (no need for a drop down)
                            else {
                                autocompleteResultContainer.append('<span class="sfsTreeSelectionContainer">' + treeLabel + ' ' + treeData.SelectedTree.Name + '</span>');
                            }
                        }
                    } // End initAutoCompleteTreeSelection function

                    element.autocomplete({
                        'source': personPickerUrl + "suggest/" + tidStr,
                        'customDisplay': function (data) {
                            var itemData = data.raw;
                            // Format autocomplete items so that a date range appears on the right
                            var dateRange = '';
                            if (itemData.BirthYear != null) {
                                dateRange = itemData.BirthYear + "-";
                            }
                            if (itemData.DeathYear != null) {
                                dateRange += itemData.DeathYear;
                            }
                            var displayHtml;
                            if (itemData.FormattedFullName) {
                                displayHtml = $('<div/>')
                                    .html(itemData.FormattedFullName).text()                       // html encode the name
                                    .replace(/(\$];)(\s+)(\$\[;)/g, "$1<span> </span>$3")
                                    .replace(/\$\[;/g, '<span class="autocompleteHighlighted">')   // add open highlight tag
                                    .replace(/\$];/g, '</span>');                                  // add close highlight tag
                            } else {
                                // Combine the given and surname and then html encode them
                                displayHtml = $('<div/>').html(itemData.FirstName + ' ' + itemData.LastName).text();
                                itemData.GivenName = itemData.FirstName;
                                itemData.Surname = itemData.LastName;
                            }
                            // Add date range
                            displayHtml = '<span class="sfsPersonPickerExtraData">' + dateRange + '</span>' + displayHtml;
                            return {
                                'value': itemData[nameType],
                                'display': displayHtml
                            };
                        },
                        'dataType': 'jsonp',
                        'key': 'GivenName',
                        'minLength': 3,
                        'onOpen': function ($resultContainer, $before) {
                            //V1 autocomplete signature for this function is (e, $resultContainer)
                            if ($.autocomplete.version === 1) {
                                //Remove after all clients update to V2 Autocomplete
                                initAutoCompleteTreeSelection($before, treeData);
                            } else {
                                initAutoCompleteTreeSelection($resultContainer, treeData);
                            }
                        },
                        'onResultSelect': function (input) {
                            // Remove after all clients update to V2 autocomplete
                            var data = input.attr('data-extra');
                            // Request search details for the selected person
                            var person = data ? $.parseJSON(data) : input.data('raw');

                            $.getJSON(personPickerUrl + "person/" + person.TreeId + "/" + person.PersonId + "/?callback=?", null, function (personPickerResponse, textStatus) {
                                if (textStatus !== 'success') return;
                                // Pre populate form based on data that came back for selected person
                                sfsPersonPickerService.updateModelFromPersonPickerResponse(scope.domPrefix, personPickerResponse);
                            });
                        },
                        'queryParameter': 'partialFirstName',

                        //V1 Autocomplete specific options - remove after all clients update to V2 autocomplete
                        'sourceUrl': personPickerUrl + "suggest/" + tidStr,
                        'queryString': 'partialFirstName',
                        'isPrefix': false,
                        'saveResponse': true,
                        'itemHandler': function (value, itemData) {
                            // Format autocomplete items so that a date range appears on the right
                            var dateRange = '';
                            if (itemData.BirthYear != null) {
                                dateRange = itemData.BirthYear + "-";
                            }
                            if (itemData.DeathYear != null) {
                                dateRange += itemData.DeathYear;
                            }
                            var displayHtml;
                            if (itemData.FormattedFullName) {
                                displayHtml = $('<div/>')
                                    .html(itemData.FormattedFullName).text()                       // html encode the name
                                    .replace(/\$\[;/g, '<span class="autocompleteHighlighted">')   // add open highlight tag
                                    .replace(/\$];/g, '</span>');                                  // add close highlight tag
                            } else {
                                // Combine the given and surname and then html encode them
                                displayHtml = $('<div/>').html(itemData.FirstName + ' ' + itemData.LastName).text();
                                itemData.GivenName = itemData.FirstName;
                                itemData.Surname = itemData.LastName;
                            }
                            // Add date range
                            displayHtml += '<span class="sfsPersonPickerExtraData">' + dateRange + '</span>';
                            return {
                                'inputText': itemData[nameType],
                                'displayText': displayHtml
                            };
                        }
                    });
                }
            }  // End createAutocomplete function
        } // End directive link function
    };
}]); // End sfsPersonPicker directive
angular.module('SearchFormServiceUI').directive('sfsQuickFillContainer', ['sfsCookies', 'sfsPersonPickerService', '$compile', 'sfsQuickTilesUtils', '$rootScope', '$timeout', 'sfsDataModelProvider', '$window', function (sfsCookies, sfsPersonPickerService, $compile, sfsQuickTilesUtils, $rootScope, $timeout, sfsDataModelProvider, $window) {
     /* istanbul ignore next */
    var quickFillTileHTML = '<div class="SUI_SFS_Quick_Fill_Tile" ng-show="isTreeAvailable && showSuggestion && allowPersonPick">'+
            '<div class="quick-fill-tiles-wrapper" ng-class="{\'redwood\':isRedwood}" >'+
				'<span class="icon iconClose quick-fill-close" ng-click="closeSuggestion()">'+
				'</span>'+
				'<h5 class="cardTitle">{{quickFillSuggestions}}</h5>'+
				'<a href="#" class="iconAfter iconArrowDownAfter SUI_SFS_List_Tree" id="SUI_SFS_List_Tree">{{personTrees.SelectedTree.Name}}</a>'+
                '<div class="calloutMenu SUI_SFS_All_Trees" id="SUI_SFS_All_Trees">'+
                    '<ul>'+
                        '<li class="sfs-tree-list" ng-repeat="tree in personTrees.AllTrees" >'+
                            '<a ng-class="{\'selected\':tree.TreeId === selectedTreeId}" ng-click="changeTreeId(tree.TreeId)">'+
                                '<span class="sfs-tree-name">{{tree.Name}}</span>'+
                                '<span ng-if="tree.TreeId === selectedTreeId" aria-hidden="true" class="icon iconCheck"></span>'+
                            '</a>'+
                        '</li>'+
                    '</ul>'+
                '</div>'+
				'<div class="quick-fill-tiles-container">'+
                '<div class="quick-fill-tiles-list-wrap" ng-repeat="person in personList" ng-hide="isLoading"  ng-if="$even">'+
                '<div class="quick-fill-tiles" ng-class="{ selectedSuggestion: person.PersonId == sfsSelectedPersonData(),\'card\':isRedwood}" title = {{person.FullName}} ng-click="selectSuggestion(person)">'+ 
                '<h4 ng-class="{\'quick-fill-tiles-allign-vertical\':!person.BirthYear && !person.DeathYear}">{{person.FullName}}</h4>' +
                    '<span ng-if="person.BirthYear || person.DeathYear">{{person.BirthYear?person.BirthYear+"-":""}}{{person.DeathYear}}</span>' +
                '</div>' +
                '<div ng-if="personList[$index+1]" class="quick-fill-tiles" ng-class="{ selectedSuggestion: personList[$index+1].PersonId == sfsSelectedPersonData(),\'card\':isRedwood}" title = {{person.FullName}} ng-click="selectSuggestion(personList[$index+1])">' +
                    '<h4 ng-class="{\'quick-fill-tiles-allign-vertical\':!personList[$index+1].BirthYear && !personList[$index+1].DeathYear}">{{personList[$index+1].FullName}}</h4>' +
                    '<span ng-if="personList[$index+1].BirthYear || personList[$index+1].DeathYear">{{personList[$index+1].BirthYear?personList[$index+1].BirthYear+"-":""}}{{personList[$index+1].DeathYear}}</span>' +
                '</div>' +
                '</div>' +
                '<div class="no-suggest" ng-if="!isLoading && allowPersonPick && personList.length == 0"> No suggestions found</div>'+
                '<div class="loading" ng-if="allowPersonPick && isLoading"></div>'+
                '</div>' +
				'<div ng-if="isShowNext" class="quick-filter-next" ng-click="onChangeNextTiles()">'+
					'<button type="button" class="quick-filter-arrow-circle icon iconArrowRight"></button>'+
				'</div>'+
				'<div ng-if="isShowPrev" class="quick-filter-prev" ng-click="onChangePrevTiles()">'+
					'<button type="button" class="quick-filter-arrow-circle icon iconArrowLeft"></button>'+
				'</div>'+
			'</div>'+
        '</div>';
    var quickFileTileNoHTML = '<div></div>'
    return {
        restrict: 'E',
        replace: true,
        scope: {
            'name': '=',
            'personPickerUrl': '@',
            'domPrefix': '@'
        },
        link: function (scope, element, attrs) {
            scope.quickFillSuggestions = $window[scope.domPrefix+'sfsData'].personPicker.quickFillSuggestions;
            var formDetails = sfsQuickTilesUtils.getVersionSettings();
            var formType = sfsQuickTilesUtils.getFormType();
            var cultureId = sfsQuickTilesUtils.getCultureId();            
            scope.showSuggestion = true;
            scope.closeSuggestion = function () {
                scope.showSuggestion = !scope.showSuggestion;
            }
            $rootScope.$on('onCloseQuickTileSuggestion', function () {
                scope.showSuggestion = false;
                scope.isClickFirstNameAutocomplete = true;
                scope.isClickLastNameAutocomplete = true;
            });
            var personPickerUrl = scope.personPickerUrl;
            scope.personTrees = {
                SelectedTree: null,
                AllTrees: []
            };
            scope.personList = [];
            scope.selectedTreeId = null;
            scope.isShowNext = false;
            scope.isShowPrev = false;
            scope.isTreeAvailable = true;
            scope.allTilesLength = 0;
            scope.wrapperLength = 0;
            scope.currentSection = 0;
            scope.firstName = "";
            scope.lastName = "";
            scope.allowPersonPick = false;
              /* istanbul ignore next */
            scope.isRedwood =  sfsCookies.get("UI-2-Style")==='rwl' ? true: false;
            scope.sfsSelectedPersonData = function () {
                var sfsData = sfsDataModelProvider.getDataModel(scope.domPrefix);
                if (sfsData) {
                    return sfsData.treePerson.personId
                }
                return null;
            }
            var onFirstOrLastNameChange = function (newValue, isGivenName) {
                var nextName = isGivenName ? 'firstName' : 'lastName'
                scope[nextName] = newValue;

                loadPersonDetails(scope.firstName, scope.lastName);
                scope.isLoading = true;
                $timeout(function () {
                    var allTilesLength = 0;
                    jQuery('.quick-fill-tiles-list-wrap').each(function (index, element) {
                        allTilesLength = allTilesLength + $(element).outerWidth();
                    });
                    scope.allTilesLength = allTilesLength;
                    scope.wrapperLength = $('.quick-fill-tiles-wrapper').width();
                    $('.quick-fill-tiles-container').width(allTilesLength);
                    if (scope.allTilesLength > scope.wrapperLength) {
                        scope.isShowNext = true;
                    }
                    else {
                        scope.isShowNext = false;
                    }
                    scope.isLoading = false;
                    scope.isShowPrev = false;
                }, 1200);
            }
            var quickTileTree;
            var updateQuickFillTileFirstName = function (newValue) {
                if(scope.isClickFirstNameAutocomplete) {
                    scope.isClickFirstNameAutocomplete = false;
                }
                else {
                    scope.showSuggestion = true; 
                }
                onFirstOrLastNameChange(newValue, true)
            }
            var updateQuickFillTileLastName = function (newValue) {
                if(scope.isClickLastNameAutocomplete) {
                    scope.isClickLastNameAutocomplete = false;
                }
                else {
                    scope.showSuggestion = true; 
                }
                onFirstOrLastNameChange(newValue, false)
            }
            scope.selectSuggestion = function (personDetails) {
                $.getJSON(personPickerUrl + "person/" + personDetails.TreeId + "/" + personDetails.PersonId + "/?callback=?", null, function (personPickerResponse, textStatus) {
                    if (textStatus !== 'success') return;
                    // Pre populate form based on data that came back for selected person
                    // Update data model with 'searchType' business event as 'TreePerson_QuickFill'
                    sfsPersonPickerService.updateModelFromPersonPickerResponse(scope.domPrefix, personPickerResponse, 'quickfill-tiles');
                    $(".quick-fill-tiles-container").animate({ left: '0px' }, 0);
                });
            };
            $( $window ).resize(function() {
                var allTilesLength = 0;
                jQuery('.quick-fill-tiles-list-wrap').each(function (index, element) {
                    allTilesLength = allTilesLength + $(element).outerWidth();
                });
                scope.allTilesLength = allTilesLength;
                scope.wrapperLength = $('.quick-fill-tiles-wrapper').width();
                $('.quick-fill-tiles-container').width(allTilesLength);
              });
            scope.onChangeNextTiles = function () {
                scope.currentSection = scope.currentSection + 1;
                var scrollSection = (scope.currentSection * scope.wrapperLength);
                $(".quick-fill-tiles-container").animate({ left: -(scrollSection)+'px' }, 800);
                if (scrollSection+scope.wrapperLength > scope.allTilesLength) {
                    scope.isShowNext = false;
                }
                if (scrollSection > scope.wrapperLength || scrollSection == scope.wrapperLength) {
                    scope.isShowPrev = true;
                }
            }

            scope.onChangePrevTiles = function () {
                scope.currentSection = scope.currentSection - 1;
                var scrollSection = (scope.currentSection * scope.wrapperLength);
                $(".quick-fill-tiles-container").animate({ left: -(scrollSection)+'px' }, 800);
                if (scope.allTilesLength > scrollSection) {
                    scope.isShowNext = true;
                }
                if (scope.wrapperLength > scrollSection) {
                    scope.isShowPrev = false;
                }
            }

            scope.changeTreeId = function (treeId, isNotEmit) {
                if (scope.personTrees.SelectedTree == treeId) return;
                var treeDetails = scope.personTrees.AllTrees.find(function (tree) { return tree.TreeId == treeId });
                scope.personTrees.SelectedTree = treeDetails;
                scope.selectedTreeId = treeDetails.TreeId;
                loadPersonDetails(scope.firstName, scope.lastName);
                if (quickTileTree) {
                    quickTileTree.close();
                }
                if (!isNotEmit) {
                    $rootScope.$emit('onSelectedTreeChange', scope.selectedTreeId);
                }
                $.getJSON(personPickerUrl + "setselectedtree/" + scope.selectedTreeId + "/?callback=?", null, function (data, textStatus) { });
                setTreeCookie(scope.selectedTreeId);
            }

            function setTreeCookie(treeId) {
                var cookieTreeId = sfsCookies.get('personPickerSelectedTreeId');
                if (treeId !== cookieTreeId) {
                    sfsCookies.set('personPickerSelectedTreeId', treeId, 365);
                }
            }
            function loadTreeData() {
                $.getJSON(personPickerUrl + "trees/?callback=?", null, function (treeData) {

                    if (treeData == null || treeData.AllTrees == null || treeData.AllTrees.length === 0) {
                        scope.isTreeAvailable = false;
                        $(".SUI_SFS_Quick_Fill_Container").removeClass("SUI_SFS_Quick_Fill_Container");
                        return;
                    }

                    scope.personTrees = treeData;
                    // Ensure treeData.SelectedTree is populated.  The response may come back with multiple trees but not a selected tree.
                    // if this is the case then first try to choose a tree using the personPickerSelectedTreeId cookie value, otherwise
                    // just select the first tree from the collection.
                    if (!treeData.SelectedTree) {
                        var cookieTreeId = sfsCookies.get('personPickerSelectedTreeId');
                        if (typeof cookieTreeId === 'string') {
                            var selectedTrees = treeData.AllTrees.filter(function (tree) {
                                return tree.TreeId === cookieTreeId;
                            });
                            treeData.SelectedTree = selectedTrees.length > 0 ? selectedTrees[0] : null;
                        }
                    }
                    treeData.SelectedTree = treeData.SelectedTree || (treeData.AllTrees.length > 0 ? treeData.AllTrees[0] : null);
                    if (!treeData.SelectedTree) {
                        return;
                    }
                    scope.selectedTreeId = treeData.SelectedTree.TreeId;

                    // Save the selected tree id to a cookie (unless the cookie already had that value)
                    setTreeCookie(treeData.SelectedTree.TreeId);
                    scope.$apply();

                    if (typeof ui === 'object' && ui.callout) {
                        quickTileTree = ui.callout('#SUI_SFS_List_Tree', {
                            content: '#SUI_SFS_All_Trees'
                        });
                    } else {
                        $('#SUI_SFS_List_Tree').callout({
                            content: $('#SUI_SFS_All_Trees')
                        });
                    }
                });
            }
            loadTreeData();
            scope.isLoading = false;
            function loadPersonDetails(firstName, lastName) {
                if (!scope.selectedTreeId) return;
                firstName = firstName || "";
                lastName = lastName || "";
                var hasMinLengthInGivenNameOrSurname = (firstName && firstName.length >= 3) || (lastName && lastName.length >= 3);
                scope.allowPersonPick = hasMinLengthInGivenNameOrSurname;
                if (!scope.allowPersonPick) {
                    return;
                }
                var personUrl = scope.personPickerUrl + 'suggest/' + scope.selectedTreeId;
                scope.isLoading = true;
                $.getJSON(personUrl + '?partialFirstName=' + firstName + '&partialLastName=' + lastName + '&callback=?', null, function (personDetails) {
                    var selectedPerson = scope.sfsSelectedPersonData();
                    personDetails = personDetails.slice(0,8);
                    if (selectedPerson) {
                        for (var personIndex = 0; personIndex < personDetails.length; personIndex++) {
                            if (personDetails[personIndex].PersonId == selectedPerson) {
                                personDetails.unshift(personDetails[personIndex]);
                                personDetails.splice(personIndex + 1, 1)
                                break;
                            }
                        }
                    }
                    scope.currentSection = 0;
                    scope.personList = personDetails;
                    scope.isLoading = false;
                    scope.$apply();
                    var allTilesLength = 0;
                    jQuery('.quick-fill-tiles').each(function (index, element) {
                        allTilesLength = allTilesLength + $(element).width();
                    });
                    scope.allTilesLength = allTilesLength;
                    scope.wrapperLength = $('.quick-fill-tiles-wrapper').width();
                    if (scope.allTilesLength > scope.wrapperLength) {
                        scope.isShowNext = true;
                    }
                    else {
                        scope.isShowNext = false;
                    }
                    scope.isShowPrev = false;
                    $(".quick-fill-tiles-container").animate({ left: '0px' }, 0);
                });

            }
            if (cultureId == "en-US" && formType == "Global" && formDetails.isQuickTilesVisible) {
                scope.$watch('name.givenName', updateQuickFillTileFirstName, true);
                scope.$watch('name.surname', updateQuickFillTileLastName, true);
                $rootScope.$on('treechanged', function (e, selectedTreeId) {
                    scope.changeTreeId(selectedTreeId, true)
                });
                element.replaceWith($compile(quickFillTileHTML)(scope));
            } else {
                $(".SUI_SFS_Quick_Fill_Container").removeClass("SUI_SFS_Quick_Fill_Container");
                element.replaceWith($compile(quickFileTileNoHTML)(scope));
            }
        }
    }
}]);angular.module('SearchFormServiceUI').directive('sfsRequired', ['$timeout', function ($timeout) {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            if (attrs.sfsRequired.toLowerCase() !== 'true') {
                return;
            }

            // Wait for next cycle to execute so that elements exist (needed when elements are created via ngRepeat)
            $timeout(function () {
                element.attr('required', 'true');

                var elementLabel = angular.element('label[for=' + attrs.id + ']');
                elementLabel.addClass('required');
                elementLabel.css('float', 'left');

                element.bind('blur', function () {
                    refreshElementStyle();
                });

                element.filter('input[type=text], textarea').on('input', function () {
                    if (!hasRequirementErrors()) {
                        setSuccessStyle();
                    } else if (hasSuccessStyle()) {
                        setErrorStyle();
                    }
                });

                element.closest('form').bind('submit', function () {
                    if (element.val().length < 1) {
                        setErrorStyle();
                    }
                });

                if (element.prop("tagName").toUpperCase() === 'SELECT') {
                    element.bind('change', function () {
                        refreshElementStyle();
                    });
                }

                scope.$on('personPickerUpdated', function(event, domPrefix) {
                    if (domPrefix === scope.domPrefix) {
                        refreshElementStyle();
                    }
                });

                scope.$on('clearFormEvent', function (event, domPrefix) {
                    if (domPrefix === scope.domPrefix) {
                        setInitialStyle();
                    }
                });

                function refreshElementStyle() {
                    if (hasRequirementErrors()) {
                        setErrorStyle();
                    } else {
                        setSuccessStyle();
                    }
                };

                function hasRequirementErrors() {
                    return !element.val()
                        || (element.attr('data-minimum-length') != null && element.val().length < element.attr('data-minimum-length'));
                };

                function hasSuccessStyle() {
                    return elementLabel.hasClass('success');
                }

                function setInitialStyle() {
                    element.removeClass('error');
                    elementLabel.removeClass('error').removeClass('success').siblings('[class="errorMessage icon iconWarning"]').remove();
                };

                function setSuccessStyle() {
                    element.removeClass('error');
                    elementLabel.removeClass('error').addClass('success').siblings('[class="errorMessage icon iconWarning"]').remove();
                };

                function setErrorStyle() {
                    element.addClass('error');
                    elementLabel.removeClass('success').addClass('error');

                    if (elementLabel.siblings('[class="errorMessage icon iconWarning"]').length < 1) {
                        elementLabel.after('<span class="errorMessage icon iconWarning"></span>');
                    }
                };

                if (!hasRequirementErrors()) {
                    setSuccessStyle();
                }
            }, 0);
        }
    };
}]);
// This directive is designed to be used specifically in LifeEventSelectorModule and FamilyMemberSelectorModule
angular.module('SearchFormServiceUI').directive('sfsRestoreFocus', ['$timeout', '$parse', function ($timeout, $parse) {
    function linkFn(scope, element, attrs) {
        scope.focusToNextRow = function (currentRowIndex) {
            var rows = attrs.$$element.find('.sfsPanel').children('fieldset');

            // When no rows are remaining, give focus to the element indicated by the sfsRestoreFocus attribute
            if (rows.length - 1 <= 0) {
                $timeout(function () {
                    angular.element($parse(attrs.sfsRestoreFocus)(scope)).focus();
                });
                return;
            }

            // Define which row should get focus.  The current row is being removed.  If the current row
            // is the last row, the give focus to the row immediately above.  Otherwise there are rows
            // below so focus the next row below.
            var rowToFocus = rows.length - 1 > currentRowIndex
                ? rows[currentRowIndex + 1]
                : rows[currentRowIndex - 1];
            // Find the element within the row to focus
            var elementsToFocus = $(rowToFocus).find('input').filter(':visible');
            if (elementsToFocus != null && elementsToFocus.length > 0) {
                elementsToFocus.first().focus();
            }
        };
    }

    return {
        restrict: 'A',
        link: linkFn
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsSafeSubmit', ['$window', 'submitQueryService',
function ($window, submitQueryService) {

    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var formDataElement = $('#' + scope.metadata.form.dataFieldId);

            element.bind('submit', function (event) {
                event.preventDefault();

                // if the form contains elements that did not pass validation cancel form submission
                if (element.closest('form').find('.error').length > 0) {
                    return;
                }

                // if the form does not satisfy required fields requirments then cancel form submission
                var satisifiesRequiredFields = true;
                element.closest('form').find('input[required], select[required]').each(function (index, el) {
                    var $el = $(el);
                    var strValue = $el.val();
                    var minimumLength = Math.max(1, $el.attr('data-minimum-length') || 1);
                    if (strValue.length < minimumLength) {
                        satisifiesRequiredFields = false;
                    }
                    return satisifiesRequiredFields;
                });
                if (!satisifiesRequiredFields) {
                    return;
                }

                // disable all submit buttons and set their text to a defined value (e.g. "Searching...") to prevent submitting twice
                element.find(':submit').val(attr.sfsSafeSubmit).addClass('disabled').prop('disabled', true);

                submitQueryService.submitSearchQuery(scope.model, scope.metadata);
            });

            $($window).on('pageshow', function (e) {
                var loadedFromCache =
                    e.originalEvent.persisted === true ||
                    ($window.performance && $window.performance.navigation.type === 2);
                if (loadedFromCache) {
                    // reset style of "submit" button to initial state
                    element.find(':submit').each(function () {
                        var item = $(this);
                        item.val(item.attr('data-initial-text'));
                        item.removeClass('disabled');
                        item.prop('disabled', false);
                    });
                }
            });

            scope.$on('clearFormEvent', function (event, domPrefix) {
                if (domPrefix === scope.domPrefix) {
                    formDataElement.val('');
                }
            });
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsShowHelpFor', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            element.bind('click', function () {
                window.open(attr.searchDomain + "/Search/Help/SearchForm.aspx?topic=" + attr.sfsShowHelpFor,
                    "_blank", "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, "
                    + "resizable=no, copyhistory=yes, width=640, height=480");
            });
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsUserIdHash', [function () {
    return {
        restrict: "A",
        scope: false,
        link: function (scope, element) {
            try {
                element.val(Ancestry.SFS.Settings.UserIdHash);
            } catch (e) {
                element.val('');
            }
        }
    };
}]);
angular.module('SearchFormServiceUI').directive('sfsYearExactness', ['$window', function ($window) {
    var directiveCount = 0;
    function linkFn($scope, element, attrs) {
        ++directiveCount;
        $scope.directiveId = "sfsYearExactnes_" + directiveCount;
        $scope.calloutId = "callout_" + $scope.proximityName + "_" + $scope.directiveId;
        $scope.exactnessCheckboxId = "checkbox_" + $scope.exactnessName + "_" + $scope.directiveId;
        var sfsData = $window[$scope.domPrefix + 'sfsData'];
        $scope.exactText = sfsData.exactYearText;
        $scope.exactToText = sfsData.exactToText;
        $scope.buttonDisplayText = sfsData.eventYearExactButtonDisplay;
        $scope.exactnessDisplayText = sfsData.eventYearExactnessDisplay;
        $scope.exactnesses = [0, 1, 2, 5, 10];

        $scope.setIsExact = function (isYearExact) {
            $scope.isYearExact = isYearExact;
            if (!isYearExact) {
                $scope.event.dateProximity.day = null;
                $scope.event.dateProximity.month = null;
                $scope.event.dateProximity.year = null;
            } else {
                $scope.event.dateProximity.day = $scope.event.dateProximity.day || 0;
                $scope.event.dateProximity.month = $scope.event.dateProximity.month || 0;
                $scope.event.dateProximity.year = $scope.event.dateProximity.year || 0;
            }
        }

        $scope.$watch('event.dateProximity.year', function () {
            $scope.setIsExact($scope.event.dateProximity.year != null);
        });

        $scope.getItemDomId = function (exactness) {
            return "input_" + $scope.proximityName + "_" + exactness + "_" + $scope.directiveId;
        };
    };

    var templateStr =
        '<div class="exact-filter">' +
            '<button type="button" class="link icon locationButton"' +
                    'data-ng-click="setIsExact(true)" ' +
                    'data-ng-class="{ iconCheck: isYearExact, inputBox : !isYearExact }" ' +
                    'data-sfs-callout="{{calloutId}}" ' +
                    'data-sfs-callout-focus="{{exactnessCheckboxId}}" ' +
                    'data-autoname="YearExactButton">' +
                '<span>{{ isYearExact ? buttonDisplayText[event.dateProximity.year] : exactText + "..." }}</span>' +
            '</button>' +
            '<input type="hidden" value="1" data-ng-if="event.date.year != null && isYearExact" name="{{exactnessName}}" />' +
            '<input type="hidden" value="{{event.dateProximity.year}}" name="{{proximityName}}" data-ng-if="event.date.year != null && isYearExact" />' +
            '<div id="{{calloutId}}" class="form calloutDomContent">' +
                '<input id="{{exactnessCheckboxId}}" class="checkbox" type="checkbox" data-ng-model="isYearExact" data-ng-change="setIsExact(isYearExact)">' +
                '<label for="{{exactnessCheckboxId}}">{{exactToText}}</label>' +
                '<ul style="list-style-type: none;">' +
                    '<li data-ng-repeat="exactness in exactnesses" data-ng-init="itemDomId = getItemDomId(exactness)">' +
                        '<input id="{{itemDomId}}" class="radio" type="radio" name="{{proximityName}}__{{directiveId}}" data-no-submit ' +
                            'data-ng-value="{{exactness}}" data-ng-model="$parent.event.dateProximity.year" data-ng-change="setIsExact(true)">' +
                        '<label for="{{itemDomId}}" data-autoname="Exact{{exactness}}">{{exactnessDisplayText[exactness]}}</label>' +
                    '</li>' +
                '</ul>' +
            '</div>' +
        '</div>';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            proximityName: '@',
            exactnessName: '@',
            event: '=',
            domPrefix: '@'
        },
        link: linkFn,
        template: templateStr
    };
}]);
angular.module('SearchFormServiceUI').factory('queryParamCollection', ["$rootScope", "$window", function ($rootScope, $window) {
    var service = {};
    var queryParams = null;
    var personPickerParams = null;
    var savedData = {};
    var formWasCleared = {};

    service.getUrlParams = function () {
        if (!queryParams) {
            queryParams = parseValues($window.location.search.substring(1));
        }

        return queryParams;
    };

    service.getPersonPickerParams = function () {
        return personPickerParams;
    };

    service.getQueryStringValue = function () {
        if (window.ancestry && window.ancestry.search) {
            if (window.ancestry.search.newSliderEditForm == 'on') {
                return true;
            } else if (window.ancestry.search.newSliderEditForm == 'off') {
                return false;
            }            
        }
        var key = 'isNewSlider';
        var queryValue = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
        return queryValue ? true : false;
    }

    service.getPersonPickerParam = function (key) {
        var result = personPickerParams[key];
        if (result) {
            return result;
        }
        return null;
    };

    service.updatePersonPickerParams = function (params) {
        personPickerParams = params;
    };

    service.getByKey = function (key, domPrefix) {
        return service.getFormData(domPrefix)[key];
    };

    service.getFormData = function (domPrefix) {
        // if the form was cleared then query parameters should not populate form elements again
        if (formWasCleared[domPrefix]) {
            return {};
        }

        if (savedData[domPrefix]) {
            return savedData[domPrefix];
        }

        var data = service.getUrlParams();
        savedData[domPrefix] = data;
        if ($window.Ancestry && $window.Ancestry.SFS && $window.Ancestry.SFS.InitialValues) {
            $.each($window.Ancestry.SFS.InitialValues, function (propName, propVal) {
                data[propName] = propVal;
            });
        }

        return savedData[domPrefix];
    };

    function parseValues(queryString) {
        var result = {};

        if (!queryString) {
            return result;
        }

        var pairs = queryString.split('&');

        for (var i = 0; i < pairs.length; i++) {
            var pairStr = pairs[i];
            var pairArr = pairStr.split('=');
            if (pairArr.length != 2) continue;
            var key = decodeURIComponent(pairArr[0].replace(/\+/g, ' '));
            var value = decodeURIComponent(pairArr[1].replace(/\+/g, ' '));
            if (key && value) {
                result[key] = value;
            }
        }

        return result;
    };

    $rootScope.$on('clearFormEvent', function (event, domPrefix) {
        formWasCleared[domPrefix] = true;
        savedData[domPrefix] = null;
    });

    return service;
}]);
angular.module('SearchFormServiceUI').factory('sfsCookies', ['$window', function ($window) {
    var service = {};

    service.getAll = function () {
        return $window.document.cookie.split(';');
    };

    service.get = function (name) {
        var nameEq = name + "=";
        var ca = service.getAll();
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEq) == 0) {
                return c.substring(nameEq.length, c.length);
            }
        }
        return null;
    };

    service.set = function (name, value, days) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        $window.document.cookie = name + "=" + value + expires + "; path=/";
    };

    service.remove = function (name) {
        service.set(name, "", -1);
    };

    return service;
}]);
angular.module('SearchFormServiceUI').factory('submitQueryService', ['$window', 'urlCanonicalizerProvider', 'sfsDataModelProvider', 'userPreferences', 'nodeObjectHashProvider', 'sfsCookies',
    function ($window, urlCanonicalizerProvider, sfsDataModelProvider, userPreferences, nodeObjectHashProvider, sfsCookies) {

    var isDebugEnabledRegex = /[?&]debug=(1|true)(&|$)/gi;

    function getQueryStringForQuerySetV1(queryParams, metadata, formElement, nodeObjectHasher, dataModel, sfsCookies) {
        var categories = metadata.form.categoryName ? [metadata.form.categoryName] : [];
        var collections = metadata.form.collectionId ? [metadata.form.collectionId] : [];
        queryParams["qh"] = nodeObjectHasher({
            name: dataModel.name,
            events: dataModel.events,
            fields: dataModel.fields,
            family: dataModel.family,
            types: dataModel.types,
            location: dataModel.location,
            priority: dataModel.priority,
            range: dataModel.range,
            treePerson: dataModel.treePerson,
            categories: categories,
            collections: collections,
            limitRecordsToCountry: dataModel.limitRecordsToCountry,
            sessionId: sfsCookies.get('ANCSESSIONID') || ''
        });
        var formData = formElement.serialize();
        // creates an associative array with all the forms fields
        var pairs = formData.split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            var key = decodeURIComponent(pair[0].replace(/\+/g, ' '));
            var value = decodeURIComponent(pair[1].replace(/\+/g, ' '));
            if (key && value) {
                queryParams[key] = value;
            }
        }

        // parameters with value "0" which must be submitted
        var paramsWithZero = {};

        // removal of all parameters with default values from array
        formElement.find("input[data-submit-default], select[data-submit-default]").each(function () {
            if (!!this.value && this.value.toString() === this.attributes['data-submit-default'].value) {
                delete queryParams[this.name];
            } else if (this.value === '0') {
                paramsWithZero[this.name] = this.value;
            }
        });

        // removal of all parameters not intended for submission
        formElement.find("input[data-no-submit], select[data-no-submit]").each(function () {
            delete queryParams[this.name];
        });

        if (metadata.form.submitPInfo === false) {
            // remove pinfo parameters
            formElement.find("input[name$=_PInfo]").each(function () {
                delete queryParams[this.name];
            });
        }

        // creating of a string that will contain the final list of parameters which will be passed
        var queryString = '';
        for (var paramKey in queryParams) {
            // not to include parameters with value "0" except parameters which must be
            // submitted (fields which have "data-submit-default" attribute)
            if (queryParams[paramKey] === '0' && paramsWithZero[paramKey] === undefined) {
                continue;
            }

            if (queryString) {
                queryString += '&';
            }
            queryString += encodeURIComponent(paramKey) + '=' + encodeURIComponent(queryParams[paramKey]);
        }
        return getUrlWithPassthroughData(queryString, metadata.form.passthroughData);
    }

    function getQueryStringForQuerySetV2(queryParams, searchModel, metadata, urlCanonicalizer, dataModelProvider) {
        var searchModelCopy = $.extend(true, {}, searchModel);
        searchModelCopy.page.number = 1;
        searchModelCopy.page.cursor = '';

        // reset values for fields in data model which don't have visual representation in the search form
        dataModelProvider.limitDataModelToFieldsOnSearchForm(metadata.form.domPrefix, searchModelCopy, metadata.form.fieldNames);

        var queryString = urlCanonicalizer.queryParamNormalizer.toQueryParams(searchModelCopy, 'searchResults', null).queryString || "";
        if (queryParams["debug"] && queryParams["debug"] === "1") {
            queryString += "&debug=1";
        }

        return getUrlWithPassthroughData(queryString, metadata.form.passthroughData);
    }

    function getUrlWithPassthroughData(url, passthroughDataMap) {
        var queryGroups = url.split('&');
        var sb = [];
        var passthroughKeys = Object.keys(passthroughDataMap);
        for (var i = 0; i < queryGroups.length; i++) {
            var queryPair = queryGroups[i].split('=');
            if(queryPair.length !== 2) continue;
            var key = queryPair[0];
            if (!passthroughDataMap[key]) {
                sb.push(queryGroups[i]);
            }
        }
        for (var j = 0; j < passthroughKeys.length; j++) {
            var key = passthroughKeys[j];
            sb.push(encodeURI(key) + "=" + encodeURI(passthroughDataMap[key]));
        }
        return sb.join('&');
    }

    return {
        submitSearchQuery: function(dataModel, metadata) {
            // if the form contains elements that did not pass validation, then cancel form submission
            var formElement = $('#' + metadata.form.domPrefix + "SearchForm > form");
            if (formElement.find('.error').length > 0) {
                return;
            }

            var queryParams = {};
            var isV1QuerySet = metadata.querySetVersion === "V1" || metadata.querySetVersion !== "V2";
            if (isV1QuerySet) {
                var hasher = nodeObjectHashProvider.getHasher({
                    coerce: false,
                    sort: false,
                    alg: 'md5',
                    enc: 'base64'
                });
                var queryString = getQueryStringForQuerySetV1(queryParams, metadata, formElement, hasher, dataModel, sfsCookies);
                var searchResultsUrl = metadata.form.url + '?' + queryString;
                $window.location.href = searchResultsUrl;
                return;
            }

            userPreferences.saveUserPreferences(metadata.form.domPrefix, dataModel, function () {
                queryParams.debug = isDebugEnabledRegex.test($window.location.search) ? '1' : '0';
                var canonicalizer = urlCanonicalizerProvider.getUrlCanonicalizer();
                var queryString = getQueryStringForQuerySetV2(queryParams, dataModel, metadata, canonicalizer, sfsDataModelProvider);
                var searchResultsUrl = metadata.form.url + '?' + queryString;
                $window.location.href = searchResultsUrl;
            });
        }
    }
}]);
angular.module('SearchFormServiceUI').factory('userPreferences', ['$rootScope', '$window', 'queryParamCollection', 'sfsDataModelProvider', function ($rootScope, $window, queryParamCollection, sfsDataModelProvider) {
    var service = {};
    var userPrefs = null;
    var formWasCleared = {};

    function getUserPreferences(domPrefix, metadata) {
        // If the form was cleared then user preferences should not populate form elements again
        if (formWasCleared[domPrefix]) return {};
        // If already loaded, return data immediately
        if (userPrefs) return userPrefs;
        var isInstitutional = metadata.user.isInstitutional;
        var isUserPrefsAvailable = window.Ancestry != null && Ancestry.SFS != null && Ancestry.SFS.Settings != null && Ancestry.SFS.Settings.UserPreferences != null;
        var splitExpOn = window.splitExperiments != null ? window.splitExperiments["international-optimization"] : "off";
        var cultureId = window.sfs_sfsData.cultureId;
        userPrefs = !isInstitutional && isUserPrefsAvailable
            ? angular.copy(Ancestry.SFS.Settings.UserPreferences) || {}
            : createUserPrefObject();
        userPrefs.CategoryBucket = userPrefs.CategoryBucket || 'rstp';
        if (splitExpOn === "on") {            
            if(cultureId === "en-US"){
                userPrefs.DefaultCollectionFocus = '0';
            }else{
                userPrefs.DefaultCollectionFocus = '1';
            }
        } else {
            userPrefs.DefaultCollectionFocus = isUserPrefsAvailable ? Ancestry.SFS.Settings.DefaultCollectionFocus : '0';
        }
        userPrefs.MatchAllTermsExactly = userPrefs.MatchAllTermsExactly === true;
        userPrefs.ShowMoreOptions = userPrefs.ShowMoreOptions === true;
        userPrefs.FirstNameExactness = userPrefs.FirstNameExactness || { Exact: false, Phonetic: false, Similar: false, Initials: false };
        userPrefs.LastNameExactness = userPrefs.LastNameExactness || { Exact: false, Phonetic: false, Similar: false, Soundex: false };
        return userPrefs;
    };

    function createUserPrefObject() {
        return {
            CategoryBucket: null,
            CollectionFocus: "0",
            FirstNameExactness: { Exact: false, Phonetic: false, Similar: false, Initials: false },
            LastNameExactness: { Exact: false, Phonetic: false, Similar: false, Soundex: false },
            MatchAllTermsExactly: false,
            ShowMoreOptions: false
        };
    }

    function loadNameExactnessDefaults(nameExactness, exactDefaults, isExactAllEnabled) {
        nameExactness.isExact = isExactAllEnabled || exactDefaults.Exact;
        if (nameExactness.isExact) {
            $.each(exactDefaults, function (key, value) {
                switch (key) {
                    case 'Phonetic':
                        nameExactness.flags.phonetic = value;
                        break;
                    case 'Similar':
                        nameExactness.flags.similar = value;
                        break;
                    case 'Initials':
                        nameExactness.flags.initials = value;
                        break;
                    case 'Soundex':
                        nameExactness.flags.soundex = value;
                        break;
                }
            });
        }
    };

    function allFieldsWithValuesAreExact(dataModel) {
        var hasGivenName = dataModel.name.givenName != null && dataModel.name.givenName.length > 0;
        if (hasGivenName && !dataModel.name.givenNameExactness.isExact) {
            return false;
        }

        var hasSurname = dataModel.name.surname != null && dataModel.name.surname.length > 0;
        if (hasSurname && !dataModel.name.surnameExactness.isExact) {
            return false;
        }

        var eventGroupNames = Object.keys(dataModel.events);
        for (var i = 0; i < eventGroupNames.length; ++i) {
            var eventGroup = dataModel.events[eventGroupNames[i]];
            for (var j = 0; j < eventGroup.instances.length; ++j) {
                var event = eventGroup.instances[j];
                if ((event.date.year != null && event.dateProximity.year == null) ||
                    (event.date.month != null && event.dateProximity.month == null) ||
                    (event.date.day != null && event.dateProximity.day == null)) {
                    return false;
                }
                if (event.location != null && event.location.length > 0 && !event.locationExactness.isExact) {
                    return false;
                }
            }
        }

        var familyRelationships = Object.keys(dataModel.family);
        for (var i = 0; i < familyRelationships.length; ++i) {
            var familyGroup = dataModel.family[familyRelationships[i]];
            for (var j = 0; j < familyGroup.instances.length; ++j) {
                var member = familyGroup.instances[j];
                if (member.givenName != null && member.givenName.length > 0 && !member.givenNameExactness.isExact) {
                    return false;
                }
                if (member.surname != null && member.surname.length > 0 && !member.surnameExactness.isExact) {
                    return false;
                }
            }
        }

        var fieldNames = Object.keys(dataModel.fields);
        for (var i = 0; i < fieldNames.length; ++i) {
            var fieldObj = dataModel.fields[fieldNames[i]];
            if (fieldObj.value != null && fieldObj.value.length > 0 && !fieldObj.isExact) {
                return false;
            }
        }
        return true;
    };

    function fieldInAdvancedAreaHasNonDefaultValue(domPrefix, dataModel, metadata) {
        if (dataModel.isExactAllEnabled) {
            return true;
        }

        var defaultCollectionPriorityItem = sfsDataModelProvider.getCollectionFocusItem(domPrefix, metadata.defaultSearchBlock);
        if (dataModel.priority !== defaultCollectionPriorityItem.weightGroupName) {
            return true;
        }

        if (dataModel.types.records !== true ||
            dataModel.types.stories !== true ||
            dataModel.types.trees !== true ||
            dataModel.types.photos !== true) {
            return true;
        }

        var eventGroupNames = Object.keys(dataModel.events);
        for (var i = 0; i < eventGroupNames.length; ++i) {
            var eventGroupName = eventGroupNames[i];
            var eventGroup = dataModel.events[eventGroupName];
            if (eventGroup.instances.length > 0 && eventGroupName !== "SelfBirth" && eventGroupName !== "Self") {
                return true;
            }
            if (eventGroupName === "Self" && eventGroup.instances.length > 1) {
                return true;
            }
            if (eventGroup.instances.length === 1) {  // should be either no instances or exactly one instance
                var event = eventGroup.instances[0];
                if (event.date.month != null || event.date.day != null) {
                    return true;
                }
                if (eventGroupName !== "SelfBirth" && event.date.year != null) {
                    return true;
                }
                if (eventGroupName !== "Self" && event.location != null && event.location.length > 0) {
                    return true;
                }
            }
        }

        var familyRelationships = Object.keys(dataModel.family);
        for (var i = 0; i < familyRelationships.length; ++i) {
            var familyGroup = dataModel.family[familyRelationships[i]];
            if (familyGroup.instances.length > 0) {
                return true;
            }
        }

        var fieldNames = Object.keys(dataModel.fields);
        for (var i = 0; i < fieldNames.length; ++i) {
            var fieldObj = dataModel.fields[fieldNames[i]];
            if (fieldObj.value != null && fieldObj.value.length > 0) {
                return true;
            }
        }
        return false;
    };

    function shouldShowMoreOptions(userPrefs, domPrefix, dataModel, metadata, params) {
        // Load "Show more options" user preference
        var showMoreOptions = (userPrefs != null && userPrefs.ShowMoreOptions === true) || fieldInAdvancedAreaHasNonDefaultValue(domPrefix, dataModel, metadata);
        if (metadata.querySetVersion === 'V1' && params.MSAV) {
            showMoreOptions = params.MSAV === '1' || params.MSAV === '2' || fieldInAdvancedAreaHasNonDefaultValue(domPrefix, dataModel, metadata);
        }
        return showMoreOptions;
    }

    $rootScope.$on('clearFormEvent', function (event, domPrefix) {
        formWasCleared[domPrefix] = true;
        userPrefs = null;
    });

    service.updateMetadataDefaults = function (domPrefix) {
        var metadata = sfsDataModelProvider.getMetadata(domPrefix);
        var userPrefs = getUserPreferences(domPrefix, metadata);
        // Determine default collection priority (not same thing as user preference and can be based on user's IP and not just the current partner)
        metadata.defaultSearchBlock = userPrefs != null && typeof (userPrefs.DefaultCollectionFocus) !== 'undefined'
            ? userPrefs.DefaultCollectionFocus
            : metadata.defaultSearchBlock || '0';
    };

    service.applyUserPreferences = function (domPrefix) {
        var dataModel = sfsDataModelProvider.getDataModel(domPrefix);
        var metadata = sfsDataModelProvider.getMetadata(domPrefix);
        if (!domPrefix || !dataModel || !metadata) return;

        var params = queryParamCollection.getUrlParams();
        var userPrefs = getUserPreferences(domPrefix, metadata);

        // Note:  Don't load the match all terms exactly and show more options preferences when the user is using the back button and the form
        //        is being loaded from saved state.

        // Load "Match all terms exactly" user preference
        var isExactAllEnabled = userPrefs != null && userPrefs.MatchAllTermsExactly === true;
        var isRefiningSearch = metadata.form.isRefiningSearch;

        if (metadata.querySetVersion === "V1" && params.MSAV) {
            isExactAllEnabled = params.MSAV === '-1' || params.MSAV === '2';
        }

        if (isExactAllEnabled) {
            if (isRefiningSearch) {
                dataModel.isExactAllEnabled = allFieldsWithValuesAreExact(dataModel);
                if (dataModel.isExactAllEnabled) {
                    sfsDataModelProvider.matchAllTermsExactly(domPrefix, true);
                }
            } else {
                dataModel.isExactAllEnabled = true;
                sfsDataModelProvider.matchAllTermsExactly(domPrefix, true);
            }
        }

        dataModel.isShowMoreOptionsExpanded = shouldShowMoreOptions(userPrefs, domPrefix, dataModel, metadata, params);
        
        if (userPrefs != null) {
            // Load given name exactness defaults if the user is not refining an existing search (like on homepage or landing page)
            // Also load given name exactness when the model does not have a value for the given name 
            if (!isRefiningSearch || (dataModel.name.givenName == null || dataModel.name.givenName.length === 0)) {
                loadNameExactnessDefaults(dataModel.name.givenNameExactness, userPrefs.FirstNameExactness, dataModel.isExactAllEnabled);
            }

            // Load surname exactness defaults if the user is not refining an existing search (like on homepage or landing page)
            // Also load surname exactness when the model does not have a value for the surname 
            if (!isRefiningSearch || (dataModel.name.surname == null || dataModel.name.surname.length === 0)) {
                loadNameExactnessDefaults(dataModel.name.surnameExactness, userPrefs.LastNameExactness, dataModel.isExactAllEnabled);
            }
        }

        var isCollectionForm = metadata.form.type === 'ContentBased' && metadata.form.collectionId;

        // At this point all user preferences are done loading unless the user is not refining an existing search (like on home page or landing page)
        if (isRefiningSearch && !isCollectionForm) return;

        dataModel.page.size = userPrefs.ResultsPageSize ? userPrefs.ResultsPageSize : dataModel.page.size;

        if (isCollectionForm) return;

        // Load collection priority (only when not refining search or using the collection form)
        var collectionFocus = userPrefs != null && userPrefs.CollectionFocus != null ? userPrefs.CollectionFocus : metadata.defaultSearchBlock;
        sfsDataModelProvider.populateCollectionPriority(domPrefix, collectionFocus);

        // Load category bucket (only when not refining search or using the collection form)
        if (userPrefs && userPrefs.CategoryBucket) {
            sfsDataModelProvider.populateCategoryBucketFromString(domPrefix, userPrefs.CategoryBucket);
        }

        // Since loading category bucket and collection focus are in the advanced area that is conditionally expanded and may now have non
        // default values, we must re-evaluate if the advanced area should be shown.
        dataModel.isShowMoreOptionsExpanded = shouldShowMoreOptions(userPrefs, domPrefix, dataModel, metadata, params);        
        dataModel.showUnviewedRecordsOnly = isRefiningSearch ? dataModel.showUnviewedRecordsOnly : userPrefs.ShowUnviewedRecordsOnly;
        dataModel.shouldShowUnviewedFilterPopup = userPrefs.ShouldShowUnviewedFilterPopup;
        dataModel.unviewedFilterPopupShownCount = userPrefs.UnviewedFilterPopupShownCount;
    };

    service.saveUserPreferences = function (domPrefix, scopeDataModel, callback) {
        callback = callback || function () { };
        var dataModel = scopeDataModel || sfsDataModelProvider.getDataModel(domPrefix);
        var metadata = sfsDataModelProvider.getMetadata(domPrefix);
        var searchPreferences = {};

        if (dataModel.name.givenName != null && dataModel.name.givenName.length > 0 && metadata.form.fieldNames.indexOf('SelfGivenName') !== -1) {
            searchPreferences.givenNameExactness = dataModel.name.givenNameExactness;
        }

        if (dataModel.name.surname != null && dataModel.name.surname.length > 0 && metadata.form.fieldNames.indexOf('SelfSurname') !== -1) {
            searchPreferences.surnameExactness = dataModel.name.surnameExactness;
        }

        if (metadata.form.type === 'Global' || metadata.form.type === 'OldSearchSimulator') {
            searchPreferences.types = dataModel.types;
            searchPreferences.showMoreOptions = dataModel.isShowMoreOptionsExpanded;
        }
        
        searchPreferences.matchAll = dataModel.isExactAllEnabled;
        searchPreferences.showUnviewedRecordsOnly = dataModel.showUnviewedRecordsOnly;
        searchPreferences.unviewedFilterPopupShownCount = dataModel.unviewedFilterPopupShownCount;
        searchPreferences.shouldShowUnviewedFilterPopup = dataModel.shouldShowUnviewedFilterPopup;

        if (metadata.collectionFocus.isAvailable) {
            var collectionFocusList = metadata.collectionFocus.items;
            var collectionFocusMatches = $.grep(collectionFocusList, function (elem) {
                return elem.weightGroupName === dataModel.priority;
            });
            var collectionFocusId = collectionFocusMatches.length > 0
                ? collectionFocusMatches[0].id
                : dataModel.priority == null || dataModel.priority === "" ? "" : null;
            if (collectionFocusId != null) {
                searchPreferences.collectionFocus = collectionFocusId;
            }
        }

        if (Object.keys(searchPreferences).length === 0) {
            callback();
        } else {
            $.ajax({
                type: "POST",
                url: metadata.ancestryDomain + "/api/search-preferences",
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                contentType: 'application/json',
                data: JSON.stringify(searchPreferences),
                processData: false,
                dataType: 'json',
                timeout: 1000, 
                success: callback,
                error: callback
            });
        }
    }

    service.getUserPreferences = function (domPrefix) {
        const metadata = sfsDataModelProvider.getMetadata(domPrefix);

        return getUserPreferences(domPrefix, metadata);
    }

    return service;
}]);
angular.module('SearchFormServiceUI').service('sfsQuickTilesUtils', ['$window', function ($window) {
    this.getFormType = function () {
        if (typeof sfs_ScopeMetadata != "undefined" && sfs_ScopeMetadata.form && sfs_ScopeMetadata.form.type) {
            return $window.sfs_ScopeMetadata.form.type;
        } else {
            return;
        }
    }
    this.getCultureId = function () {
        if (typeof sfs_sfsData != "undefined" && sfs_sfsData.cultureId) {
            return $window.sfs_sfsData.cultureId;
        } else {
            return;
        }
    }

    this.getQuickFillVersion = function () {
        if (typeof Ancestry != "undefined" && Ancestry.SFS && Ancestry.SFS.Settings
            && Ancestry.SFS.Settings.QuickfillVersion) {
            try {
                var versionJson = JSON.parse($window.Ancestry.SFS.Settings.QuickfillVersion);
                return versionJson.version;
            } catch (error) {
                return 'normal';
            }
        }
    }

    this.getVersionSettings = function () {
        var currentVersion = this.getQuickFillVersion();
        var formVersion = {
            "v1": { givenName: true, surname: true, quickTiles: false },
            "v2": { givenName: true, surname: true, quickTiles: true },
            "v3": { givenName: false, surname: false, quickTiles: true },
            "normal": { givenName: true, surname: false, quickTiles: false },
        }
        var formDetail = formVersion[currentVersion] || formVersion.normal;
        return {
            isGivenNameVisible: formDetail.givenName,
            isSurnameVisible: formDetail.surname,
            isQuickTilesVisible: formDetail.quickTiles,
        }

    }
}]);
angular.module('SearchFormServiceUI').service('sfsDataModelProvider', ['$rootScope', '$window', '$timeout', 'urlCanonicalizerProvider', 'sfsLocationExactnessProvider', function ($rootScope, $window, $timeout, urlCanonicalizerProvider, sfsLocationExactnessProvider) {

    var eventTemplate = {
        date: { day: null, month: null, year: null },
        dateProximity: { day: null, month: null, year: null },
        gpid: "",
        location: "",
        locationExactness: { isExact: false, isAdjacent: false, level: null }
    };
    var eventWithExactnessTemplate = {
        date: { day: null, month: null, year: null },
        dateProximity: { day: 0, month: 0, year: 0 },
        gpid: "",
        location: "",
        locationExactness: { isExact: true, isAdjacent: false, level: 0 }
    };
    var nameDefault = {
        givenName: '',
        surname: '',
        secondSurname: '',
        givenNameExactness: {
            flags: { phonetic: false, similar: false, initials: false },
            isExact: false
        },
        surnameExactness: {
            flags: { phonetic: false, similar: false, soundex: false },
            isExact: false
        }
    };

    var fieldDefault = { value: '', isExact: false };
    var dataModels = {};  // collection of data models as their may be multiple forms
    $window.sfsDataModels = dataModels;

    function getMetadata(domPrefix) {
        return $window[domPrefix + 'ScopeMetadata'];
    }
    function unchangedDataModel(data) {
        if(data) {
            dataModels['unchangedData'] = data;
        }
        return dataModels['unchangedData'] || {};
        
    }
    function getDataModel(domPrefix) {
        if (dataModels[domPrefix] == null) {
            var dataModelMetadata = getMetadata(domPrefix);
            var dataModel;
            var splitExpOn = window.splitExperiments != null ? window.splitExperiments["international-optimization"] : "off";
            var cultureId = window.sfs_sfsData.cultureId;
            if ($window.ancestry && $window.ancestry.search && $window.ancestry.search.dataModel) {
                dataModel = $window.ancestry.search.dataModel;
                dataModels[domPrefix] = dataModel;
            }
            else {
                if(splitExpOn === 'on' && cultureId !== "en-US"){
                    dataModel = {
                        searchSource: null,
                        types: {
                            records: true,
                            stories: true,
                            trees: true,
                            photos: true
                        },
                        name: $.extend(true, {}, nameDefault),
                        isShowMoreOptionsExpanded: false,
                        isExactAllEnabled: false,
                        events: {},
                        family: {},
                        fields: {
                            SelfGender: $.extend(true, {}, fieldDefault, { relation: 'Self', eventName: '', assertion: 'Gender' }),
                            SelfRace: $.extend(true, {}, fieldDefault, { relation: 'Self', eventName: '', assertion: 'Race' }),
                            keyword: $.extend(true, {}, fieldDefault, { relation: '', eventName: '', assertion: 'keyword' })
                        },
                        page: {
                            number: 1,
                            cursor: null,
                            size: 20
                        },
                        location: [],
                        priority: 'fromquery',
                        range: { yearStart: null, yearEnd: null },
                        treePerson: { treeId: null, personId: null }
                    };
                }
            else {
                dataModel = {
                searchSource: null,
                types: {
                    records: true,
                    stories: true,
                    trees: true,
                    photos: true
                },
                name: $.extend(true, {}, nameDefault),
                isShowMoreOptionsExpanded: false,
                isExactAllEnabled: false,
                events: {},
                family: {},
                fields: {
                    SelfGender: $.extend(true, {}, fieldDefault, { relation: 'Self', eventName: '', assertion: 'Gender' }),
                    SelfRace: $.extend(true, {}, fieldDefault, { relation: 'Self', eventName: '', assertion: 'Race' }),
                    keyword: $.extend(true, {}, fieldDefault, { relation: '', eventName: '', assertion: 'keyword' })
                },
                page: {
                    number: 1,
                    cursor: null,
                    size: 20
                },
                location: [],
                priority: 'default',
                range: { yearStart: null, yearEnd: null },
                treePerson: { treeId: null, personId: null }
            };
            }
            dataModels[domPrefix] = dataModel;
            loadDefaultCategoryBucket(domPrefix);
            }
            $.each(dataModelMetadata.events, function (eventMetadataKey, eventMetadataObj) {
                dataModel.events[eventMetadataKey] = dataModel.events[eventMetadataKey] || { instances: [] };
                dataModel.events[eventMetadataKey].relation = eventMetadataObj.relation;
                dataModel.events[eventMetadataKey].eventName = eventMetadataObj.eventName;
            });
            $.each(dataModelMetadata.customEvents, function (eventMetadataKey, eventMetadataObj) {
                if (dataModel.events[eventMetadataKey] != null) return;
                dataModel.events[eventMetadataKey] = {
                    relation: eventMetadataObj.relation,
                    eventName: eventMetadataObj.eventName,
                    instances: []
                };
            });
            $.each(dataModelMetadata.fields, function (fieldName, field) {
                dataModel.fields[fieldName] = dataModel.fields[fieldName] ||
                    { value: '', isExact: false, relation: field.relation, eventName: field.eventName, assertion: field.assertion };
            });
            $.each(dataModelMetadata.family, function (relationship, familyMemberGroupConfiguration) {
                dataModel.family[relationship] = dataModel.family[relationship] || { instances: [] };
                while (dataModel.family[relationship].instances.length < familyMemberGroupConfiguration.instances) {
                    service.addFamilyMember(domPrefix, relationship);
                }
            });
            $.each(dataModelMetadata.singletonEvents, function (defaultEventIndex, defaultEvent) {
                service.addEvent(domPrefix, defaultEvent);
            });

            ensureIsExactAllEnabledSyncsWithModelChanges(domPrefix, dataModel);
        }

        return dataModels[domPrefix];
    }

    function ensureIsExactAllEnabledSyncsWithModelChanges(domPrefix, dataModel) {
        $rootScope.sfsDataModels = $rootScope.sfsDataModels || {};
        $rootScope.sfsDataModels[domPrefix] = dataModel;
        $rootScope.$watch('sfsDataModels.' + domPrefix, function (current,prev) {
            disableExactAllIfModelHasFieldWithExactnessDisabled(dataModel, domPrefix, current,prev);
        }, true);
    }

    function disableExactAllIfModelHasFieldWithExactnessDisabled(dataModel, domPrefix, current,prev) {
        dataModel.isExactAllEnabled = dataModel.isExactAllEnabled === true
                && dataModel.name.givenNameExactness.isExact === true
                && dataModel.name.surnameExactness.isExact === true;
        if (!dataModel.isExactAllEnabled) {
            if(current.isExactAllEnabled != prev.isExactAllEnabled) matchAllPrevTermsExactly(domPrefix, dataModel.isExactAllEnabled)
            return;
        }

        var eventGroupNames = Object.keys(dataModel.events);
        for (var i = 0; i < eventGroupNames.length; ++i) {
            var eventGroup = dataModel.events[eventGroupNames[i]];
            for (var j = 0; j < eventGroup.instances.length; ++j) {
                var event = eventGroup.instances[j];
                if (event.dateProximity.year == null
                    || event.dateProximity.month == null
                    || event.dateProximity.day == null
                    || event.locationExactness.isExact === false) {
                    dataModel.isExactAllEnabled = false;
                    return;
                }
            }
        }

        var familyRelationships = Object.keys(dataModel.family);
        for (var i = 0; i < familyRelationships.length; ++i) {
            var familyGroup = dataModel.family[familyRelationships[i]];
            for (var j = 0; j < familyGroup.instances.length; ++j) {
                var member = familyGroup.instances[j];
                if (member.givenNameExactness.isExact === false || member.surnameExactness.isExact === false) {
                    dataModel.isExactAllEnabled = false;
                    return;
                }
            }
        }

        var fieldNames = Object.keys(dataModel.fields);
        for (var i = 0; i < fieldNames.length; ++i) {
            var fieldObj = dataModel.fields[fieldNames[i]];
            if (!fieldObj.isExact) {
                dataModel.isExactAllEnabled = false;
                return;
            }
        }
    }

    function resetIfPersonPicker(domPrefix, source) {
        if (source === 'personPicker') {
            service.resetData(domPrefix);
        }
    }

    /** Call this to populate the data model from just the data object passed in.  By default the data model will be reset prior to populating. **/
    function populateFromData(domPrefix, data, source, callback) {
        callback = callback || function () { };
        var dataModel = service.getDataModel(domPrefix);
        if (!dataModel) return callback();
        if (!data) {
            resetIfPersonPicker(domPrefix, source);
            return callback();
        }

        if (getMetadata(domPrefix).querySetVersion === 'V2') {
            populateFromV2QuerySetData(domPrefix, data, source, callback);
        } else {
            populateFromV1QuerySetData(domPrefix, data, source, callback);
        }
    }

    function populateFromV2QuerySetData(domPrefix, data, source, callback) {
        var dataModel = service.getDataModel(domPrefix);
        var dataModelProvidedOnPage = (($window.ancestry || {}).search || {}).dataModel != null;
        if (source === 'onload' && dataModelProvidedOnPage) {
            dataModel.limitRecordsToCountry = data['sbo'] === '0';
            return callback();
        }
        if (source === 'onload' && $window.Ancestry && $window.Ancestry.SFS && $window.Ancestry.SFS.InitialValues) {
            // TODO: Update how initial values works

            // Some pages provide data in window.Ancestry.SFS.InitialValues which acts as a query object.
            // The places pages on search does this as well as a few marketing pages.  The trouble with
            // this is that the query object is in the V1 query set.  :(
            populateFromV1QuerySetData(domPrefix, $window.Ancestry.SFS.InitialValues, '', function() {});
        }
        urlCanonicalizerProvider.getUrlCanonicalizer().queryParamNormalizer.getDataModel(data, 'searchResults', null, function (err, searchDataModel) {
            $timeout(function () {
                resetIfPersonPicker(domPrefix, source);
                // copy items from current model that should stay the same
                searchDataModel.searchSource = dataModel.searchSource;
                searchDataModel.categories = dataModel.categories;
                searchDataModel.collections = dataModel.collections;

                //  metadata.form.isRefiningSearch;
                searchDataModel.showUnviewedRecordsOnly = dataModel.showUnviewedRecordsOnly;

                $.extend(true, searchDataModel.page, dataModel.page);
                // copy changes into data model
                $.extend(true, dataModel, searchDataModel);
                enforceFamilyLimits(domPrefix, dataModel);
                dataModel.limitRecordsToCountry = data['sbo'] === '0';
                if (dataModel.isExactAllEnabled) {
                    matchAllTermsExactly(domPrefix, dataModel.isExactAllEnabled);
                }
                callback();
            }, 1);
        });
    }

    function populateFromV1QuerySetData(domPrefix, data, source, callback) {
        var dataModel = service.getDataModel(domPrefix);
        resetIfPersonPicker(domPrefix, source);
        populateRaceFromData(domPrefix, data);
        populateGenderFromData(domPrefix, data);
        populateKeywordFromData(domPrefix, data);
        populateMsavFromData(domPrefix, data);
        populateNameFromData(domPrefix, data);
        populateEventsFromData(domPrefix, data);
        populateFamilyFromData(domPrefix, data);
        populateFieldsFromData(domPrefix, data);
        populateYearRangeFromData(domPrefix, data);

        dataModel.viewMode = data['MSV'] === '1' ? 'category' : 'record';

        var searchSourceRegex = /pt_t([^_]+)_p(([^_]|$)+)/;
        if (data['ssrc'] && searchSourceRegex.test(data['ssrc'])) {
            var searchSourceMatch = data['ssrc'].match(searchSourceRegex);
            dataModel.treePerson.treeId = searchSourceMatch[1];
            dataModel.treePerson.personId = searchSourceMatch[2];
        }

        if (data['cp']) {
            populateCollectionPriority(domPrefix, data['cp']);
        }

        dataModel.searchType = data['searchType'] || 'form';        

        if (data && data['catBucket']) {
            populateCategoryBucketFromString(domPrefix, data['catBucket']);
        } else if (data && data['catbucket']) {
            populateCategoryBucketFromString(domPrefix, data['catbucket']);
        }
        dataModel.limitRecordsToCountry = data['sbo'] === '0';

        if (dataModel.isExactAllEnabled) {
            matchAllTermsExactly(domPrefix, dataModel.isExactAllEnabled);
        }
        callback();
    }

    function enforceFamilyLimits(domPrefix, model) {
        var metadata = getMetadata(domPrefix);
        var relationships = Object.keys(model.family);
        for (var i = 0; i < relationships.length; ++i) {
            var relationship = relationships[i];
            var familyGroupMetadata = metadata.family[relationship];
            var familyGroup = model.family[relationship];
            familyGroup.instances.splice(familyGroupMetadata.maxCount, Math.max(familyGroup.instances.length - familyGroupMetadata.maxCount, 0));
            while (familyGroup.instances.length < familyGroupMetadata.instances) {
                addFamilyMember(domPrefix, relationship);
            }
        }
    }

    function populateCollectionPriority(domPrefix, searchBlock) {
        var collectionPriorityItem = getCollectionFocusItem(domPrefix, searchBlock);
        var dataModel = service.getDataModel(domPrefix);
        if (collectionPriorityItem) {
            if (dataModel && dataModel.location){
                dataModel.location.length = 0;
            }                    
            $.merge(dataModel.location || [], collectionPriorityItem.locationGpids || []);
            dataModel.priority = collectionPriorityItem.weightGroupName || 'default';
        }
    }

    function getCollectionFocusItem(domPrefix, collectionFocusId) {
        var metadata = getMetadata(domPrefix);
        var collectionFocusList = metadata.collectionFocus.items;
        return $.grep(collectionFocusList, function (elem) {
            return elem.id === collectionFocusId;
        })[0];
    }

    function populateKeywordFromData(domPrefix, data) {
        var dataModel = service.getDataModel(domPrefix);
        dataModel.fields.keyword.value = data['gskw'] || '';
        dataModel.fields.keyword.isExact = data['gskw_x'] === '1';
    }

    function populateRaceFromData(domPrefix, data) {
        var dataModel = service.getDataModel(domPrefix);
        dataModel.fields.SelfRace.value = data['_83004002'] || '';
        dataModel.fields.SelfRace.isExact = data['_83004002_x'] === '1';
    }

    function populateGenderFromData(domPrefix, data) {
        var dataModel = service.getDataModel(domPrefix);
        var genderParam = data['_83004003-n_xcl'];
        if (genderParam === 'm') {
            dataModel.fields.SelfGender.value = "female";
        } else if (genderParam === 'f') {
            dataModel.fields.SelfGender.value = "male";
        } else {
            dataModel.fields.SelfGender.value = null;
        }
        dataModel.fields.SelfGender.isExact = false;
    }

    function loadDefaultCategoryBucket(domPrefix) {
        var metadata = getMetadata(domPrefix);
        var defaultCategoryBucketStr = metadata.form.type.toUpperCase() === "OLDSEARCHSIMULATOR" ? "r" : "rstp";
        populateCategoryBucketFromString(domPrefix, defaultCategoryBucketStr);
    }

    function populateCategoryBucketFromString(domPrefix, categoryBucketStr) {
        var model = getDataModel(domPrefix);
        model.types.records = categoryBucketStr.indexOf("r") !== -1;
        model.types.stories = categoryBucketStr.indexOf("s") !== -1;
        model.types.trees = categoryBucketStr.indexOf("t") !== -1;
        model.types.photos = categoryBucketStr.indexOf("p") !== -1;
        if (!model.types.records &&
            !model.types.stories &&
            !model.types.trees &&
            !model.types.photos) {
            loadDefaultCategoryBucket(domPrefix);
        }
    }

    function populateNameFromData(domPrefix, data) {
        var dataModel = service.getDataModel(domPrefix);
        dataModel.name.givenName = data['gsfn'] || '';

        if (service.getMetadata(domPrefix).name.usesParentSurnameFields && (data['gsfln'] || data['gssln'])) {
            dataModel.name.surname = data['gsfln'] || '';
            dataModel.name.secondSurname = data['gssln'] || '';
        } else {
            dataModel.name.surname = data['gsln'] || '';
        }

        function setExactnessObj(exactnessObj, exactnessString) {
            if (exactnessString === '0' || !exactnessString) {
                exactnessObj.isExact = false;
                $.each(exactnessObj.flags, function (key) {
                    exactnessObj.flags[key] = false;
                });
            } else if (exactnessString === '1') {
                exactnessObj.isExact = true;
                $.each(exactnessObj.flags, function (key) {
                    exactnessObj.flags[key] = false;
                });
            } else {
                $.each(exactnessString.split('_'), function (index, elem) {
                    switch (elem) {
                        case 'NP':
                            exactnessObj.flags.phonetic = true;
                            break;
                        case 'NN':
                            exactnessObj.flags.similar = true;
                            break;
                        case 'NIC':
                            exactnessObj.flags.initials = true;
                            break;
                        case 'NS':
                            exactnessObj.flags.soundex = true;
                            break;
                    }
                });
                exactnessObj.isExact = true;
            }
        }

        setExactnessObj(dataModel.name.givenNameExactness, data['gsfn_x']);
        setExactnessObj(dataModel.name.surnameExactness, data['gsln_x']);
    }

    function populateMsavFromData(domPrefix, data) {
        var dataModel = service.getDataModel(domPrefix);
        if (!dataModel) return;
        if (!data || !data.MSAV) return;
        var msav = parseInt(data.MSAV);
        switch (msav) {
            case 2:
                dataModel.isExactAllEnabled = true;
                dataModel.isShowMoreOptionsExpanded = true;
                break;
            case 1:
                dataModel.isExactAllEnabled = false;
                dataModel.isShowMoreOptionsExpanded = true;
                break;
            case 0:
                dataModel.isExactAllEnabled = false;
                dataModel.isShowMoreOptionsExpanded = false;
                break;
            case -1:
                dataModel.isExactAllEnabled = true;
                dataModel.isShowMoreOptionsExpanded = false;
                break;
        }
    }

    function populateFieldsFromData(domPrefix, data) {
        var dataModel = service.getDataModel(domPrefix);
        if (!dataModel) return;
        var dataModelMetadata = getMetadata(domPrefix);
        var indexForms = ['', '-n', '__n'];
        $.each(dataModel.fields, function (fieldName, field) {
            var fieldMetadata = dataModelMetadata.fields[fieldName];
            if (fieldMetadata == null) return;
            var searchKey = fieldMetadata.searchKey;
            for (var i = 0; i < indexForms.length; ++i) {
                var resolvedValue = data[searchKey + indexForms[i]];
                if (resolvedValue !== undefined) {
                    field.value = resolvedValue;
                }
                var resolvedExactnessValue = data[searchKey + indexForms[i] + '_x'];
                if (resolvedExactnessValue !== undefined) {
                    field.isExact = resolvedExactnessValue === '1';
                }
            }
        });
    }

    function populateYearRangeFromData(domPrefix, data) {
        var dataModel = service.getDataModel(domPrefix);

        var yearStart = data.ossyear;
        if (yearStart != null) {
            dataModel.range.yearStart = parseInt(yearStart) || null;
        }

        var yearEnd = data.ossyearend;
        if (yearEnd != null) {
            dataModel.range.yearEnd = parseInt(yearEnd) || null;
        }
    }

    var generalExactOptions = ['1', 'XO'];
    var locationExactnesses = ['1', 'PCO', 'PACO', 'PS', 'PAS', 'PC'];

    function loadLocationExactness(domPrefix, event, locationExactnessStr) {
        if (event.locationExactness.isExact && $.inArray(locationExactnessStr, locationExactnesses) !== -1) {
            var exactness = sfsLocationExactnessProvider.getExactnessFromStr(event.gpid, locationExactnessStr) || { relativeLevel: 0, isAdjacent: false };
            event.locationExactness.level = exactness.relativeLevel;
            event.locationExactness.isAdjacent = exactness.isAdjacent;
            if (event.locationExactness.level < 0) {
                event.locationExactness.level = 0;
                event.locationExactness.isAdjacent = false;
            }
        }
    }

    function populateEventsFromData(domPrefix, data) {
        function getEventData(keyRoot, index, suffix) {
            suffix = suffix || '';
            var key = keyRoot + index + suffix;
            var eventData = data[key];
            if (eventData == null && index == 0) {
                key = keyRoot + suffix;
                eventData = data[key];
            }
            if (eventData == null && suffix == '__ftp') return getEventData(keyRoot, index, '-ftp');
            return eventData;
        }

        function getPInfoFromStr(pinfoStr) {
            if (pinfoStr == null) return null;
            var pinfoParts = pinfoStr.split('|');
            if (pinfoParts.length < 3) return null;
            var level = parseInt(pinfoParts.splice(0, 1)[0]) || null;
            pinfoParts.pop();  // last item should be empty str
            var pinfo = [];
            var pinfoLength = Math.max(11, level + 1);
            for (var i = 0; i < pinfoLength; ++i) {
                pinfo.push(0);
            }
            var errorParsing = false;
            pinfoParts.forEach(function (pinfoPart) {
                var pinfoPartInt = parseInt(pinfoPart);
                if (isNaN(pinfoPartInt)) {
                    errorParsing = true;
                    return;
                }
                pinfo.push(pinfoPartInt);
            });

            return errorParsing ? null : { level: level, idHierarchy: pinfo };
        }

        function loadEvent(eventGroupName, keys, eventIndex) {
            var isSingletonEventGroup = isSingletonEvent(domPrefix, eventGroupName);
            var day = parseInt((getEventData(keys.day, eventIndex) || "") + '') || null;
            var hasDay = day != null;
            var month = parseInt((getEventData(keys.month, eventIndex) || "") + '') || null;
            var hasMonth = month != null;
            var year = parseInt((getEventData(keys.year, eventIndex) || "") + '') || null;
            var hasYear = year != null;
            var locationText = getEventData(keys.location, eventIndex, '__ftp') || "";
            var hasLocationText = locationText.length > 0;
            if (hasLocationText || hasYear || hasMonth || hasDay) {
                var event = isSingletonEventGroup ? service.getEvent(domPrefix, eventGroupName) : service.addEvent(domPrefix, eventGroupName);
                event.date.year = year;
                event.date.month = month;
                event.date.day = day;
                event.location = locationText;

                // Initialize remaining event date fields
                var isYearExactStr = getEventData(keys.year, eventIndex, '_x');
                var isMonthExactStr = getEventData(keys.month, eventIndex, '_x');
                var isDayExactStr = getEventData(keys.day, eventIndex, '_x');
                var yearProximity = parseInt(getEventData(keys.yearProximity, eventIndex)) || null;
                var isYearExact =
                    $.inArray(isYearExactStr, generalExactOptions) !== -1 ||
                    $.inArray(isMonthExactStr, generalExactOptions) !== -1 ||
                    $.inArray(isDayExactStr, generalExactOptions) !== -1;
                event.dateProximity.year = isYearExact
                    ? (yearProximity != null ? yearProximity : 0)
                    : null;

                // Initialize remaining event location fields
                var textExactnessStr = getEventData(keys.location, eventIndex, '__ftp_x') || getEventData(keys.location, eventIndex, '-ftp_x');
                var gpidExactnessStr = getEventData(keys.location, eventIndex, '_x');
                var gpid = getEventData(keys.location, eventIndex);
                var pinfoStr = getEventData(keys.location, eventIndex, '_PInfo');
                var pinfo = getPInfoFromStr(pinfoStr);
                event.gpid = gpid != null && gpid.length > 0 ? gpid : null;
                event.locationExactness.isExact = event.gpid != null ? $.inArray(gpidExactnessStr, locationExactnesses) !== -1 : $.inArray(textExactnessStr, generalExactOptions) !== -1;
                event.locationExactness.level = event.locationExactness.isExact ? 0 : null;
                event.locationExactness.isAdjacent = false;
                if (event.location != null && event.gpid != null) {
                    if (pinfo != null) {
                        sfsLocationExactnessProvider.cachePlaceByPrefixResponse({ Id: event.gpid, HName: event.location, IdHierarchy: pinfo.idHierarchy, LevelId: pinfo.level, fromPlaceByPrefix: false });
                    }
                    sfsLocationExactnessProvider.getPlaceInfoByPrefixMatchingGpid(domPrefix, event.location, event.gpid, function (err, placeInfo) {
                        loadLocationExactness(domPrefix, event, gpidExactnessStr);
                    });
                }
            }
        }

        function getSearchKey(aliasStr, part) {
            return 'ms' + aliasStr + part;
        }

        var dataModelMetadata = getMetadata(domPrefix);
        $.each(dataModelMetadata.events, function (eventMetadataKey, eventMetadataObj) {
            if (!eventMetadataObj.alias) return;
            $.each(eventMetadataObj.alias, function (aliasIndex, alias) {
                for (var eventIndex = 0; eventIndex < eventMetadataObj.max; ++eventIndex) {
                    var searchKeys = {
                        day: getSearchKey(alias, 'dd'),
                        month: getSearchKey(alias, 'dm'),
                        year: getSearchKey(alias, 'dy'),
                        yearProximity: getSearchKey(alias, 'dp'),
                        location: getSearchKey(alias, 'pn')
                    };
                    loadEvent(eventMetadataKey, searchKeys, eventIndex);
                }
            });
        });

        $.each(dataModelMetadata.customEvents, function (customEventGroupName, customEventMetadata) {
            var searchKeys = {
                day: customEventMetadata.daySearchKey,
                month: customEventMetadata.monthSearchKey,
                year: customEventMetadata.yearSearchKey,
                yearProximity: customEventMetadata.yearProximitySearchKey,
                location: customEventMetadata.locationSearchKey
            };
            loadEvent(customEventGroupName, searchKeys, 0);
        });
    }

    function populateFamilyMemberGivenName(domPrefix, data, familyMemberType, index, member) {
        var metadata = getMetadata(domPrefix);
        var groupMetadata = metadata.family[familyMemberType];
        var nameKey = groupMetadata.givenNameParam + (index === -1 ? "" : index);
        var exactKey = nameKey + "_x";
        var givenNameValue = data[nameKey];
        if (givenNameValue == null) return null;
        member = member || getOrAddFamilyMember(domPrefix, familyMemberType, Math.max(index, 0));
        if (member == null) return null;

        member.givenName = givenNameValue;
        member.givenNameExactness.isExact = data[exactKey] === '1';
        return member;
    }

    function populateFamilyMemberSurname(domPrefix, data, familyMemberType, index, member) {
        var metadata = getMetadata(domPrefix);
        var groupMetadata = metadata.family[familyMemberType];
        var indexQueryStr = index === -1 ? "" : index.toString();
        var nameKey = groupMetadata.surnameParam + indexQueryStr;
        var exactKey = nameKey + '_x';
        var surname = data[nameKey];
        if (surname == null) return;
        member = member || getOrAddFamilyMember(domPrefix, familyMemberType, Math.max(index, 0));
        if (member == null) return null;

        var firstNameKey = groupMetadata.surnameParam + "f" + indexQueryStr;
        var secondNameKey = groupMetadata.surnameParam + "s" + indexQueryStr;
        if (service.getMetadata(domPrefix).name.usesParentSurnameFields && (data[firstNameKey] || data[secondNameKey])) {
            member.surname = data[firstNameKey] || '';
            member.secondSurname = data[secondNameKey] || '';
        } else {
            member.surname = surname;
        }
        member.surname = member.surname.replace(/ +/g, " ").replace(/ y /gi, " ");
        member.surnameExactness.isExact = data[exactKey] === "1";
        member.secondSurname = member.secondSurname.replace(/ +/g, " ").replace(/ y /gi, " ");
        return member;
    }

    function populateFamilyFromData(domPrefix, data) {
        var metadata = getMetadata(domPrefix);
        var familyMemberTypes = Object.keys(metadata.family);
        familyMemberTypes.forEach(function (familyMemberType) {
            var groupConfig = metadata.family[familyMemberType];
            for (var i = -1; i < groupConfig.maxCount; i++) {
                var member = populateFamilyMemberGivenName(domPrefix, data, familyMemberType, i, null);
                populateFamilyMemberSurname(domPrefix, data, familyMemberType, i, member);
            }
        });
    }

    function isCollectionForm(domPrefix) {
        var metadata = getMetadata(domPrefix);
        return metadata.form.type === 'ContentBased' && metadata.form.collectionId;
    }

    function resetData(domPrefix) {
        var dataModel = service.getDataModel(domPrefix);
        dataModel.limitRecordsToCountry = false;
        dataModel.searchSource = null;
        if (!isCollectionForm(domPrefix)) {
            populateCollectionPriority(domPrefix, service.getMetadata(domPrefix).defaultSearchBlock);
        }
        loadDefaultCategoryBucket(domPrefix);
        $.extend(true, dataModel.name, nameDefault);
        dataModel.isExactAllEnabled = false;
        $.each(dataModel.events, function (eventGroupName, eventGroup) {
            if (isSingletonEvent(domPrefix, eventGroupName)) {
                $.extend(true, eventGroup.instances[0], eventTemplate);
                if (eventGroup.instances.length > 1) {
                    eventGroup.instances.splice(1, eventGroup.instances.length - 1);
                }
            } else {
                eventGroup.instances.length = 0;
            }
        });
        $.each(dataModel.fields, function (fieldName, field) {
            field.value = '';
            field.isExact = false;
        });
        dataModel.range.yearStart = null;
        dataModel.range.yearEnd = null;
        clearFamilyMembers(domPrefix);
        dataModel.treePerson.treeId = '';
        dataModel.treePerson.personId = '';
    }
    function matchAllPrevTermsExactly(domPrefix, matchAll) {
        var dataModel = service.getDataModel(domPrefix);
        if (!dataModel.name.givenName) dataModel.name.givenNameExactness.isExact = matchAll;
        if (!dataModel.name.surname) dataModel.name.surnameExactness.isExact = matchAll;
        $.each(dataModel.events, function (eventGroupIndex, eventGroup) {
            $.each(eventGroup.instances, function (eventIndex, event) {
                if (event.date) {
                    var dateBox = Object.values(event.date);
                    if (dateBox) {
                        var isValid = false;
                        dateBox.forEach(function (dateItem) {
                            if (dateItem) isValid = true;
                        });
                        if (isValid) {
                            if (!event.dateProximity.day && event.dateProximity.day != 0) event.dateProximity.day = matchAll || null;
                            if (!event.dateProximity.month && event.dateProximity.month != 0) event.dateProximity.month = matchAll || null;
                            if (!event.dateProximity.year && event.dateProximity.month != 0) event.dateProximity.year = matchAll || null;
                        }
                    }
                }
                if (!event.location) event.locationExactness.isExact = matchAll;
            });
        });
        $.each(dataModel.family, function (familyGroupIndex, familyGroup) {
            $.each(familyGroup.instances, function (memberIndex, member) {
                if (!member.givenName) member.givenNameExactness.isExact = matchAll;
                if (!member.surname) member.surnameExactness.isExact = matchAll;
            });
        });
        $.each(dataModel.fields, function (fieldName, field) {
            if (!field.value) field.isExact = matchAll;
        });
    }
    function matchAllTermsExactly(domPrefix, matchAll) {
        var dataModel = service.getDataModel(domPrefix);
        dataModel.isExactAllEnabled = matchAll;
        dataModel.limitRecordsToCountry = matchAll;

        function setExactnessPropsToFalse(exactnessProps) {
            $.each(exactnessProps, function (key) {
                exactnessProps[key] = false;
            });
        }

        dataModel.name.givenNameExactness.isExact = matchAll;
        dataModel.name.surnameExactness.isExact = matchAll;
        if (!matchAll) {
            setExactnessPropsToFalse(dataModel.name.givenNameExactness.flags);
            setExactnessPropsToFalse(dataModel.name.surnameExactness.flags);
        }

        $.each(dataModel.events, function (eventGroupIndex, eventGroup) {
            $.each(eventGroup.instances, function (eventIndex, event) {
                event.dateProximity.day = !matchAll ? null : (event.dateProximity.day != null ? event.dateProximity.day : 0);
                event.dateProximity.month = !matchAll ? null : (event.dateProximity.month != null ? event.dateProximity.month : 0);
                event.dateProximity.year = !matchAll ? null : (event.dateProximity.year != null ? event.dateProximity.year : 0);
                event.locationExactness.isExact = matchAll;
                event.locationExactness.level = !matchAll ? null : (event.locationExactness.level != null ? event.locationExactness.level : 0);
                event.locationExactness.isAdjacent = matchAll && event.locationExactness.isAdjacent;
            });
        });
        $.each(dataModel.family, function (familyGroupIndex, familyGroup) {
            $.each(familyGroup.instances, function (memberIndex, member) {
                member.givenNameExactness.isExact = matchAll;
                member.surnameExactness.isExact = matchAll;
            });
        });
        $.each(dataModel.fields, function (fieldName, field) {
            field.isExact = matchAll;
        });
    }

    function isSingletonEvent(domPrefix, eventGroupName) {
        var dataModelMetadata = getMetadata(domPrefix);
        return $.inArray(eventGroupName, dataModelMetadata.singletonEvents) !== -1;
    }

    function getEventGroup(domPrefix, eventGroupName) {
        var dataModel = service.getDataModel(domPrefix);
        var eventContainer = dataModel.events[eventGroupName];
        return eventContainer;
    }

    function getEvent(domPrefix, eventGroupName, index) {
        var eventContainer = service.getEventGroup(domPrefix, eventGroupName);
        index = typeof (index) == 'undefined' ? 0 : index;
        if (eventContainer == null || index < 0 || index >= eventContainer.instances.length) return null;
        return eventContainer.instances[index];
    }

    function getEventGroupMaxCount(domPrefix, eventGroupName) {
        if (isSingletonEvent(domPrefix, eventGroupName)) return 1;
        var metadata = service.getMetadata(domPrefix);
        return metadata.events[eventGroupName].max;
    }

    function addEvent(domPrefix, eventGroupName, insertionIndex) {
        var eventContainer = service.getEventGroup(domPrefix, eventGroupName);
        if (isSingletonEvent(domPrefix, eventGroupName) && eventContainer.instances.length > 0) return eventContainer.instances[0];
        var index = typeof (insertionIndex) == 'undefined' ? eventContainer.instances.length : insertionIndex;
        if (eventContainer == null || index < 0 || index > eventContainer.instances.length) return null;
        var newCount = eventContainer.instances.length + 1;
        if (newCount > service.getEventGroupMaxCount(domPrefix, eventGroupName)) return null;
        var eventTemplateObj = service.getDataModel(domPrefix).isExactAllEnabled ? eventWithExactnessTemplate : eventTemplate;
        var newEventObj = $.extend(true, {}, eventTemplateObj);
        eventContainer.instances.splice(index, 0, newEventObj);
        return newEventObj;
    }

    function removeEvent(domPrefix, eventGroupName, removeIndex) {
        if (isSingletonEvent(domPrefix, eventGroupName)) return;
        var eventContainer = service.getEventGroup(domPrefix, eventGroupName);
        if (eventContainer == null) return;
        var index = typeof (removeIndex) == 'undefined' ? eventContainer.instances.length - 1 : removeIndex;
        if (index < 0 || index >= eventContainer.instances.length) return;
        eventContainer.instances.splice(index, 1);
    }

    function getFamilyMemberGroup(domPrefix, familyMemberType) {
        var dataModel = service.getDataModel(domPrefix);
        var familyContainer = dataModel.family[familyMemberType];
        if (!familyContainer)
            throw new Error("dataModelProvider does not have a data model with \"family." + familyMemberType + "\" property");
        return familyContainer;
    }

    function getOrAddFamilyMember(domPrefix, familyMemberType, index) {
        var member = getFamilyMember(domPrefix, familyMemberType, index);
        if (member == null) {
            member = service.addFamilyMember(domPrefix, familyMemberType);
        }
        return member;
    }

    function getFamilyMember(domPrefix, familyMemberType, index) {
        var familyGroup = getFamilyMemberGroup(domPrefix, familyMemberType);
        index = typeof (index) == 'undefined' ? 0 : index;
        if (familyGroup == null || index < 0 || index >= familyGroup.instances.length) return null;
        return familyGroup.instances[index];
    }

    function addFamilyMember(domPrefix, familyMemberType) {
        var metadata = getMetadata(domPrefix);
        var familyMemberContainer = getFamilyMemberGroup(domPrefix, familyMemberType);
        if (familyMemberContainer.instances.length >= metadata.family[familyMemberType].maxCount) return null;
        var newFamilyMemberObj = $.extend(true, {}, nameDefault);
        if (service.getDataModel(domPrefix).isExactAllEnabled) {
            newFamilyMemberObj.givenNameExactness.isExact = true;
            newFamilyMemberObj.surnameExactness.isExact = true;
        }
        familyMemberContainer.instances.push(newFamilyMemberObj);
        return newFamilyMemberObj;
    }

    function removeFamilyMember(domPrefix, relationship, index) {
        var familyMemberContainer = getFamilyMemberGroup(domPrefix, relationship);
        if (index < 0 || index >= familyMemberContainer.instances.length) return;
        familyMemberContainer.instances.splice(index, 1);
    }

    function clearFamilyMembers(domPrefix) {
        var dataModel = service.getDataModel(domPrefix);
        var metadata = service.getMetadata(domPrefix);
        var familyMemberTypes = Object.keys(dataModel.family);
        familyMemberTypes.forEach(function (familyMemberType) {
            var familyMemberContainer = getFamilyMemberGroup(domPrefix, familyMemberType);
            familyMemberContainer.instances.length = 0;
            for (var i = 0; i < metadata.family[familyMemberType].instances; ++i) {
                addFamilyMember(domPrefix, familyMemberType);
            }
        });
    }

    function getCategoryBucketString(domPrefix) {
        var model = getDataModel(domPrefix);
        var categoryBucketStr = '';
        if (model.types.records) categoryBucketStr += 'r';
        if (model.types.stories) categoryBucketStr += 's';
        if (model.types.trees) categoryBucketStr += 't';
        if (model.types.photos) categoryBucketStr += 'p';
        return categoryBucketStr;
    }

    // traverses the search model and sets all search form values to null
    // for fields not shown on the screen to avoid including them into search queries
    function limitDataModelToFieldsOnSearchForm(domPrefix, dataModel, fieldNames) {
        processPersonName(dataModel, fieldNames);
        processEvents(domPrefix, dataModel, fieldNames);
        processFamilyMembers(dataModel, fieldNames);
        processFields(dataModel, fieldNames);
    }

    function processPersonName(dataModel, fieldNames) {
        if (dataModel.name.givenName && !fieldNameExists('SelfGivenName', fieldNames)) {
            dataModel.name.givenName = '';
        }

        if (dataModel.name.surname && !fieldNameExists('SelfSurname', fieldNames)) {
            dataModel.name.surname = '';
            dataModel.name.secondSurname = '';
        }
    }

    function processFields(dataModel, fieldNames) {
        Object.keys(dataModel.fields).forEach(function (fieldName) {
            var field = dataModel.fields[fieldName];
            if (field.value && !fieldNameExists(fieldName, fieldNames)) {
                field.value = null;
            }
        });
    }
    
    function processFamilyMembers(dataModel, fieldNames) {
        Object.keys(dataModel.family).forEach(function (key) {
            var instances = dataModel.family[key].instances;

            var prefix = key;
            for (var i = 0; i < instances.length; i++) {
                var inst = instances[i];

                if (inst.givenName && !fieldNameExists(prefix + 'GivenName', fieldNames)) {
                    inst.givenName = '';
                }

                if (inst.surname && !fieldNameExists(prefix + 'Surname', fieldNames)) {
                    inst.surname = '';
                }
            }
        });
    }

    function processEvents(domPrefix, dataModel, fieldNames) {
        Object.keys(dataModel.events).forEach(function (eventGroupName) {
            var evt = dataModel.events[eventGroupName];
            var instances = evt.instances;
            var prefix = (evt.relation || '') + (evt.eventName || '');
            if (evt.instances.length > 1 && isSingletonEvent(domPrefix, eventGroupName)) {
                evt.instances.splice(1, evt.instances.length - 1);
            }
            for (var i = 0; i < instances.length; i++) {
                var inst = instances[i];

                if (inst.date.day && !fieldNameExists(prefix + 'Day', fieldNames)) {
                    inst.date.day = null;
                }

                if (inst.date.month && !fieldNameExists(prefix + 'Month', fieldNames)) {
                    inst.date.month = null;
                }

                if (inst.date.year && !fieldNameExists(prefix + 'Year', fieldNames)) {
                    inst.date.year = null;
                }

                if (inst.location && !fieldNameExists(prefix + 'Place', fieldNames)) {
                    inst.gpid = null;
                    inst.location = null;
                }
            }
        });
    }

    function fieldNameExists(name, fields) {
        if (!fields) {
            return false;
        }

        for (var i = 0; i < fields.length; i++) {
            if (fields[i].toLowerCase() === name.toLowerCase()) {
                return true;
            }
        }

        return false;
    }

    var service = {
        unchangedDataModel: unchangedDataModel,
        getDataModel: getDataModel,
        getMetadata: getMetadata,
        resetData: resetData,
        populateFromData: populateFromData,
        matchAllTermsExactly: matchAllTermsExactly,
        matchAllPrevTermsExactly: matchAllPrevTermsExactly,
        getEventGroup: getEventGroup,
        getEvent: getEvent,
        getEventGroupMaxCount: getEventGroupMaxCount,
        addEvent: addEvent,
        removeEvent: removeEvent,
        addFamilyMember: addFamilyMember,
        removeFamilyMember: removeFamilyMember,
        getCategoryBucketString: getCategoryBucketString,
        getCollectionFocusItem: getCollectionFocusItem,
        populateCategoryBucketFromString: populateCategoryBucketFromString,
        populateCollectionPriority: populateCollectionPriority,
        limitDataModelToFieldsOnSearchForm: limitDataModelToFieldsOnSearchForm
    };

    return service;
}]);
'use strict';
(function () {
    angular.module('SearchFormServiceUI').service('sfsFilterHierarchyService', ['$window', 'sfsDataModelProvider', function ($window, sfsDataModelProvider) {
        return {
            getFieldFilter: function (domPrefix, fieldName, values, callback) {
                var metadata = sfsDataModelProvider.getMetadata(domPrefix);
                getFilter(metadata, fieldName, values, callback);
            },
            getPlaceFilter: function (domPrefix, eventGroupName, values, callback) {
                var metadata = sfsDataModelProvider.getMetadata(domPrefix);
                getFilter(metadata, eventGroupName, values, callback);
            },
            getValuePathFromFieldValue: function (domPrefix, fieldName, value, callback) {
                var metadata = sfsDataModelProvider.getMetadata(domPrefix);
                getValuePathFromValue(metadata, fieldName, value, callback);
            },
            getValuePathFromPlaceValue: function (domPrefix, eventGroupName, value, callback) {
                var metadata = sfsDataModelProvider.getMetadata(domPrefix);
                getValuePathFromValue(metadata, eventGroupName, value, callback);
            }
        };
    }]);

    function getFilter(metadata, fieldNameOrEventGroupName, values, callback) {
        if (!metadata.filters || !metadata.filters[fieldNameOrEventGroupName]) {
            return callback(new Error("No Filter Found for " + fieldNameOrEventGroupName));
        }

        var filter = metadata.filters[fieldNameOrEventGroupName];
        for (var i = 0; i < values.length; ++i) {
            var value = values[i];
            for (var j = 0; j < filter.options.length; ++j) {
                if (filter.options[j].value === value) {
                    filter = filter.options[j].filter;
                    break;
                }
            }
            if (!filter) {
                return callback(new Error("No Filter Found for " + fieldNameOrEventGroupName + " for value path " + values.join(", ")));
            }
        }

        callback(null, copyFilter(filter));
    };

    function getValuePathFromValue(metadata, fieldNameOrEventGroupName, value, callback) {
        if (!metadata.filters || !metadata.filters[fieldNameOrEventGroupName]) {
            return callback(new Error("No Filter Found for " + fieldNameOrEventGroupName));
        }

        var filter = metadata.filters[fieldNameOrEventGroupName];
        var valuePath = findValuePathForLeafValueOrNull(filter, value);
        if (valuePath == null) {
            return callback(new Error("Could not find value path for \"" + fieldNameOrEventGroupName + "\" with value \"" + value + "\""));
        }
        return callback(null, valuePath);
    };

    function findValuePathForLeafValueOrNull(filter, leafValue) {
        for (var i = 0; i < filter.options.length; ++i) {
            var option = filter.options[i];
            if (option.filter == null) {
                if (option.value === leafValue)
                    return [leafValue];
            } else {
                var valuePath = findValuePathForLeafValueOrNull(option.filter, leafValue);
                if (valuePath) {
                    valuePath.splice(0, 0, option.value);
                    return valuePath;
                }
            }
        }
        return null;
    };

    function copyFilter(filter) {
        // Return a copy of the filter that does not have a reference to additional filters to force consumers to use this service 
        // as though AJAX needs to happen, which would be the case if we weren't providing the full filter info on the page.
        var filterCopy = {};
        angular.copy(filter, filterCopy);
        for (var i = 0; i < filterCopy.options.length; ++i) {
            filterCopy.options[i].isLeaf = filterCopy.options[i].filter == null;
            filterCopy.options[i].filter = null;
        }
        return filterCopy;
    }
})();
angular.module('SearchFormServiceUI').service('sfsLocationExactnessProvider', ['$window', '$rootScope', function($window, $rootScope) {
    var cache = {};
    var exactnessStrs = ['1', 'PCO', 'PACO', 'PS', 'PAS', 'PC'];
    var locationRequests = {};
    var locationCache = {};

    var placeExactnesses = [
        { id: 'cty',  absoluteLevel: 8, supportsAdjacency: false, acronym: '1' },
        { id: 'cnty', absoluteLevel: 7, supportsAdjacency: true,  acronym: 'PCO', adjacentAcronym: 'PACO' },
        { id: 'st',   absoluteLevel: 5, supportsAdjacency: true,  acronym: 'PS',  adjacentAcronym: 'PAS' },
        { id: 'ctry', absoluteLevel: 3, supportsAdjacency: false, acronym: 'PC' }
    ];
    var placeExactnessesMap = {};
    placeExactnesses.forEach(function(exactness) {
        placeExactnessesMap[exactness.id] = exactness;
    });


    function getExactnesses(gpid) {
        return getExactnessesV2(gpid) || getExactnessesV1(gpid) || [];
    };

    function shouldExcludeExactness(exactness, placeInfo) {
        // Some exactnesses should be excluded depending on location.  
        // e.g. Austrailia and Canada have no counties, so the county exactnesses should be excluded for these locations.
        if (exactness.absoluteLevel === 7) {  // County level
            // no counties in Australia (5027) and Canada (3243)
            if ($.inArray(5027, placeInfo.idHierarchy) !== -1 ||
                $.inArray(3243, placeInfo.idHierarchy) !== -1) {
                return true;
            }
        }
        else if (exactness.absoluteLevel === 5) {  // State level
            // Wales (5250)
            // England (3251)
            // Ireland (3250)
            if ($.inArray(5250, placeInfo.idHierarchy) !== -1 ||
                $.inArray(3251, placeInfo.idHierarchy) !== -1 ||
                $.inArray(3250, placeInfo.idHierarchy) !== -1) {
                return true;
            }
        }
        return false;
    }

    function getExactnessObj(locationData, isAdjacent, relativeLevel) {
        return {
            absoluteLevel: locationData.absoluteLevel,
            acronym: !isAdjacent ? locationData.acronym : locationData.adjacentAcronym,
            isAdjacent: isAdjacent,
            relativeLevel: relativeLevel
        };
    };

    function getExactnessesV1(gpid) {
        if (gpid == null || cache[gpid] == null) return [];
        var relativeExactnesses = [];
        var placeInfo = cache[gpid];
        var relativeLevel = -1;
        placeExactnesses.forEach(function(placeExactness) {
            if (placeExactness.absoluteLevel > placeInfo.level) return;
            if (shouldExcludeExactness(placeExactness, placeInfo)) return;
            ++relativeLevel;
            relativeExactnesses.push(getExactnessObj(placeExactness, false, relativeLevel));
            if (placeExactness.adjacentAcronym) {
                relativeExactnesses.push(getExactnessObj(placeExactness, true, relativeLevel));
            }
        });
        return relativeExactnesses;
    };

    function isGpidMappedToExactnessV2Cache(gpid) {
        return $window.ancestry != null 
            && $window.ancestry.search != null 
            && $window.ancestry.search.locationExactnesses != null 
            && $window.ancestry.search.locationExactnesses[gpid] != null;
    }

    function getExactnessesV2(gpid) {
        if (!isGpidMappedToExactnessV2Cache(gpid)) return null;
        var exactnesses = [];        
        var locations = $window.ancestry.search.locationExactnesses[gpid];
        var relativeLevel = -1;
        locations.forEach(function(locationId) {
            ++relativeLevel;
            var locationData = placeExactnessesMap[locationId];
            exactnesses.push(getExactnessObj(locationData, false, relativeLevel));
            if (locationData.supportsAdjacency) {
                exactnesses.push(getExactnessObj(locationData, true, relativeLevel));
            }
        });
        return exactnesses;
    };

    function debugEnabled() {
        var isDebugOnUrlRegex = /(^|&|[?])debug=1($|&)/;
        
        return isDebugOnUrlRegex.test($window.location.search || '');
    }

    return {
        getPlaceInfoByPrefixMatchingGpid: function(domPrefix, location, gpid, callback) {
            if (isGpidMappedToExactnessV2Cache(gpid)) {
                var dummyPlaceInfoUsedOnlyForSettingPinfo = { idHierarchy: [], level: 0 };
                callback(null, dummyPlaceInfoUsedOnlyForSettingPinfo);
                return;
            }

            callback = callback || function() {};

            // Throw out bad requests
            if (location == null || location.length === 0) {
                return callback(new Error("Location must not be null or empty"));
            }

            // Don't bother requesting if response is already cached
            if (locationCache[location]) {
                var cachedGpid = locationCache[location];
                return callback(null, cache[cachedGpid]);
            }

            // Prevent concurrent requests for the same location
            var request = locationRequests[location];
            if (request) {
                request.listeners.push(callback);
                return;
            }
            request = { listeners: [callback] };
            locationRequests[location] = request;
            function notifyListeners(err, response) {
                request.listeners.forEach(function(listenerCallback) {
                    listenerCallback(err, response);
                });
                delete locationRequests[location];
            };
            function handleError() {
                $rootScope.$apply(function() {
                    if (cache[gpid]) {
                        notifyListeners(null, cache[gpid]);
                    } else {
                        notifyListeners(new Error('Could not find place info from the place by prefix service with a matching gpid for ' + location + " | " + gpid));
                    }
                });
            };

            var sfsData = $window[domPrefix + 'sfsData'];
            var eventPlaceStrs = sfsData['eventPlace'];
            var placePickerSourceUrl = (eventPlaceStrs.placePickerUrl || '//placepfx.ancestry.com/s/') + '?callback=?&maxCount=4&cultureId=' + (sfsData.cultureId || 'en-US');
            var that = this;
            $.ajax({
                url: placePickerSourceUrl + '&prefix=' + encodeURIComponent(location),
                dataType: 'jsonp',
                // in debug mode we stuff lots of debug info into the search results page
                // which is a lengthy and blocking operation and it takes a while and may result 
                // into timeout errors under Firefox
                timeout: debugEnabled() ? 8000: 2000,  
                success: function (placeByPrefixResponse) {
                    if (placeByPrefixResponse == null || placeByPrefixResponse.length <= 0) {
                        return handleError();
                    }
                    // Note: Because we are using a hack of finding place info via text when we have the exact gpid, it is possible that the first match is not the
                    //       place info referred to by the gpid.  To help facilitate this we need to get back multiple matches and then pick the one that ends with
                    //       the gpid.
                    var matchingItem = null;
                    for (var i = 0; i < placeByPrefixResponse.length; ++i) {
                        var item = placeByPrefixResponse[i];
                        if (item.Id.toString() === gpid) {
                            matchingItem = item;
                            break;
                        }
                    }
                    if (matchingItem == null) {
                        return handleError();
                    }
                    $rootScope.$apply(function() {
                        that.cachePlaceByPrefixResponse(matchingItem);
                        notifyListeners(null, cache[gpid]);
                    });
                },
                error: handleError
            });
        },
        cachePlaceByPrefixResponse: function(placeByPrefixResponse) {
            var gpid = placeByPrefixResponse.Id.toString();
            var placeInfo = {
                name: placeByPrefixResponse.HName,
                idHierarchy: placeByPrefixResponse.IdHierarchy,
                level: placeByPrefixResponse.LevelId
            };
            cache[gpid] = placeInfo;
            // Only put items in locationCache that are directly from place by prefix.  This allows other sources to populate a cache that works
            // but is later superceded by data from the place by prefix service.
            if (placeByPrefixResponse.fromPlaceByPrefix !== false) {
                locationCache[placeInfo.name] = gpid;
            }
        },
        getExactnesses: function(domPrefix, gpid) {
            var sfsData = $window[domPrefix + 'sfsData'];
            var exactnessStrs = sfsData.eventPlaceExactnessDisplay;
            var optionStrs = sfsData.eventPlaceExactnessSelectDisplay;
            function setPlaceExactnessStrings(placeExactness) {
                placeExactness.optionString = optionStrs[placeExactness.acronym];
                placeExactness.exactnessString = exactnessStrs[placeExactness.acronym];
            };
            var exactnesses = getExactnesses(gpid);
            exactnesses.forEach(function(exactness) {
                setPlaceExactnessStrings(exactness);
            });
            return exactnesses;
        },
        getExactnessFromStr: function (gpid, exactnessStr) {
            if (gpid == null || exactnessStr == null || exactnessStrs.indexOf(exactnessStr) === -1) return null;
            var exactnesses = getExactnesses(gpid);
            for (var i = 0; i < exactnesses.length; ++i) {
                var exactness = exactnesses[i];
                if (exactness.acronym === exactnessStr) {
                    return exactness;
                }
            }
            return null;
        }
    };
}]);(function () {
    var cacheGroups = [];
    angular.module('SearchFormServiceUI').service('sfsObjectCache', [function () {
        return {
            set: function (item, cacheGroupName, object, valuePathOrNull) {
                // Cache Group Names are used to help filter results down so that it isn't a complete linear traversal
                cacheGroups[cacheGroupName] = cacheGroups[cacheGroupName] || { cacheItemsByPath: {}, cacheItemsWithNullPath: [] };
                var cache = cacheGroups[cacheGroupName];
                // Further group cached items using the valuePath for faster retrieval.  If the value path is null, then this 
                // is just one big array of all cache items with no value path.
                var cacheItems = null;
                if (valuePathOrNull == null) {
                    cacheItems = cache.cacheItemsWithNullPath;
                } else {
                    cache.cacheItemsByPath[valuePathOrNull] = cache.cacheItemsByPath[valuePathOrNull] || [];
                    cacheItems = cache.cacheItemsByPath[valuePathOrNull];
                }
                // Don't cache the same item twice to the same exact place
                for (var i = 0; i < cacheItems.length; ++i) {
                    if (cacheItems[i] === object) return;
                }
                // Cache the item
                cacheItems.push({ object: object, item: item});
            },
            get: function (cacheGroupName, object, valuePathOrNull) {
                var cache = cacheGroups[cacheGroupName];
                if (!cache) return null;
                var cacheItems = null;
                if (valuePathOrNull == null) {
                    cacheItems = cache.cacheItemsWithNullPath;
                } else {
                    cacheItems = cache.cacheItemsByPath[valuePathOrNull];
                }
                if (cacheItems == null) return null;
                for (var i = 0; i < cacheItems.length; ++i) {
                    var cacheItem = cacheItems[i];
                    if (cacheItem.object === object) {
                        return cacheItem.item;
                    }
                }
                return null;
            }
        }
    }]);
})();
(function() {
    angular.module('SearchFormServiceUI').service('sfsPersonPickerService', ['$window', '$rootScope', 'queryParamCollection', 'sfsDataModelProvider', 'urlCanonicalizerProvider',
        function($window, $rootScope, queryParamCollection, sfsDataModelProvider, urlCanonicalizerProvider) {
            var currentTreeId, currentTreeData;
        return {
            updateModelFromPersonPickerResponse: function (domPrefix, response, businessEvent) {
                var model = sfsDataModelProvider.getDataModel(domPrefix);
                var metadata = sfsDataModelProvider.getMetadata(domPrefix);
                var queryObj = null;
                if (metadata.querySetVersion === "V2") {
                    var updatedModel = getV2ModelFromResponse(response, metadata, model, businessEvent);

                    // reset values for fields in data model which don't have visual representation in the search form
                    sfsDataModelProvider.limitDataModelToFieldsOnSearchForm(domPrefix, updatedModel, metadata.form.fieldNames);

                    var queryNormalizer = urlCanonicalizerProvider.getUrlCanonicalizer().queryParamNormalizer;
                    var queryParamData = queryNormalizer.toQueryParams(updatedModel, 'searchResults', null);
                    queryObj = queryParamData.params;
                } else {
                    var collectionFocusItem = $.grep(metadata.collectionFocus.items, function(item) {
                        return model.priority === item.groupName;
                    })[0];
                    var params = {
                        cp: collectionFocusItem ? collectionFocusItem.id.toString() : "0",
                        catBucket: sfsDataModelProvider.getCategoryBucketString(domPrefix)
                    };
                    queryObj = getQueryStringObjectFromV1(response, metadata, params, businessEvent);
                }

                $rootScope.$apply(function () {
                    queryParamCollection.updatePersonPickerParams(queryObj);
                    $rootScope.$broadcast('personPickerUpdated', domPrefix);
                    // Always enabled "Show more options"
                    model.isShowMoreOptionsExpanded = true;
                });
            },
            getTreeId: function() {
                return currentTreeId;
            },
            setTreeId: function(id) {
                currentTreeId = id;
            },
            setTreeData:  function(data) {
                currentTreeData = data;
            },
            getTreeData: function() {
                return currentTreeData;
            }
        };
    }]);

    function getEventsFilteredForContentBasedForms(lifeEvents, metadata) {
        var startDate = metadata.collection.yearRange.start;
        var endDate = metadata.collection.yearRange.end;

        // Initially filtered events are all but the Residence events as the residence events need to be filtered further
        var filteredEvents = lifeEvents.filter(function(item) { return item.Type !== 'r'; });

        var livedInEvents = lifeEvents.filter(function (item) {
            return item.Type === 'r'
                && item.hasOwnProperty('Date')
                && item.Date.hasOwnProperty('Year')
                && item.LocationText != null
                && item.LocationText.length > 0;
        });
        if (livedInEvents.length === 0) return filteredEvents;

        var livedInEventsFilteredByYear = livedInEvents.filter(function (item) {
            return item.Date.Year >= startDate && item.Date.Year <= endDate;
        }).sort(function (a, b) {
            return a.Date.Year - b.Date.Year;
        });

        if (livedInEventsFilteredByYear.length === 0) {
            // if there are no events in that range, show the closest event from BEFORE and the closest event from AFTER the range
            var livedInEventsBefore = livedInEvents.filter(function (item) {
                return item.Date.Year < startDate;
            }).sort(function (a, b) {
                // descending sort
                return b.Date.Year - a.Date.Year;
            });
            if (livedInEventsBefore.length > 0) {
                // add to result the closest event from BEFORE the range
                livedInEventsFilteredByYear.push(livedInEventsBefore[0]);
            }

            var livedInEventsAfter = livedInEvents.filter(function (item) {
                return item.Date.Year > endDate;
            }).sort(function (a, b) {
                return a.Date.Year - b.Date.Year;
            });
            if (livedInEventsAfter.length > 0) {
                // add to result the closest event from AFTER the range
                livedInEventsFilteredByYear.push(livedInEventsAfter[0]);
            }
        }

        filteredEvents = filteredEvents.concat(livedInEventsFilteredByYear);
        return filteredEvents;
    }

    function getFilteredEvents(personPickerResponse, metadata) {
        if (!personPickerResponse.hasOwnProperty('LifeEvents') || personPickerResponse.LifeEvents.length === 0) return [];

        var lifeEvents = $.extend(true, [], personPickerResponse.LifeEvents);
        if (metadata.form.type === 'ContentBased') {
            lifeEvents = getEventsFilteredForContentBasedForms(lifeEvents, metadata);
        }
        var eventCounts = {};
        for (var i = 0; i < lifeEvents.length; i++) {
            var event = lifeEvents[i];
            var eventCount = eventCounts[event.Type] || 0;
            eventCounts[event.Type] = ++eventCount;

            var hasReachedMaximumResidenceEvents = event.Type === 'r' && eventCount > 4;
            var hasReachedMaximumAnyEvents = event.Type === 'y' && eventCount > 11;
            var hasReachedMaximumEventCount = event.Type !== 'r' && event.Type !== 'y' && eventCount > 1;
            if (hasReachedMaximumResidenceEvents || hasReachedMaximumAnyEvents || hasReachedMaximumEventCount) {
                // Remove this event and continue filtering at next event
                lifeEvents.splice(i, 1);
                --i;
                continue;
            }

            // Remove Day / Month / Year from residence events -- We don't show Day/Month/Year modules for Lived In (Residence) events
            var date = event.Date;
            if (event.Type === 'r' && date) {
                date.Day = null;
                date.Month = null;
                date.Year = null;
            }
        }
        return lifeEvents;
    }

    function populateEventsForV1(params, personPickerResponse, metadata) {
        var lifeEvents = getFilteredEvents(personPickerResponse, metadata);
        var eventIndices = {};
        lifeEvents.forEach(function(event) {
            var eventIndex = eventIndices[event.Type] || 0;
            eventIndices[event.Type] = eventIndex + 1;

            // Add event date to parameter list
            var date = event.Date;
            if (date) {
                if (date.Day) {
                    var dayKey = 'ms' + event.Type + 'dd' + eventIndex;
                    params[dayKey] = date.Day;
                }
                if (date.Month) {
                    var monthKey = 'ms' + event.Type + 'dm' + eventIndex;
                    params[monthKey] = date.Month;
                }
                if (date.Year) {
                    var yearKey = 'ms' + event.Type + 'dy' + eventIndex;
                    params[yearKey] = date.Year;
                }
            }

            // Add event location to parameter list
            if (event.LocationText) {
                var placeKey = 'ms' + event.Type + 'pn' + eventIndex;
                params[placeKey + '__ftp'] = event.LocationText;
                if (event.LocationGpids) {
                    if (event.LocationGpids.Gpid) {
                        params[placeKey] = event.LocationGpids.Gpid.toString();
                    }
                    var hIndex = event.LocationGpids.GpidHierarchyIndex;
                    var h = event.LocationGpids.GpidHierarchy;
                    if (hIndex && h) {
                        params[placeKey + '_PInfo'] = hIndex + '-|' + h.join('|') + '|';
                    }
                }
            }
        });
    }

    function populateFamilyForV1(params, personPickerResponse, metadata) {
        var memberCounts = {};
        personPickerResponse.FamilyMembers.forEach(function(member) {
            var memberType = member.Relationship;
            var memberIndex = memberCounts[memberType] || 0;
            var memberIndexStr = memberIndex === 0 ? '' : memberIndex.toString();
            // Ensure there can be multiple children, spouses, and siblings but no other types (only 1 mother and 1 father allowed)
            if (memberType !== 'c' && memberType !== 's' && memberType !== 'b' && memberIndex > 0) return;
            memberCounts[memberType] = memberIndex + 1;
            // Add Given Name to parameter list
            if (member.GivenName) {
                var memberFirstNameKey = 'ms' + memberType + 'ng' + memberIndexStr;
                params[memberFirstNameKey] = member.GivenName;
            }
            // Add LastName to parameter list
            if (member.Surname) {
                var memberLastNameKey = 'ms' + memberType + 'ns' + memberIndexStr;
                var surname = member.Surname.replace(/ +/g, " ").replace(/ y /gi, " ");
                params[memberLastNameKey] = surname;
                if (metadata.name.usesParentSurnameFields) {
                    params['ms' + memberType + 'nsf' + memberIndexStr] = member.FatherSurname;
                    params['ms' + memberType + 'nss' + memberIndexStr] = member.MotherSurname;
                }
            }
        });
    }

    function getNormalizedSurnameData(personPickerResponse, metadata) {
        // remove duplicate spaces and unnecessary terms (like y separating surnames)
        var surnames = personPickerResponse.Surname ? personPickerResponse.Surname.split(' ') : [];

        if (personPickerResponse.Gender === 'f' && !metadata.name.usesParentSurnameFields) {
            var spouseSurnames = personPickerResponse.SpouseSurnames || [];
            // limit spouse surnames
            var spouseSurnameLimit = metadata.name.spouseSurnameLimit;
            var spouseSurnameCount = spouseSurnames.length >= spouseSurnameLimit ? spouseSurnameLimit : spouseSurnames.length;
            spouseSurnames = spouseSurnames.slice(0, spouseSurnameCount);
            var maidenName = (personPickerResponse.MaidenName || '').replace(/ +/g, " ").replace(/ y /gi, " ");
            if (maidenName.length > 0) {
                surnames = surnames.concat(personPickerResponse.MaidenName.split(' '));
            }
            surnames = surnames.concat(spouseSurnames);
        }
        var uniqueSurnameHash = {};
        var uniqueSurnames = [];
        surnames.forEach(function(surnameArg) {
            if (surnameArg !== null &&
                surnameArg.length > 0 &&
                !uniqueSurnameHash.hasOwnProperty(surnameArg.toLowerCase())) {
                uniqueSurnameHash[surnameArg.toLowerCase()] = true;
                uniqueSurnames.push(surnameArg);
            }
        });

        return uniqueSurnames.join(' ');
    }

    function getQueryStringObjectFromV1(personPickerResponse, metadata, defaultParams, businessEvent) {
       var params = $.extend({}, defaultParams);
        params['ssrc'] = 'pt_t' + personPickerResponse.TreeId + '_p' + personPickerResponse.PersonId;

        if (personPickerResponse.hasOwnProperty('LifeEvents') && personPickerResponse.LifeEvents.length > 0) {
            populateEventsForV1(params, personPickerResponse, metadata);
        }

        if (personPickerResponse.hasOwnProperty('FamilyMembers') && personPickerResponse.FamilyMembers.length > 0) {
            populateFamilyForV1(params, personPickerResponse, metadata);
        }

        if (personPickerResponse.hasOwnProperty('Gender') && personPickerResponse.Gender) {
            params['_83004003-n_xcl'] = personPickerResponse.Gender === 'm' ? 'f' : 'm';
        }

        if (personPickerResponse.hasOwnProperty('GivenName') && personPickerResponse.GivenName) {
            params['gsfn'] = personPickerResponse.GivenName;
        }

        var surname = getNormalizedSurnameData(personPickerResponse, metadata);
        params['gsln'] = surname; 
        params['gsfln'] = personPickerResponse.FatherSurname;
        params['gssln'] = personPickerResponse.MotherSurname;

        // Always enabled "Show more options"
        params['MSAV'] = '1';

        params['searchType'] = businessEvent;

        return params;
    }

    function getV2Events(response, metadata) {
        var eventTypes = {
            'r': { relation: "Self", eventName: "Residence" },
            'b': { relation: "Self", eventName: "Birth" },
            'd': { relation: "Self", eventName: "Death" },
            'm': { relation: "Self", eventName: "Marriage" },
            'y': { relation: "Self", eventName: "" }
        };
        var events = getFilteredEvents(response, metadata);
        if (events.length === 0) return {};
        var modelEvents = {};
        events.forEach(function(event) {
            var eventMetaData = eventTypes[event.Type];
            if (eventMetaData == null) return;
            var eventGroupName = eventMetaData.relation + eventMetaData.eventName;
            var eventGroup = modelEvents[eventGroupName] || { instances:[], relation:eventMetaData.relation, eventName:eventMetaData.eventName };
            modelEvents[eventGroupName] = eventGroup;
            var date = event.Date || {};
            var gpid = event.LocationText !== null && event.LocationGpids && event.LocationGpids != null && event.LocationGpids.Gpid && event.LocationGpids.Gpid != null 
                ? event.LocationGpids.Gpid.toString() 
                : null;
            eventGroup.instances.push({
                date: {
                    day: date.Day || null,
                    month: date.Month || null,
                    year: date.Year || null
                },
                location: event.LocationText || null,
                gpid: gpid
            });
        });
        return modelEvents;
    }

    function getV2Family(response, metadata) {
        if (!response.hasOwnProperty('FamilyMembers') || response.FamilyMembers.length === 0) return {};
        var memberTypes = {
            'c': 'child',
            'f': 'father',
            'm': 'mother',
            's': 'spouse',
            'b': 'sibling'
        };
        var memberCounts = {};
        var family = {};
        response.FamilyMembers.forEach(function(member) {
            var memberType = memberTypes[member.Relationship];
            if (memberType == null) return;
            var memberIndex = memberCounts[memberType] || 0;
            // Ensure there can be multiple children, spouses, and siblings but no other types (only 1 mother and 1 father allowed)
            if (memberType !== 'child' && memberType !== 'spouse' && memberType !== 'sibling' && memberIndex > 0) return;
            memberCounts[memberType] = memberIndex + 1;
            var newMember = {};
            // Add Given Name to parameter list
            if (member.GivenName) {
                newMember.givenName = member.GivenName;
            }
            // Add LastName to parameter list
            newMember.surname = member.Surname;
            if (metadata.name.usesParentSurnameFields) {
                newMember.surname = member.FatherSurname;
                newMember.secondSurname = member.MotherSurname;
             }
             if (newMember.givenName || newMember.surname || newMember.secondSurname) {
                if (!family[memberType]) family[memberType] = { instances: [] };
                family[memberType].instances.push(newMember);
            }
       });
        return family;
    }

    function getV2Gender(response) {
        var gender = { value: null, isExact: false, relation: "Self", eventName: "", assertion: "Gender" };
        if (response.Gender === 'm') gender.value = "male";
        else if (response.Gender === 'f') gender.value = "female";
        else gender.value = null;
        return gender;
    }

    function getV2Name(response, metadata) {
        if (!metadata.name.usesParentSurnameFields) {
            var surname = getNormalizedSurnameData(response, metadata);
            return {
                givenName: response.GivenName || "",
                surname: surname
            };
        }
        return {
            givenName: response.GivenName || "",
            surname: response.FatherSurname || "",
            secondSurname: response.MotherSurname || ""
        };
    }

    function getV2ModelFromResponse(response, metadata, currentModel, businessEvent) {
        return {
            name: getV2Name(response, metadata),
            fields: {
                SelfGender: getV2Gender(response)
            },
            events: getV2Events(response, metadata),
            family: getV2Family(response, metadata),
            treePerson: { treeId: response.TreeId, personId: response.PersonId },
            priority: currentModel.priority,
            location: $.extend(true, [], currentModel.location),
            searchType: businessEvent
        };
    }
})();
